import { memo, useCallback, useEffect, useState } from 'react';
import Dialog from '../../../components/Dialog';
import { Positive } from '../../../components/Core/Button';
import useFeatheryRedux from '../../../redux';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import Label from '../../../components/Dialog/Label';
import { useAppSelector } from '../../../hooks';
import { DropdownMultiField } from '../../../components/Core';

function AccountGroupsModal({ email, groups, close }: any) {
  const [curGroups, setCurGroups] = useState(groups);
  const { editAccount, getPanels } = useFeatheryRedux();

  const allGroups = useAppSelector(
    (state) => state.accounts.organization?.all_user_groups ?? {}
  );

  useEffect(() => setCurGroups(groups), [email]);

  const submit = useCallback(() => {
    editAccount({
      user_groups: curGroups,
      account_email: email
    })
      .then(() => getPanels())
      .then(() => close());
  }, [curGroups]);

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  return (
    <Dialog isOpen={email} title='Set User Groups' size='xsm' onClose={close}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <Label>Groups</Label>
        <DropdownMultiField
          selected={curGroups}
          options={allGroups.map((group: any) => ({
            value: group.id,
            label: group.name
          }))}
          onChange={(items: { value: string }[]) =>
            setCurGroups(items.map((item) => item.value))
          }
        />
        <div className='dialog-form-action text-center'>
          <Positive title='Save' type='submit' lockoutOverride={lockOutFlag} />
        </div>
      </form>
    </Dialog>
  );
}

export default memo(AccountGroupsModal);
