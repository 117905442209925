import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { objectPick, objectRemove } from '../../utils/core';
import { UNDO_TITLES, UNDO_TYPES } from '../../utils/constants';
import {
  Button,
  LogoLoader,
  OnOffSwitch,
  TextField
} from '../../components/Core';
import { DeleteRuleConfirmModal } from '../../components/Modals';
import { LogicRule } from '../FormLogicPage/LogicPageComponent';
import { EditIcon } from '../../components/Icons';
import { RuleBuilder } from './components/RuleBuilder';
import { Tab, Tabs } from 'react-bootstrap';
import { ConfirmationModal } from './components/ConfirmationModal';
import { transformToCode } from './components/RuleBuilder/transform';
import RuleCodeEditor, { CODE_DOCS_URL } from './components/RuleCodeEditor';
import RuleFieldsPanel, { TriggerEvents } from './components/RuleFieldsPanel';
import useFeatheryRedux from '../../redux';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useFieldKey from '../../utils/useFieldKey';
import { RuleDSLManager } from './components/RuleBuilder/context/RuleDSL';
import { getAllFieldsInDSL } from './components/RuleBuilder/transform/utils';
import { isEmptyDSL, getPrivateActions } from './components/RuleBuilder/utils';

export default function FormLogicRulePage() {
  const { formId, ruleId } = useParams<{ formId: string; ruleId: string }>();
  const history = useHistory();
  const getFieldKey = useFieldKey(true);
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const workingLogicRules = useAppSelector(
    (s) => s.formBuilder.workingLogicRules
  );

  const {
    formBuilder: { setPanelDataWithUndoRedo, filterUndoRedoStacksByType }
  } = useFeatheryRedux();

  const [activeTab, setActiveTab] = useState('rule_builder');
  const [mode, setMode] = useState<'edit' | 'view'>('view');
  const [showRevertModal, setShowRevertModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showOnlyNoCodeModal, setShowOnlyNoCodeModal] =
    useState<boolean>(false);
  const [privateActions, setPrivateActions] = useState<string>('');
  const [rule, setRule] = useState<LogicRule | null>(null);
  const [initialized, setInitialized] = useState(false);

  // Implement dynamically calculated height for the ruleContainer to take up the remaining height
  const containerRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (containerRef.current) {
      if (activeTab !== 'rule_builder') {
        // Set containerRef height to the height of the window minus the top offet of the containerRef
        const height =
          window.innerHeight -
          containerRef.current.getBoundingClientRect().top -
          32; // 32 is the bottom padding of the tab container holding this page (avoid scroll)
        containerRef.current.style.height = `${height}px`;
      } else {
        containerRef.current.style.height = 'auto';
      }
    }
  }, [activeTab]);

  useEffect(() => {
    workingLogicRules[ruleId] && setRule(workingLogicRules[ruleId]);
  }, [workingLogicRules, ruleId]);

  // Initially set the active tab to the mode of the rule
  useEffect(() => {
    if (initialized) {
      return; // Nothing to do
    }

    if (workingLogicRules[ruleId] && !initialized) {
      setInitialized(true);
      setActiveTab(workingLogicRules[ruleId].mode || 'rule_builder');
    }
  }, [workingLogicRules, ruleId, initialized]);

  // renaming the rule
  const [isRenaming, setIsRenaming] = useState(false);
  const nameEditRef = useRef<any>();
  useEffect(() => {
    if (isRenaming) setTimeout(() => nameEditRef.current.focus(), 100);
  }, [isRenaming]);

  const [showDeleteRuleModal, setShowDeleteRuleModal] = useState(false);
  const deleteRule = (rule: LogicRule) => {
    setPanelDataWithUndoRedo({
      id: rule.id,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: objectPick(workingLogicRules, [rule.id]),
      newRulesValue: {},
      workingLogicRules: objectRemove(workingLogicRules, [rule.id]),
      title: UNDO_TITLES.DELETE_LOGIC_RULE,
      type: UNDO_TYPES.LOGIC_RULES
    });
    history.push(`/forms/${panel.id}/logic`);
  };

  const updateRule = (rule: LogicRule) => {
    // if changing the trigger event, then must also update the runtime order index as well
    if (rule.trigger_event !== workingLogicRules[rule.id].trigger_event) {
      // The index is used for determining the run order for the rules that run off the same event
      rule.index =
        Object.values(workingLogicRules)
          .filter((r: any) => r.trigger_event === rule.trigger_event)
          .reduce((acc: number, r: any) => Math.max(acc, r.index), 0) + 1;
    }

    setRule(rule);
    setPanelDataWithUndoRedo({
      id: rule.id,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: objectPick(workingLogicRules, [rule.id]),
      newRulesValue: { [rule.id]: rule },
      workingLogicRules: { ...workingLogicRules, [rule.id]: rule },
      title: UNDO_TITLES.LOGIC_RULES,
      type: UNDO_TYPES.LOGIC_RULES
    });
  };

  const handleRuleRename = (newName: string) => {
    if (rule) {
      setIsRenaming(false);
      newName = newName.trim();

      if (!newName) return;
      const allKeys = Object.values(workingLogicRules).map(
        (rule: any) => rule.name
      );
      if (allKeys.includes(newName)) return;

      updateRule({ ...rule, name: newName });
    }
  };

  const handleCodeChange = ({
    text,
    valid
  }: {
    text: string;
    valid?: boolean;
  }) => {
    if (rule) {
      updateRule({
        ...rule,
        code: text,
        valid: valid ?? rule.valid
      });
    }
  };

  const handleRuleBuilderComplete = (payload: any) => {
    if (rule) {
      updateRule({
        ...rule,
        dsl: payload.dsl ?? rule.dsl,
        valid: payload.valid ?? rule.valid,
        code: payload.code ?? rule.code
      });
    }
  };

  const handleChangeMode = (mode: string) => {
    if (!rule) return; // Can't do anything

    if (mode === 'rule_builder') {
      const manager = new RuleDSLManager(rule.dsl as IRuleDSL);
      const fields = getAllFieldsInDSL(rule.dsl as IRuleDSL, true);

      // This is to ensure that each referenced field has a valid key referenced
      fields.forEach((field) => {
        manager.updateOperand(field.id, {
          ...field,
          meta: {
            ...field.meta,
            field_key: getFieldKey(field.value)
          }
        });
      });

      const dsl = manager.toJSON() as IRuleDSL;

      transformToCode(dsl as IRuleDSL).then((code) => {
        updateRule({
          ...rule,
          dsl,
          code,
          mode: 'rule_builder'
        });
      });
    } else if (mode === 'code_editor') {
      updateRule({
        ...rule,
        mode: 'code_editor'
      });
    }
  };

  const handleSetActiveTab = (tab: string) => {
    if (tab === 'rule_builder' && rule?.mode === 'code_editor') {
      setShowRevertModal(true);
    } else if (tab === 'code_editor' && mode === 'edit') {
      setShowCancelModal(true);
    } else if (tab === 'code_editor' && rule?.mode === 'rule_builder') {
      const _privateActions = getPrivateActions(rule?.dsl);
      if (_privateActions !== '') {
        setPrivateActions(_privateActions);
        setShowOnlyNoCodeModal(true);
      } else setActiveTab(tab);
    } else {
      setActiveTab(tab);
    }
  };

  const [isCodeEditorMaximized, setIsCodeEditorMaximized] = useState(false);

  return (
    <>
      <ConfirmationModal
        show={showRevertModal}
        setShow={setShowRevertModal}
        title='Warning'
        message={
          isEmptyDSL(rule?.dsl)
            ? 'Using the Rule Builder will cause the code currently defined to be lost. Are you sure you want to continue?'
            : 'Using Rule Builder will revert all changes made in Advanced Logic. Are you sure you want to continue?'
        }
        onConfirm={() => {
          handleChangeMode('rule_builder');
          setActiveTab('rule_builder');
        }}
        onCancel={() => setShowRevertModal(false)}
      />
      <ConfirmationModal
        show={showCancelModal}
        setShow={setShowCancelModal}
        title='Warning'
        message='Any changes that have been made to the rule builder will be lost. Are you sure you want to continue without saving?'
        onConfirm={() => {
          setMode('view');
          filterUndoRedoStacksByType(UNDO_TYPES.RULE_BUILDER);
          setActiveTab('code_editor');
        }}
        onCancel={() => setShowCancelModal(false)}
      />
      <ConfirmationModal
        show={showOnlyNoCodeModal}
        setShow={setShowOnlyNoCodeModal}
        title='Warning'
        message={`The following actions are not supported in the code editor: ${privateActions}. Please remove them before switching.`}
        onCancel={() => setShowOnlyNoCodeModal(false)}
      />
      {rule ? (
        <div className={styles.ruleContainer} ref={containerRef}>
          <div className={styles.headerContainer}>
            <div className={styles.ruleNameLeft}>
              <Button.Back
                className={styles.backButton}
                onClick={() => history.push(`/forms/${formId}/logic`)}
              />
              <h1 className={styles.ruleHeader}>Logic Rule:</h1>
              {isRenaming ? (
                <TextField
                  value={rule.name}
                  onComplete={handleRuleRename}
                  maxLength={128}
                  ref={nameEditRef}
                  className={styles.input}
                />
              ) : (
                <>
                  <div
                    className={styles.ruleHeader}
                    onDoubleClick={() => setIsRenaming(true)}
                  >
                    {rule.name}
                  </div>
                  <EditIcon
                    className={styles.ruleEditIcon}
                    width={16}
                    height={16}
                    color='#000000'
                    onClick={() => setIsRenaming(true)}
                  />
                </>
              )}
            </div>
            <div className={styles.ruleActions}>
              <div className={styles.ruleStatus}>
                <span>Status</span>{' '}
                <OnOffSwitch
                  checked={rule?.enabled}
                  onChange={(val: boolean) =>
                    updateRule({ ...rule, enabled: val })
                  }
                  className={styles.ruleStatusSwitch}
                />
              </div>
              <button
                className={classNames('btn btn-custom', styles.delete)}
                onClick={() => setShowDeleteRuleModal(true)}
              >
                Delete Rule
              </button>
            </div>
          </div>
          <div className={styles.ruleContent}>
            {!isCodeEditorMaximized && (
              <RuleFieldsPanel
                rule={rule}
                setRule={updateRule}
                showEnabled
                validation
                columns={2}
              />
            )}
            <Tabs
              className={styles.ruleTabs}
              activeKey={activeTab}
              onSelect={(tab: string | null) =>
                tab && handleSetActiveTab(tab as string)
              }
              fill={false}
              unmountOnExit
            >
              <Tab
                className={styles.ruleTab}
                eventKey='rule_builder'
                title='Rule Builder'
              >
                <RuleBuilder
                  rule={rule}
                  mode={mode}
                  onComplete={handleRuleBuilderComplete}
                  setMode={setMode}
                />
              </Tab>
              <Tab
                className={classNames(styles.ruleTab, styles.codeEditor)}
                eventKey='code_editor'
                title='Advanced Logic'
              >
                <RuleCodeEditor
                  onChange={(code) => handleCodeChange(code)}
                  onChangeMode={(mode: any) => handleChangeMode(mode)}
                  onToggleMaximize={(isMaximized: boolean) =>
                    setIsCodeEditorMaximized(isMaximized)
                  }
                  isMaximized={isCodeEditorMaximized}
                  code={{
                    text: rule.code,
                    valid: rule.valid
                  }}
                  triggerEvent={rule.trigger_event as TriggerEvents}
                  readOnly={rule?.mode === 'rule_builder'}
                  readOnlyText={
                    <>
                      Code Editor unlocks{' '}
                      <a href={CODE_DOCS_URL} target='_blank' rel='noreferrer'>
                        advanced functionality
                      </a>{' '}
                      for your rule. Click <b>Edit Code</b> to start.
                    </>
                  }
                />
              </Tab>
            </Tabs>
          </div>
          <DeleteRuleConfirmModal
            show={showDeleteRuleModal}
            setShow={setShowDeleteRuleModal}
            rule={rule}
            deleteRule={deleteRule}
          />
        </div>
      ) : (
        <LogoLoader />
      )}
    </>
  );
}
