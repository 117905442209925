import { memo, useMemo, useState } from 'react';
import Dialog from '../../../components/Dialog';
import { Positive } from '../../../components/Core/Button';
import Label from '../../../components/Dialog/Label';
import { DropdownMultiField } from '../../../components/Core';

function EnvelopeTagsModal({ tags, updateTags, envelopes, show, close }: any) {
  const [curTags, setCurTags] = useState(tags);
  const tagOptions = useMemo(() => {
    if (!envelopes) return [];

    const tags = new Set();
    envelopes.forEach((envelope: any) => {
      envelope.tags.forEach((tag: string) => tags.add(tag));
    });
    return Array.from(tags).map((tag) => ({ value: tag, label: tag }));
  }, [envelopes]);

  return (
    <Dialog
      isOpen={show}
      title='Filter by Document Tags'
      size='sm'
      onClose={close}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          updateTags(curTags);
          close();
        }}
        style={{ marginTop: '20px' }}
      >
        <Label>Tags</Label>
        <DropdownMultiField
          selected={curTags}
          options={tagOptions}
          onChange={(items: { value: string }[]) =>
            setCurTags(items.map((item) => item.value))
          }
        />
        <div
          className='dialog-form-action text-center'
          style={{ marginTop: '100px' }}
        >
          <Positive title='Filter' type='submit' />
        </div>
      </form>
    </Dialog>
  );
}

export default memo(EnvelopeTagsModal);
