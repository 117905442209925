import { memo, useMemo, useState } from 'react';
import useMounted from '../../../utils/useMounted';
import { Spinner } from 'react-bootstrap';

import classNames from 'classnames';

function LockoutButton({
  icon,
  btnClass,
  title: label,
  onClick = () => {},
  lockoutOverride = false,
  setLockoutOverride = (lockout: boolean) => {},
  loading = false,
  lockout = true,
  ...props
}: any) {
  const [lockedOut, setLockedOut] = useState(false);
  const mounted = useMounted();
  const [thisButtonClicked, setThisButtonClicked] = useState(false);

  const handleClick = useMemo(
    () => async (e: any) => {
      setThisButtonClicked(true);
      if (lockout) {
        setLockedOut(true);
        setLockoutOverride(true);
      }
      await onClick(e);
      // Only reset if still mounted
      if (mounted.current) {
        setLockedOut(false);
        setLockoutOverride(false);
      }
    },
    [lockout, setLockedOut, mounted, onClick]
  );

  const isLockedOut = lockoutOverride || lockedOut;

  const content = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {loading || (isLockedOut && thisButtonClicked) ? (
        <Spinner
          animation='border'
          role='status'
          style={{ width: '16px', height: '16px', marginRight: '10px' }}
        />
      ) : (
        !!icon && icon
      )}
      {/* Must wrap text in span so Google Translate doesn't replace spinner during translation. That breaks the app */}
      <span>{label}</span>
    </div>
  );

  return (
    <button
      onClick={handleClick}
      disabled={loading || isLockedOut}
      className={classNames('btn', 'btn-custom', btnClass)}
      {...props}
    >
      {content}
    </button>
  );
}

export default memo(LockoutButton);
