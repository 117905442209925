import { v4 as uuidv4 } from 'uuid';
import {
  PDFCheckBox,
  PDFDocument,
  PDFDropdown,
  PDFOptionList,
  PDFRadioGroup,
  PDFSignature,
  PDFTextField
} from 'pdf-lib';
import {
  AnnotatedField,
  AnnotatedFieldType
} from '../pages/DocumentEditorPage/types';
import { DISPLAY_PDF_PAGE_WIDTH } from '../pages/DocumentEditorPage';
import { REGION } from '../api/utils';

export type FileType = 'pdf' | 'docx' | 'xlsx';

export function getSignUrl(document: any) {
  const region = REGION ? `${REGION}.` : '';
  return `https://${region}document.feathery.io/to/${document.id}`;
}

const fileTypeMap: Record<string, FileType> = {
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'application/msword': 'docx',
  'application/vnd.ms-excel': 'xlsx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
} as const;

export function getFileType(file: File): FileType | null {
  return fileTypeMap[file.type] ?? null;
}

export async function validatePdf(readPromise: Promise<any>): Promise<any> {
  return new Promise((resolve, reject) => {
    let hasFields = false;
    readPromise
      .then((result) => {
        PDFDocument.load(result)
          .then((pdfDoc) => {
            // do a simple check by getting the form fields
            const form = pdfDoc.getForm();
            hasFields = form.getFields().length > 0;
            // no errors, so resolve
            resolve({ error: '', hasFields });
          })
          .catch((err) => {
            // Couldn't get `err instance of EncryptedPDFError` to work
            // Reading error message for the word 'encrypted' instead.
            if (err.toString().includes('encrypted')) {
              resolve({
                error: `The file you uploaded is password-protected or encrypted. Please remove the password protection and try uploading the file again.`,
                hasFields
              });
            }
            resolve({ error: `PDF did not validate - ${err}`, hasFields });
          });
      })
      .catch((err) => {
        resolve({
          error: 'PDF did not validate - failed to load file',
          hasFields
        });
      });
  });
}

export function readFile(file: Blob): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) resolve(reader.result);
      else resolve('Failed to load file');
    };
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}

export async function createAnnotatedFieldsFromPdf(
  fileReadPromise: Promise<string | ArrayBuffer> | null,
  fieldsRequired: 'required' | 'optional'
): Promise<AnnotatedField[] | null> {
  return new Promise((resolve, reject) => {
    if (!fileReadPromise) return resolve(null);
    fileReadPromise
      .then((result) => {
        PDFDocument.load(result)
          .then((pdfDoc) => {
            // get annotated fields from pdf fields
            const form = pdfDoc.getForm();
            const pageRects = pdfDoc
              .getPages()
              // Sometimes the page height is negative (NitroPDF??).
              // Use absolute value of height/width.
              .map((page) => {
                const pageSize = page.getSize();
                return {
                  width: Math.abs(pageSize.width),
                  height: Math.abs(pageSize.height)
                };
              });
            // sometimes the page number is not set on the widget,
            // so if there is only one page, we will use that
            // Otherwise we ignore it because there is no other way to get it.
            const defaultPage = pdfDoc.getPages().length === 1 ? 0 : -1;

            const annotatedFields: AnnotatedField[] = [];
            form.getFields().forEach((field) => {
              const widgets = field.acroField.getWidgets();

              widgets.forEach((widget) => {
                const widgetPage = widget.P();
                let page = widgetPage
                  ? pdfDoc.getPages().findIndex((x) => x.ref === widgetPage)
                  : -1;
                if (page < 0) page = defaultPage;

                let type: AnnotatedFieldType = 'button';
                if (field instanceof PDFTextField) type = 'text';
                else if (field instanceof PDFRadioGroup) type = 'radio';
                else if (field instanceof PDFOptionList) type = 'listbox';
                else if (field instanceof PDFSignature) type = 'signature';
                else if (field instanceof PDFCheckBox) type = 'checkbox';
                else if (field instanceof PDFDropdown) type = 'combobox';

                // PDF buttons are not mappable
                if (type !== 'button') {
                  // if the widget has a page index - it is good to go
                  const rect = widget.getRectangle();
                  const name = field.getName();
                  if (page >= 0 && pageRects[page]) {
                    const scale =
                      pageRects[page].width / DISPLAY_PDF_PAGE_WIDTH;
                    // sometimes there are fillable fields that have
                    // negative height.  We don't want to ignore these.
                    // Use the absolute value of the height and adjust y.
                    const width = Math.abs(rect.width);
                    const height = Math.abs(rect.height);
                    const y = rect.height < 0 ? rect.y - height : rect.y;
                    annotatedFields.push({
                      x: rect.x / scale,
                      y: (pageRects[page].height - y - height) / scale,
                      // sometimes there are fillable fields that have
                      // negative height or width.  We don't want to ignore these.
                      // Use the absolute value of the height and width.
                      width: width / scale,
                      height: height / scale,
                      page,
                      type,
                      id: uuidv4(),
                      pdf_field_id: name,
                      required: fieldsRequired === 'required',
                      ...(type === 'listbox' || type === 'combobox'
                        ? {
                            properties: {
                              options: (field as PDFDropdown).getOptions()
                            }
                          }
                        : {})
                    });
                  }
                }
              });
            });
            resolve(annotatedFields);
          })
          .catch((err) => {
            resolve(null);
          });
      })
      .catch((err) => {
        resolve(null);
      });
  });
}

export function mergeDocumentFields(
  oldFields: AnnotatedField[],
  newFields: AnnotatedField[]
): AnnotatedField[] {
  const mergedFields = [...oldFields];

  newFields.forEach((field) => {
    const existingFieldIndex = mergedFields.findIndex((f) => {
      return f.pdf_field_id === field.pdf_field_id;
    });
    if (existingFieldIndex >= 0) {
      mergedFields[existingFieldIndex] = {
        ...mergedFields[existingFieldIndex],
        x: field.x,
        y: field.y,
        width: field.width,
        height: field.height
      };
    } else {
      mergedFields.push({ ...field });
    }
  });

  return mergedFields;
}
