import FormNavigation from '../../components/NavBar/FormNavigation';
import styles from './styles.module.scss';
import pageStyles from '../styles.module.scss';
import HeaderFilterResultsTable from '../../components/HeaderFilterResultsTable';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { useMemo, useState } from 'react';
import { formatDate } from '../../utils/format';
import classNames from 'classnames';
import ExtractionCreateEditModal from './ExtractionCreateEditModal';

export default function AIExtractionListPage() {
  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();

  const extractions = useAppSelector((state) =>
    state.ai.extractions.filter((extraction) => extraction.panel === formId)
  );
  const servars = useAppSelector((state) => state.fields.servars ?? []);
  const hiddenFields = useAppSelector(
    (state) => state.fields.hiddenFields ?? []
  );
  const [create, setCreate] = useState(false);

  const dataToRender = useMemo(() => {
    return extractions.map((extraction: any) => {
      return {
        ...extraction,
        updated_at_pretty: formatDate(extraction.updated_at),
        sources: extraction.file_sources
          .map((source: string) => {
            const s = servars.find((servar) => servar.id === source);
            if (s) return s.key;
            else return hiddenFields.find((hf) => hf.id === source).key;
          })
          .join(', ')
      };
    });
  }, [extractions]);

  return (
    <>
      <FormNavigation activeItem='aiExtraction' />
      <ExtractionCreateEditModal
        isOpen={create}
        close={() => setCreate(false)}
      />
      <div className={styles.aiExtractionsListPage}>
        <div className={pageStyles.pageHeadRow}>
          <h1 className={classNames('page-head', pageStyles.pageHeadTitle)}>
            AI Document Extractions
          </h1>
        </div>
        <HeaderFilterResultsTable
          data={dataToRender}
          columns={[
            { key: 'key', name: 'Name' },
            { key: 'sources', name: 'Files to Extract' },
            {
              key: 'updated_at_pretty',
              name: 'Last Updated',
              sortBy: 'updated_at'
            }
          ]}
          defaultSort={{ order: -1, key: 'updated_at' }}
          useSearch
          name='Extraction'
          onCreate={() => setCreate(true)}
          onSelect={(extraction: any) =>
            history.push(`/forms/${formId}/ai/${extraction.id}`)
          }
        />
      </div>
    </>
  );
}
