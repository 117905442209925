import { useEffect, useState } from 'react';
import useFeatheryRedux from '../../../../redux';
import { ROLLOUT_BASE_URL } from '../../../Integrations/RolloutSidebar/utils';
import { DropdownField } from '../../../Core';
import { Spinner } from 'react-bootstrap';
import styles from './styles.module.scss';

type RolloutCredentialPickerProps = {
  app: string;
  credentialKey: string | null;
  onChange: (credential: any, { previousCredential }?: any) => void;
  hideLabel?: boolean;
};

// Allows user to choose which integration account to use for their api connector
export function RolloutCredentialPicker({
  app,
  credentialKey,
  onChange,
  hideLabel
}: RolloutCredentialPickerProps) {
  const { fetchRolloutToken } = useFeatheryRedux();
  const [credentials, setCredentials] = useState<any[]>();

  useEffect(() => {
    fetchRolloutToken().then(async ({ token }: { token: string }) => {
      const headers = { Authorization: `Bearer ${token}` };
      let results: Record<string, any>[] = await fetch(
        `${ROLLOUT_BASE_URL}/credentials/${app}?` +
          new URLSearchParams({
            includeData: 'true'
          }).toString(),
        {
          headers
        }
      )
        .then((res) => res.json())
        .catch((error) => console.error(error));

      if (!results) {
        results = [];
      }

      if (!Array.isArray(results)) {
        console.error(
          `Could not fetch ${app} accounts:`,
          (results as any)?.message
        );
        results = [];
      }
      setCredentials(results);

      if (results.length && !credentialKey) {
        onChange(results[0]);
      }
    });
  }, [app]);

  const appDisplay = app.charAt(0).toUpperCase() + app.slice(1);
  return (
    <label className={styles.credential_picker}>
      {!hideLabel && appDisplay}
      {credentials && (
        <>
          <DropdownField
            onChange={(event: any) => {
              const previousCredential = credentialKey
                ? credentials.find(
                    (option) => option.credentialKey === credentialKey
                  )
                : null;

              onChange(
                event.target.value
                  ? credentials.find(
                      (option) => option.credentialKey === event.target.value
                    )
                  : null,
                previousCredential ? { previousCredential } : undefined
              );
            }}
            selected={credentialKey ?? credentials[0]?.credentialKey ?? ''}
            options={
              credentials.length
                ? credentials.map((option) => ({
                    display: option.label,
                    value: option.credentialKey
                  }))
                : [
                    {
                      value: '',
                      display: `No linked ${appDisplay} account found`
                    }
                  ]
            }
            disabled={!credentials.length}
            hideCaret={!credentials.length}
          />
          {!credentials.length && (
            <div
              style={{ color: 'var(--red-border)' }}
            >{`Can't use ${appDisplay} token if integration is not set up.`}</div>
          )}
        </>
      )}
      {!credentials && (
        <Spinner
          animation='border'
          role='status'
          className={styles.credential_spinner}
        />
      )}
    </label>
  );
}
