import { useEffect, useMemo, useRef, useState } from 'react';
import { AIExtractionEntry } from '../types';
import { AIStarIcon, CaretIcon, InfoIcon } from '../../../components/Icons';
import { useExtractionState, useExtractionUpdater } from '../context';
import { useSyncedRefState } from '../../../hooks/useSyncedRefState';
import { getValueId } from '../utils';
import { useScreen } from '../../../hooks/useOnScreen';
import useField from '../../../utils/useField';
import AIFieldEntryValue from './AIFieldEntryValue';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FIELDS_WITH_OPTIONS } from '../../AIExtractionListPage/ExtractionCreateEditModal/constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type Props = {
  entry: AIExtractionEntry;
  onEdit?: (index: number, newValue: string) => void;
  onDelete?: (index: number) => void;
};

const AIFieldEntriesItem = ({
  entry,
  onEdit = () => {},
  onDelete = () => {}
}: Props) => {
  const [expanded, setExpanded] = useState(true);
  const { setSelectedValueId } = useExtractionUpdater();
  const { activeComponent, selectedValueId, hoveredValueId } =
    useExtractionState();

  const getField = useField();
  const getExpanded = useSyncedRefState(expanded);
  const getSelectedValueId = useSyncedRefState(selectedValueId);
  const getActiveComponent = useSyncedRefState(activeComponent);
  const entryFieldRef = useRef<HTMLDivElement>(null);
  const getEntryFieldRef = () => entryFieldRef?.current;

  const { isIntersecting, scrollIntoView } = useScreen(entryFieldRef);

  const valueIds = useMemo(() => {
    return entry.value.map((_, index) => getValueId(entry, index));
  }, [entry]);

  const fieldDetails = useMemo(() => {
    const field = getField(entry.fieldId);

    return {
      name: field ? field.key : 'Unknown Field',
      isMapped: field ? FIELDS_WITH_OPTIONS.includes(field.type) : false
    };
  }, [entry.fieldId]);

  // Helper function for scrolling and highlighting UX
  const scrollAndHighlight = (
    { scroll = false, highlight = false } = {},
    valueId?: string
  ) => {
    const entryFieldDiv = getEntryFieldRef();
    const _expanded = getExpanded();

    if (!entryFieldDiv) {
      return; // Nothing to do
    }

    if (scroll && getActiveComponent() === 'canvas') {
      if (valueId) {
        scrollIntoView(valueId);
      } else if (!_expanded && !isIntersecting()) {
        scrollIntoView();
      }
    }

    if (highlight) {
      entryFieldDiv.classList.add(styles.highlight);
    } else {
      entryFieldDiv.classList.remove(styles.highlight);
    }
  };

  // Unselect the value if no longer expanded
  useEffect(() => {
    const _selectedValueId = getSelectedValueId();
    const entryFieldDiv = getEntryFieldRef();

    if (!expanded && _selectedValueId && valueIds.includes(_selectedValueId)) {
      setSelectedValueId(null);
    }

    if (
      expanded &&
      entryFieldDiv &&
      entryFieldDiv.classList.contains(styles.highlight)
    ) {
      scrollAndHighlight({ highlight: true }); // Remove the highlight if expanded
    }
  }, [expanded, valueIds]);

  // Auto expand the values if the selected value is in the list
  useEffect(() => {
    const isExpanded = getExpanded();

    if (selectedValueId && valueIds.includes(selectedValueId)) {
      if (!isExpanded) {
        setExpanded(true);
      }

      scrollAndHighlight({ scroll: true }, selectedValueId);
    }
  }, [selectedValueId, valueIds]);

  // Highlight the div that contains the hovered value if not expanded
  useEffect(() => {
    const isExpanded = getExpanded();

    if (!hoveredValueId) {
      scrollAndHighlight();
      return; // Nothing to do if already expanded
    }

    if (hoveredValueId && valueIds.includes(hoveredValueId)) {
      scrollAndHighlight({ highlight: !isExpanded, scroll: !isExpanded });
    }
  }, [hoveredValueId, valueIds]);

  return (
    <div
      ref={entryFieldRef}
      id={`entry-${entry.id}`}
      className={styles.aiEntryField}
    >
      <div
        className={styles.header}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <div
          className={styles.text}
          style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
        >
          {fieldDetails.name}
          {fieldDetails.isMapped && (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>
                  Extracted values for this field will be mapped to the
                  field&apos;s options. The location of where the values were
                  extracted from cannot be determined.
                </Tooltip>
              }
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InfoIcon width={16} height={16} />
              </div>
            </OverlayTrigger>
          )}
        </div>
        <div className={classNames(styles.icon, { [styles.flip]: expanded })}>
          <CaretIcon width={20} height={20} />
        </div>
      </div>
      {expanded && (
        <div className={styles.content}>
          <div className={styles.header}>VALUES</div>
          <div className={styles.values}>
            {entry.value.map((_, index) => (
              <AIFieldEntryValue
                key={`${entry.id}-${index}`}
                entry={entry}
                index={index}
                onEdit={(newValue) => onEdit(index, newValue)}
                onDelete={() => onDelete(index)}
              />
            ))}
            {entry.value.length === 0 && (
              <div
                key={entry.id}
                className={classNames(styles.value, styles.disabled)}
              >
                <div className={classNames(styles.icon, styles.grey)}>
                  <AIStarIcon height={14} />
                </div>
                <div className={classNames(styles.text, styles.tag)}>
                  NO DATA
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AIFieldEntriesItem;
