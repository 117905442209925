import { Fragment, memo, useEffect, useMemo, useState } from 'react';

import '../../style/dialog-form.css';
import { Neutral, Positive } from '../Core/Button';
import Dialog from '../Dialog';
import { useParams } from 'react-router-dom';
import {
  ELEMENT_DATA,
  elementTypeToAsset,
  getAsset,
  themeSelectorToElementType
} from '../../utils/themes';
import elementEntries from '../SelectionPanel/elementEntries';

import styles from './styles.module.scss';
import { calculateElementRenderData } from '../../utils/step';
import { COMPONENT_MAP } from '../../utils/elements';
import { ChevronDownIcon, RightArrowIcon } from '../Icons';
import classNames from 'classnames';
import { StyledContainer } from '@feathery/react';
import { Viewport } from '../RenderingEngine/GridInGrid/engine';
import { useAppSelector } from '../../hooks';

function ThemeSwapModal({
  curThemeId,
  newThemeId,
  hideModal,
  setAssetSwaps
}: any) {
  const { formId } = useParams<{ formId: string }>();
  const [newAssetSwaps, setNewAssetSwaps] = useState({});

  const themeState = useAppSelector((state) => state.themes);
  const curTheme = themeState.themes[curThemeId];
  const newTheme = themeState.themes[newThemeId];
  const assetUsage = useMemo(
    () =>
      themeState.panelThemeAssetUse.find(
        (data: any) => data.panel_id === formId
      ),
    [themeState.panelThemeAssetUse, formId]
  );

  useEffect(() => {
    if (assetUsage && curTheme && newTheme) {
      const newSwaps = assetUsage.assets_in_use.reduce(
        (swaps: any, asset: any) => {
          swaps[asset.id] = '';
          return swaps;
        },
        {}
      );
      setNewAssetSwaps(newSwaps);
    }
  }, [curTheme, newTheme, assetUsage, formId]);

  const swapOptions = useMemo(() => {
    if (!newTheme) return {};

    return Object.keys(COMPONENT_MAP).reduce((allOptions, type) => {
      const options = [''];
      const assetType = elementTypeToAsset(type);
      let assets = newTheme[assetType] ?? [];
      if (assetType === 'servar_field_assets')
        assets = assets.filter((asset: any) => asset.properties.type === type);
      options.push(...assets.map((asset: any) => asset.id));
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      allOptions[type] = options;
      return allOptions;
    }, {});
  }, [newTheme]);

  const dataToRender = useMemo(() => {
    if (!assetUsage || !curTheme || !newTheme) return [];

    // Initialize curElements with assets to be replaced
    const curElements = Object.keys(COMPONENT_MAP).reduce((swap, type) => {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      swap[type] = [];
      return swap;
    }, {});
    // This method chain populates `curElements`
    (assetUsage.assets_in_use ?? [])
      .map((data: any) => {
        // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
        let elementType = themeSelectorToElementType(data.type);
        let category = [data.type];
        if (!elementType) {
          // themeSelectorToElementType will be empty on a servar field type
          elementType = data.type;
          category = ['field', data.type];
        }
        return [
          getAsset(curTheme, elementType, data.id),
          elementType,
          category
        ];
      })
      .reduce((swap: any, [asset, elementType, category]) => {
        swap[elementType].push({ asset, category });
        return swap;
      }, curElements);

    return (
      <div className={styles.themeSwapContainer}>
        {Object.entries(curElements).map(([elementType, assets]) => {
          if ((assets as any).length === 0) return null;

          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          const ElementComponent = COMPONENT_MAP[elementType];
          return (
            <div
              key={elementType}
              className={styles.themeSwapTypeContainer}
              style={{
                gridTemplateRows: `25px 40px ${'200px 30px '.repeat(
                  (assets as any).length
                )}`
              }}
            >
              <span className={styles.typeLabel}>
                {elementEntries[elementType].label}
              </span>
              <span className={styles.curThemeLabel}>{curTheme.key}</span>
              <span className={styles.newThemeLabel}>{newTheme.key}</span>
              {(assets as any).map(({ asset, category }: any, i: any) => {
                const gridRow = `${i * 2 + 3} / ${i * 2 + 4}`;
                // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                const newThemeId = newAssetSwaps[asset.id];
                const elementDataType =
                  category[0] === 'field' ? category[1] : category[0];
                let swapElement, swapElementLabel;
                if (newThemeId) {
                  swapElement = getAsset(newTheme, elementType, newThemeId);
                  swapElementLabel = (
                    <div className={styles.elementContainerLabel}>
                      {swapElement.key}
                    </div>
                  );
                } else {
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  swapElement = ELEMENT_DATA[elementDataType];
                  swapElementLabel = (
                    <div
                      className={classNames(
                        styles.elementContainerLabel,
                        styles.primary
                      )}
                    >
                      PRIMARY
                    </div>
                  );
                }
                // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                const options = swapOptions[elementType];
                const swapControls =
                  options.length === 1 ? null : (
                    <>
                      <div
                        className={classNames(styles.swapToggle, styles.left)}
                        onClick={() => {
                          // Add length of options to handle negative number modulo
                          const index =
                            (options.indexOf(newThemeId) + options.length - 1) %
                            options.length;
                          setNewAssetSwaps({
                            ...newAssetSwaps,
                            [asset.id]: options[index]
                          });
                        }}
                      >
                        <ChevronDownIcon width={16} height={16} />
                      </div>
                      <div
                        className={classNames(styles.swapToggle, styles.right)}
                        onClick={() => {
                          const index =
                            (options.indexOf(newThemeId) + 1) % options.length;
                          setNewAssetSwaps({
                            ...newAssetSwaps,
                            [asset.id]: options[index]
                          });
                        }}
                      >
                        <ChevronDownIcon width={16} height={16} />
                      </div>
                    </>
                  );

                const curElement = {
                  ...calculateElementRenderData({
                    theme: curTheme,
                    element: asset,
                    style: category
                  }),
                  isElement: true
                };

                const newElement = {
                  ...calculateElementRenderData({
                    theme: newTheme,
                    element: swapElement,
                    style: category
                  }),
                  isElement: true
                };

                return (
                  <Fragment key={asset.key}>
                    <div
                      key={`label-${asset.key}`}
                      className={styles.elementLabelContainer}
                      style={{
                        gridRow,
                        gridColumn: '1 / 2'
                      }}
                    >
                      <div className={styles.elementContainerLabel}>
                        {asset.key}
                      </div>
                      <div className={styles.elementContainer}>
                        <StyledContainer
                          node={curElement}
                          viewport={Viewport.Desktop}
                          viewportOnly={true}
                        >
                          <ElementComponent
                            element={curElement}
                            editMode='disabled'
                          />
                        </StyledContainer>
                      </div>
                    </div>
                    <RightArrowIcon
                      className={styles.arrowIcon}
                      style={{
                        gridRow,
                        gridColumn: '2 / 3'
                      }}
                      width={30}
                      height={30}
                    />
                    <div
                      key={`options-${asset.key}`}
                      style={{
                        gridRow,
                        gridColumn: '3 / 4'
                      }}
                      className={styles.elementLabelContainer}
                    >
                      {swapElementLabel}
                      {swapControls}
                      <div className={styles.elementContainer}>
                        <StyledContainer
                          node={newElement}
                          viewport={Viewport.Desktop}
                          viewportOnly={true}
                        >
                          <ElementComponent
                            element={newElement}
                            editMode='disabled'
                          />
                        </StyledContainer>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }, [
    assetUsage,
    curTheme,
    newTheme,
    newAssetSwaps,
    setNewAssetSwaps,
    swapOptions
  ]);

  return (
    <Dialog
      isOpen={Boolean(newThemeId)}
      title='Swap Assets'
      onClose={hideModal}
      size='xlg'
    >
      {dataToRender}
      <div className='dialog-form-action text-center'>
        <Neutral
          title='Cancel'
          onClick={() => {
            setNewAssetSwaps({});
            hideModal();
          }}
        />
        <Positive
          title='Confirm'
          onClick={() => {
            setNewAssetSwaps({});
            setAssetSwaps({ ...newAssetSwaps });
            hideModal();
          }}
        />
      </div>
    </Dialog>
  );
}

export default memo(ThemeSwapModal);
