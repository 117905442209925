import { memo, useCallback } from 'react';

import Dialog from '../Dialog';
import { Neutral, Positive } from '../Core/Button';
import useModalSubmissionLockout from '../../utils/useModalSubmissionLockout';
import useGetJwt from '../../utils/useGetJwt';
import useFeatheryRedux from '../../redux';
import { parseAndThrowError } from '../../redux/utils';

function DownloadFormPdfModal({ panel, fuserId, show, close }: any) {
  const getJwt = useGetJwt();

  const {
    toasts: { addInfoToast, addErrorToast }
  } = useFeatheryRedux();

  const submit = useCallback(() => {
    const token = getJwt();
    return FeatheryAPI.getPanelPdf(token, {
      panelId: panel.id,
      fuserId
    })
      .then(async (res) => {
        if (res.status === 200)
          addInfoToast(
            "Your submission PDF will be emailed to you once it's ready."
          );
        else {
          const result = await res.json();
          parseAndThrowError(result, 'Your PDF download could not be started');
        }
      })
      .catch((e) => {
        addErrorToast({ text: e.toString() });
      })
      .finally(() => close());
  }, [panel.id, fuserId]);

  const { lockOutFlag, lockoutFunction } = useModalSubmissionLockout(submit);

  return (
    <Dialog isOpen={show} title='Export Form PDF' size='sm' onClose={close}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        A PDF of your form will be created and emailed to you.
        <div className='dialog-form-action text-right'>
          <Neutral title='Cancel' onClick={close} />
          <Positive title='Continue' lockoutOverride={lockOutFlag} />
        </div>
      </form>
    </Dialog>
  );
}

export default memo(DownloadFormPdfModal);
