/* eslint-disable react-hooks/exhaustive-deps */

import { Col, Row } from 'react-bootstrap';
import {
  CheckboxField,
  CollapsibleSection,
  DropdownField,
  InlineTooltip,
  PropertyLabel,
  SoloImageField,
  TextField
} from '../../Core';
import { memo, useMemo } from 'react';
import {
  assetUpdateOperations,
  elementOperation,
  getAsset
} from '../../../utils/themes';
import {
  ACTION_OPTIONS,
  calculateOverrideObjects,
  getActionOptions
} from '../utils';

import {
  CustomValidationsSection,
  HideIfsSection,
  TextPropertySection
} from './components';
import { objectPick } from '../../../utils/core';
import { useParams } from 'react-router-dom';
import { TYPE_BUTTON } from '../../../utils/elements';
import { INTEGRATIONS } from '../../Integrations';
import useIntegrations from '../../Integrations/useIntegrations';
import ClickActionSection from '../Sections/ClickActionSection';
import { useRepeatedContainerChecks } from '../../../hooks/useRepeatedContainerChecks';
import { useAppSelector } from '../../../hooks';

function ButtonPropertiesPanel({
  mode,
  viewport,
  theme,
  baseProps,
  overrideProps,
  handleUpdates
}: any) {
  const { result: element, isOverride: isOverrideProp } =
    calculateOverrideObjects(baseProps, overrideProps, true);
  const { repeatExists, inRepeatContainer } =
    useRepeatedContainerChecks(element);
  const asset = getAsset(theme, TYPE_BUTTON, element.source_asset);

  const { formId } = useParams<{ formId: string }>();
  const integrations = useIntegrations({
    types: [
      INTEGRATIONS.ARGYLE,
      INTEGRATIONS.PLAID,
      INTEGRATIONS.ALLOY,
      INTEGRATIONS.FLINKS,
      INTEGRATIONS.PERSONA,
      INTEGRATIONS.FIREBASE,
      INTEGRATIONS.STYTCH,
      INTEGRATIONS.SMS_OTP,
      INTEGRATIONS.SMS_MESSAGE,
      INTEGRATIONS.EMAIL_OTP,
      INTEGRATIONS.STRIPE,
      INTEGRATIONS.TELESIGN,
      INTEGRATIONS.QUIK
    ],
    panelId: formId
  });

  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const org = useAppSelector((state) => state.accounts.organization);
  const starter = org?.tier >= 1;

  function handlePropChange(propUpdate: any) {
    handleUpdates([elementOperation({ viewport, propUpdate })]);
  }

  function handleInheritedPropChange(propUpdate: any) {
    handlePropChange({ properties: propUpdate });
  }

  function labelData(...propList: string[]) {
    return {
      mode,
      highlighted: isOverrideProp({ properties: propList }),
      themeOperations: {
        instance: {
          label: 'Reset to asset value',
          operation: () => {
            handleUpdates([
              elementOperation({ viewport, propReset: propList })
            ]);
          }
        },
        ...(asset && {
          asset: {
            label: asset.key,
            operation: () => {
              handleUpdates(
                assetUpdateOperations({
                  viewport,
                  elementType: TYPE_BUTTON,
                  asset,
                  newProps: objectPick(element.properties, propList),
                  newElementProps: objectPick(element, propList)
                })
              );
            }
          }
        })
      }
    };
  }

  const isTheme = mode === 'theme';
  const actionOptions = useMemo(
    () =>
      getActionOptions({ isTheme, repeatExists, integrations, org, panel }, [
        ACTION_OPTIONS.NEXT,
        ACTION_OPTIONS.BACK,
        ACTION_OPTIONS.ADD_REPEATED_ROW,
        ACTION_OPTIONS.REMOVE_REPEATED_ROW,
        ACTION_OPTIONS.OAUTH,
        ACTION_OPTIONS.MAGIC_LINK,
        ACTION_OPTIONS.SMS_CODE,
        ACTION_OPTIONS.VERIFY_SMS,
        ACTION_OPTIONS.EMAIL_CODE,
        ACTION_OPTIONS.VERIFY_EMAIL,
        ACTION_OPTIONS.SMS_MESSAGE,
        ACTION_OPTIONS.AI_DOCUMENT_EXTRACT,
        ACTION_OPTIONS.LOGOUT,
        ACTION_OPTIONS.PLAID,
        ACTION_OPTIONS.ALLOY_VERIFY_ID,
        ACTION_OPTIONS.FLINKS,
        ACTION_OPTIONS.PERSONA,
        ACTION_OPTIONS.ARGYLE,
        ACTION_OPTIONS.PURCHASE_PRODUCTS,
        ACTION_OPTIONS.SELECT_PRODUCT_TO_PURCHASE,
        ACTION_OPTIONS.REMOVE_PRODUCT_FROM_PURCHASE,
        ACTION_OPTIONS.INVITE_COLLABORATOR,
        ACTION_OPTIONS.VERIFY_COLLABORATOR,
        ACTION_OPTIONS.REWIND_COLLABORATION,
        ACTION_OPTIONS.STORE_FIELD_VALUE,
        ACTION_OPTIONS.OPEN_FUSER_ENVELOPES,
        ACTION_OPTIONS.GENERATE_QUIK_DOCUMENTS,
        ACTION_OPTIONS.URL,
        ACTION_OPTIONS.NEW_SUBMISSION,
        ACTION_OPTIONS.TELESIGN_SILENT_VERIFICATION,
        ACTION_OPTIONS.TELESIGN_PHONE_TYPE,
        ACTION_OPTIONS.TELESIGN_VOICE_OTP,
        ACTION_OPTIONS.TELESIGN_SMS_OTP,
        ACTION_OPTIONS.TELESIGN_VERIFY_OTP,
        ACTION_OPTIONS.CUSTOM
      ]),
    [isTheme, repeatExists, integrations, panel]
  );

  if (!element) return null;
  if (!element.properties) return null;

  return (
    <>
      <TextPropertySection
        element={element}
        handlePropChange={handlePropChange}
      />

      <ClickActionSection
        title='When Clicked'
        properties={element.properties}
        elementId={element.id}
        elementType='button'
        actionOptions={actionOptions}
        labelData={labelData}
        handlePropChange={handlePropChange}
        isTheme={isTheme}
        inRepeatContainer={inRepeatContainer}
      />
      <CollapsibleSection title='Behavior' collapsible>
        <Row>
          <Col>
            <CheckboxField
              checked={element.properties.captcha_verification}
              disabled={!starter}
              disabledMessage='You must be on a paid plan to turn on CAPTCHA'
              text={
                <>
                  CAPTCHA spam prevention{' '}
                  <InlineTooltip
                    text='Reach out to support@feathery.io if you need to use CAPTCHA on a custom domain'
                    inline
                  />
                </>
              }
              onChange={(isChecked) =>
                handleInheritedPropChange({
                  captcha_verification: isChecked
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CheckboxField
              checked={element.properties.disable_if_fields_incomplete}
              text='Disable until required fields are complete'
              onChange={(isChecked) =>
                handleInheritedPropChange({
                  disable_if_fields_incomplete: isChecked
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm='4'>
            <PropertyLabel
              label='Show Loader on Click'
              {...labelData('show_loading_icon')}
            />
          </Col>
          <Col>
            <DropdownField
              title='Loading indicator location'
              selected={element.properties.show_loading_icon ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInheritedPropChange({
                  show_loading_icon: e.target.value
                })
              }
              options={[
                { value: '', display: 'None' },
                { value: 'on_button', display: 'On Button' },
                { value: 'full_page', display: 'Full Step' }
              ]}
            />
          </Col>
        </Row>
        {element.properties.show_loading_icon ? (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                centered={false}
                label={
                  <>
                    Loader
                    <InlineTooltip
                      text='Defaults to a spinner if not specified'
                      inline
                    />
                  </>
                }
                {...labelData('loading_icon')}
              />
            </Col>
            <Col>
              <SoloImageField
                imageId={element.loading_icon}
                // @ts-expect-error TS(2322) FIXME: Type '(newImage: any) => void' is not assignable t... Remove this comment to see the full error message
                setImage={(newImage: any) =>
                  handlePropChange({
                    properties: { loading_icon: newImage?.file || '' },
                    loading_icon: newImage?.id || ''
                  })
                }
                fileTypes={['image/*', 'application/json']}
              />
            </Col>
          </Row>
        ) : null}
      </CollapsibleSection>

      <CollapsibleSection title='Accessibility' expanded={false}>
        <Row>
          <Col sm='4'>
            <PropertyLabel label='Aria Label' {...labelData('aria_label')} />
          </Col>
          <Col>
            <TextField
              value={element.properties.aria_label ?? ''}
              onComplete={(label: any) =>
                handleInheritedPropChange({
                  aria_label: label
                })
              }
              triggerCleanUp
            />
          </Col>
        </Row>
      </CollapsibleSection>

      {mode === 'builder' && (
        <>
          <CustomValidationsSection
            element={element}
            customValidations={(element as any).validations}
            handleUpdates={handleUpdates}
          />
          <HideIfsSection
            element={element}
            hideIfs={(element as any).hide_ifs}
            showLogic={element.show_logic}
            handleUpdates={handleUpdates}
          />
        </>
      )}
    </>
  );
}

export default memo(ButtonPropertiesPanel);
