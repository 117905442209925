import { memo } from 'react';

import styles from './styles.module.scss';
import classNames from 'classnames';

function ElementSection({
  label,
  highlighted,
  category,
  showLabel,
  children,
  onMouseDown,
  overflow = true,
  dark = false,
  className,
  style = {}
}: any) {
  return (
    <div
      id={`element-section-${category}`}
      className={classNames(
        className,
        styles.elementSection,
        styles[`element-section-${category}`],
        highlighted && styles.highlighted,
        onMouseDown && styles.highlightable,
        overflow && styles.overflow,
        dark && styles.dark
      )}
      style={style}
      onMouseDown={(event) => {
        if (onMouseDown) {
          event.stopPropagation();
          onMouseDown();
        }
      }}
    >
      <div className={showLabel ? styles.headingWrapper : ''}>
        {label && (
          <div className={showLabel ? styles.title : styles.header}>
            {label}
          </div>
        )}
        {showLabel && <div className={styles.label}>PRIMARY</div>}
      </div>
      {children}
    </div>
  );
}

export default memo(ElementSection);
