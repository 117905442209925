function Redtail({ width = 2500, height = 729 }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='2 -7.425204050850924 232.55 59.76207535433079'
      width={width}
      height={height}
    >
      <g fill='#c81414'>
        <path d='M62 43V9h9.57c9.56 0 13 3.58 13 10.25 0 5.52-4.72 8.65-9.92 9.88 1.76 6.9 6.63 9.16 10.93 9.16V43c-8 0-15.7-4-16.66-18.17h2.7c4.48 0 7.23-1.8 7.23-5.58s-2.38-5.57-7.24-5.57h-4V43zM109.81 43H90.57V9h19.24v4.7H96.13V23h12.81v4.66H96.13v10.63h13.68zM143.47 25.69q0 8.42-4.68 12.87T125.33 43h-9.51V9h10.51q8.12 0 12.63 4.37t4.51 12.32zm-5.86.18q0-12.21-11.42-12.21h-4.81v24.68h4q12.24 0 12.23-12.47zM159.66 43h-5.56V13.78h-10V9h25.56v4.77h-10zM191.66 43l-3.39-9.4h-12.94L172 43h-5.83l12.68-34h6l12.67 34zm-4.85-14.17l-3.17-9.22c-.23-.62-.55-1.59-1-2.92s-.69-2.3-.84-2.92A52.76 52.76 0 0 1 180 20l-3 8.84zM201.36 43V9h5.56v34zM214.6 43V9h5.56v29.24h14.39V43zM26 50a24 24 0 1 1 24-24 24 24 0 0 1-24 24zm22-24a22 22 0 1 0-22 22 22 22 0 0 0 22-22z' />
        <path d='M15.67 38.43c.33 0 .72-.13.92-.85s.88-3.08 1.11-3.89a6.44 6.44 0 0 1 1.6-2.45c.25 1.61.44 3.94.66 5.39a.75.75 0 0 0 .76.67.63.63 0 0 0 .61-.69c0-.34 0-.57-.07-1.36a21.93 21.93 0 0 1 .61-4.89c.21-.64 1.31-1.38 1.84-1.17a46.58 46.58 0 0 1 4.38 1.87c.6.34 1.11 1.86 1.31 3s.61 3.8.87 5.19A.78.78 0 0 0 31 40a.76.76 0 0 0 .72-.69c.1-1.69.14-5 .27-6.77a.93.93 0 0 1 .74-.93c.58-.17.71.31.89 1 .36 1.32 1.27 5.09 1.27 5.09a.77.77 0 0 0 .75.68.6.6 0 0 0 .56-.69c-.14-1.41-.51-4-.72-6.13-.1-1.05-.15-2.47-.31-3.64-.23-1.7-.36-1.76-.46-2.9a5.55 5.55 0 0 1 0-1.94c.26-.9 1.13-2.78 1.41-2.77.52 0 1.36.11 1.92.17a1.84 1.84 0 0 0 1.61-.67l.77-1.08c.31-.43.12-.69-.25-.8-.86-.27-2.56-.73-3.43-1s-.75-1.13-2.14-1.17a3.82 3.82 0 0 0-1.95.38c-1.07-1.41-1.26.12-2.25 1.16-1.47 1.54-1.24 2 1.4 1.44a6.78 6.78 0 0 1-1.23 2.16c-.87 1.1-1.86.94-2.85 1.5s-5.53.19-8.06.94a7.08 7.08 0 0 1-3.52-3.69 12.63 12.63 0 0 1-.6-5.52c-.17-.08-.87.31-1.36 1.36s-.5 1.75-.75 1.86-.61-.38-.76-.32-.19.67.16 1.52c.27.68.94 1.14.78 1.47s-.72-.05-.8.1.45.82 1.08 1.21 1.19.57 1.19.85-.62.08-.66.29 1.21.94 2.49 1.25c1.55.37 1.53.54 1.27 1.14-1.08 2.49-2.36 9.26-3 12.64-.27.56.11.93.49.93z' />
      </g>
    </svg>
  );
}

export default Redtail;
