/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useFeatheryRedux from '../../redux';
import { Negative, Neutral } from '../Core/Button';
import Dialog from '../Dialog';

import styles from './styles.module.scss';
import { useAppSelector } from '../../hooks';

function DeleteThemeModal({ show, setShow, themeId }: any) {
  const { deleteTheme } = useFeatheryRedux();
  const history = useHistory();
  const panels = useAppSelector((state) => state.panels.panels);
  const panelsWithTheme = Object.values(panels).filter(
    (p) => p.theme === themeId
  );
  const [error, setError] = useState('');

  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon={false}
      alignCenter
      title={panelsWithTheme.length > 0 ? 'Cannot be deleted' : 'Delete theme'}
      size='xsm'
    >
      {panelsWithTheme.length === 0 && (
        <>
          <div style={{ padding: '16px 8px' }}>
            Are you sure you want to delete this theme?
          </div>
          <div className='dialog-form-action'>
            <Neutral title='Cancel' onClick={() => setShow(false)} />
            <Negative
              title='Delete'
              onClick={async (event: any) => {
                event.preventDefault();
                try {
                  await deleteTheme({ themeId });
                  setShow(false);
                  history.push('/themes');
                } catch (error) {
                  // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
                  setError(error.message);
                }
              }}
            />
          </div>
        </>
      )}
      {panelsWithTheme.length > 0 && (
        <>
          <div style={{ padding: '8px 0' }}>
            This theme is active in the following form(s):
          </div>
          <ul className={styles.deleteThemeList}>
            {panelsWithTheme.map((p) => (
              <li key={(p as any).key}>{(p as any).key}</li>
            ))}
          </ul>
          <div style={{ padding: '16px 0' }}>
            Unlink this theme from all forms to continue.
          </div>
          <Neutral
            title='Cancel'
            style={{ margin: '0' }}
            onClick={() => setShow(false)}
          />
        </>
      )}

      {error && <div>{error}</div>}
    </Dialog>
  );
}

export default memo(DeleteThemeModal);
