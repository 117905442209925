import useFeatheryRedux from '../../redux';

import '../../style/dialog-form.css';
import { Neutral, Positive } from '../Core/Button';
import Dialog from '../Dialog';
import { useAppSelector } from '../../hooks';

export default function PendingInvitersModal() {
  const {
    editAccount,
    migrateAccount,
    toasts: { addToast }
  } = useFeatheryRedux();
  const account = useAppSelector((state) => state.accounts.account);
  const inviterEmail = account.pending_inviters[0];

  const declineInviter = async (e: any) => {
    e.preventDefault();
    await editAccount({
      pending_inviters: account.pending_inviters.slice(1),
      pending_permissions: account.pending_permissions.slice(1)
    });
    addToast({ text: 'Invite declined' });
  };

  const acceptInviter = async (e: any) => {
    e.preventDefault();
    await migrateAccount({ destination: inviterEmail });
    location.reload();
  };

  return (
    <Dialog isOpen={inviterEmail} title='Accept Invite to Join Team' size='sm'>
      {inviterEmail} invited you to join their team. If you accept the invite,
      you&apos;ll be moved out of your current team and into theirs.
      <div className='dialog-form-action text-center'>
        <Neutral title='Decline' onClick={declineInviter} />
        <Positive title='Accept' onClick={acceptInviter} />
      </div>
    </Dialog>
  );
}
