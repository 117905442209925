/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Neutral, Positive } from '../../Core/Button';

import Dialog from '../../Dialog';
import useFeatheryRedux from '../../../redux';
import styles from './styles.module.scss';
import ImageSelectCard from './ImageSelectCard';
import { useAppSelector } from '../../../hooks';

function ImageUploadModal({
  selectedImageId = '',
  onSelect = (newImage: any) => {},
  show = false,
  close = () => {},
  fileTypes = ['image/*']
}) {
  const { createImages } = useFeatheryRedux();
  const images = useAppSelector((state) => state.images);
  const [selected, setSelected] = useState(images[selectedImageId] || null);
  const fileInput = useRef<HTMLInputElement>(null);
  const {
    toasts: { addErrorToast }
  } = useFeatheryRedux();
  useEffect(
    () => setSelected(images[selectedImageId] || null),
    [selectedImageId]
  );

  const onCardSelect = (image: any, confirm = false) => {
    if (confirm) {
      onSelect(image);
      close();
    } else {
      setSelected(image);
    }
  };
  const clearSelection = () => onSelect(null);

  const imageRows = useMemo(() => {
    if (!show) return null;

    const imageList = Object.values(images)
      .filter((image) => fileTypes.includes((image as any).type))
      .sort((image) => (image as any).created_at)
      .reverse();

    const rows = [];
    // 3 thumbnails per row
    for (let i = 0; i < imageList.length; i += 3) {
      rows.push(
        <div className={styles.row} key={i}>
          <ImageSelectCard
            key={0}
            image={imageList[i]}
            index={0}
            onCardSelect={onCardSelect}
            clearSelection={clearSelection}
            selected={selected && selected.id === (imageList as any)[i]?.id}
          />
          <ImageSelectCard
            key={1}
            image={imageList[i + 1]}
            index={1}
            onCardSelect={onCardSelect}
            clearSelection={clearSelection}
            selected={selected && selected.id === (imageList as any)[i + 1]?.id}
          />
          <ImageSelectCard
            key={2}
            image={imageList[i + 2]}
            index={2}
            onCardSelect={onCardSelect}
            clearSelection={clearSelection}
            selected={selected && selected.id === (imageList as any)[i + 2]?.id}
          />
        </div>
      );
    }
    return rows;
  }, [show, images, selected]);

  return (
    <Dialog
      isOpen={show}
      onClose={close}
      shouldShowCloseIcon
      title='Select Image'
      size='lg'
    >
      <div className={styles.container}>{imageRows}</div>
      <div className='dialog-form-action text-center'>
        <Neutral
          title='Upload New'
          onClick={() => fileInput.current && fileInput.current.click()}
        />
        <Positive
          title='Select'
          onClick={() => {
            onSelect(selected);
            close();
          }}
        />
      </div>
      {/* Input component must be hidden, and it also remains empty since we track files in state here */}
      {/* Since the input is always empty, we have to check for existing data and ignore the required attribute */}
      <input
        ref={fileInput}
        type='file'
        multiple
        onChange={(event) => {
          const files: any = [];
          const types: any = [];
          const names: any = [];
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          Array.from(event.target.files).forEach((file) => {
            let type = file.type;
            if (type.split('/')[0] === 'image') {
              // Treat all images as the same type
              type = 'image/*';
              types.push(type);
              files.push(file);
              names.push(file.name);
            }
          });
          if (files.length !== event.target.files?.length) {
            addErrorToast({
              text: 'Could not upload one or more of your files. Only images are allowed.'
            });
          }
          if (files.length > 0)
            createImages({ file: files, name: names, type: types }).then(
              // Autoselect last uploaded image
              (data: any) => setSelected(data[data.length - 1])
            );

          // Clear file input
          event.target.value = '';
        }}
        accept={fileTypes.join(',')}
        style={{
          position: 'absolute',
          height: 1,
          width: 1,
          bottom: 0,
          opacity: 0
        }}
      />
    </Dialog>
  );
}

export default memo(ImageUploadModal);
