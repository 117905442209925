// JSON type definition file for the feathery context
// The format here is 'loosely' borrowed and extended from tern.js.
// see: https://ternjs.net/doc/manual.html#typedef
//

export const fieldContext = {
  id: 'string',
  value: 'any',
  clear: 'fn()',
  options: 'any',
  displayText: 'string',
  placeholder: 'string',
  disabled: 'boolean',
  required: 'boolean',
  onThisForm: 'boolean',
  isHiddenField: 'boolean',
  type: 'string',
  setError: 'fn(value)',
  setStyles: 'fn(value)',
  equals: 'fn(value)',
  notEquals: 'fn(value)',
  equalsIgnoreCase: 'fn(value)',
  notEqualsIgnoreCase: 'fn(value)',
  greaterThan: 'fn(value)',
  greaterThanOrEqual: 'fn(value)',
  lessThan: 'fn(value)',
  lessThanOrEqual: 'fn(value)',
  isFilled: 'fn()',
  isEmpty: 'fn()',
  isNumerical: 'fn()',
  isText: 'fn()',
  contains: 'fn(value)',
  doesNotContain: 'fn(value)',
  containsIgnoreCase: 'fn(value)',
  doesNotContainIgnoreCase: 'fn(value)',
  startsWith: 'fn(value)',
  doesNotStartWith: 'fn(value)',
  endsWith: 'fn(value)',
  doesNotEndWith: 'fn(value)',
  isTrue: 'fn()',
  isFalse: 'fn()',
  selectionsInclude: 'fn(value)',
  selectionsDoNotInclude: 'fn(value)'
};
const fieldsContext = {
  // special fields type - expand to all field keys
  '!fields': {
    '!type': fieldContext
  }
};
const cartItemContext = {
  id: 'string',
  name: 'string',
  price: 'number',
  price_formatted: 'string',
  quantity: 'number',
  quantity_formatted: 'string',
  subtotal: 'number',
  subtotal_formatted: 'string'
};
const cartContext = {
  items: {
    '!type': cartItemContext
  },
  total: 'number',
  total_formatted: 'string',
  addProductToCart: 'fn(productId, quantity, replace)',
  removeProductFromCart: 'fn(productId)'
};
const productContext = {
  name: 'string',
  id: 'string',
  description: 'string',
  mode: 'string',
  active: 'boolean',
  price: 'number',
  price_formatted: 'string',
  subscription_interval: 'string',
  currency: 'string',
  cart_quantity: 'number',
  cart_subtotal: 'number',
  cart_subtotal_formatted: 'string',
  addToCart: 'fn(quantity, replace)',
  removeFromCart: 'fn()',
  pricing_type: 'string'
};

const collaboratorContext = {
  role: 'string',
  orderNumber: 'number',
  permission: 'string',
  permissionFields: 'string[]'
};

const featheryBaseContext = {
  userId: 'userId',
  fields: { '!type': fieldsContext },
  goToStep: 'fn(stepId)',
  http: {
    get: 'fn()',
    post: 'fn()',
    patch: 'fn()',
    put: 'fn()',
    delete: 'fn()',
    connect: 'fn(connectorName)'
  },
  openUrl: 'fn(url, target)',
  runIntegrationActions: 'fn(actionIds, options)',
  getStepProperties: 'fn()',
  setFormCompletion: 'fn()',
  validateStep: 'fn(showErrors)',
  updateUserId: 'fn(newId)',
  setProgress: 'fn(progress)',
  isLastStep: 'fn()',
  isTestForm: 'fn()',
  cart: { '!type': cartContext },
  products: { '!type': productContext },
  collaborator: { '!type': collaboratorContext },
  applyAlloyJourney: 'fn(journeyToken, entities)',
  setCalendlyUrl: 'fn(url)',
  getFormFields: 'fn()',
  runAIExtraction: 'fn(extractionId, waitForCompletion, pages)',
  setFieldValues: 'fn(fieldValues)',
  getFieldValues: 'fn()',
  _getInternalUserId: 'fn()'
};
const triggerData = {
  id: 'string',
  text: 'string',
  type: 'string',
  repeatIndex: 'number'
};
const visibilityStatus = {
  elementId: 'string',
  isVisible: 'boolean'
};

export const featheryBaseDef = {
  feathery: {
    '!type': featheryBaseContext
  },
  '!fields': {
    '!type': fieldContext
  }
};

export const featherySubmitDef = {
  feathery: {
    '!type': featheryBaseContext,
    submitFields: 'object',
    trigger: triggerData
  },
  '!fields': {
    '!type': fieldContext
  }
};
export const featheryLoadDef = {
  feathery: {
    '!type': featheryBaseContext
  },
  '!fields': {
    '!type': fieldContext
  }
};
export const featheryChangeDef = {
  feathery: {
    '!type': featheryBaseContext,
    integrationData: {
      id: 'string',
      addressComponents: 'array',
      geometry: {
        latitude: 'string',
        longitude: 'string'
      }
    },
    trigger: triggerData,
    valueRepeatIndex: 'number'
  },
  '!fields': {
    '!type': fieldContext
  }
};
export const featheryActionDef = {
  feathery: {
    '!type': featheryBaseContext,
    trigger: triggerData,
    action: 'string'
  },
  '!fields': {
    '!type': fieldContext
  }
};
export const featheryErrorDef = {
  feathery: {
    '!type': featheryBaseContext,
    errorFieldId: 'string',
    errorFieldType: 'string',
    errorMessage: 'string',
    trigger: triggerData
  },
  '!fields': {
    '!type': fieldContext
  }
};
export const featheryFormCompleteDef = {
  feathery: {
    '!type': featheryBaseContext
  },
  '!fields': {
    '!type': fieldContext
  }
};
export const featheryViewDef = {
  feathery: {
    '!type': featheryBaseContext,
    visibilityStatus: visibilityStatus
  },
  '!fields': {
    '!type': fieldContext
  }
};

export const eventDefsMap: Record<string, any> = {
  submit: featherySubmitDef,
  load: featheryLoadDef,
  change: featheryChangeDef,
  action: featheryActionDef,
  error: featheryErrorDef,
  formComplete: featheryFormCompleteDef,
  view: featheryViewDef
};

// Utility functions

// Turn the feathery type def into JSON graph
export const typeDefToGraph = (typeDef: any, fieldKeys: string[]) => {
  const graph: any = {};
  Object.keys(typeDef).forEach((key: string) => {
    let def =
      typeDef[key] instanceof Object ? { ...typeDef[key] } : typeDef[key];
    // Flatten any !type into this type
    if (
      def instanceof Object &&
      def['!type'] &&
      def['!type'] instanceof Object
    ) {
      // add def['!type'] entries to def
      Object.keys(def['!type']).forEach((subKey) => {
        def[subKey] = def['!type'][subKey];
      });
      delete def['!type'];
      def = typeDefToGraph(def, fieldKeys);
    }
    // if the key is !fields, expand to all field keys
    if (key === '!fields') {
      fieldKeys.forEach((fieldKey) => {
        graph[fieldKey] = def;
      });
    } else graph[key] = def;
  });
  return graph;
};
