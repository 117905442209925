import { Fragment, memo } from 'react';
import { Neutral, Positive } from '../Core/Button';

import Dialog from '../Dialog';
import { useHistory, useParams } from 'react-router-dom';
import type { ERROR_WARNING, ERROR_WARNINGS } from '../../utils/useDraftOps';
import { VALIDATION_CODES } from '../../utils/useDraftOps';
import { useAppSelector } from '../../hooks';
import styles from './styles.module.scss';
import { LogicRule } from '../../pages/FormLogicPage/LogicPageComponent';

// Individual validation types rendering
const ValidationItem = ({ item, ...otherProps }: any) =>
  validationRenderers[item.code] ? (
    validationRenderers[item.code](item, otherProps)
  ) : (
    <span>
      {/* Standard validations just have a message */}
      {item.data.message ?? ''}
    </span>
  );
// custom validation items renderers
const validationRenderers = {
  // server-side validation
  [VALIDATION_CODES.FIELD_REF]: ({ data: formServarRefs }: ERROR_WARNING) => (
    <div className={styles.publishErrorWarning}>
      You have deleted fields used in other forms. Their usage will be removed
      when you publish.
      {formServarRefs.map((formServarRef: any, index: number) => (
        <div key={index}>
          <span className={styles.firstLevelItem}>
            Form: {formServarRef.form_key}
          </span>
          <ul>
            {formServarRef.servars.map((servar: any, sindex: number) => (
              <li key={sindex}>{servar.servar_key}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  ),

  // server-side validation
  [VALIDATION_CODES.SERVAR_NAME]: ({ data: servarFields }: ERROR_WARNING) => (
    <div className={styles.publishErrorWarning}>
      Some field(s) have conflicting IDs. We will adjust them when you publish.
      <ul>
        {servarFields.map((field: any, index: number) => (
          <li key={index}>{field.servar_key}</li>
        ))}
      </ul>
    </div>
  ),

  // server-side validation
  [VALIDATION_CODES.ASSET_IN_USE]: ({
    data: formAssetsInUse
  }: ERROR_WARNING) => (
    <div className={styles.publishErrorWarning}>
      These theme asset(s) are used in other forms and will not be deleted.
      {formAssetsInUse.map((formAssetInUseItem: any, index: number) => (
        <div key={index}>
          <span className={styles.firstLevelItem}>
            Form: {formAssetInUseItem.form_key}
          </span>
          <ul>
            {formAssetInUseItem.refs.map((asset: any, sindex: number) => (
              <li key={sindex}>
                {asset.asset_name} (type: {asset.element_type})
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  ),

  // client-side validation
  [VALIDATION_CODES.THEME]: ({ data: formKeys }: ERROR_WARNING) => (
    <div className={styles.publishErrorWarning}>
      You edited this form&apos;s theme. This will impact other forms using this
      theme:
      <ul>
        {formKeys.map((formKey: any, index: number) => (
          <li key={index}>{formKey}</li>
        ))}
      </ul>
    </div>
  ),

  // client-side validation
  [VALIDATION_CODES.INVALID_LOGIC_RULE]: (
    { data: any }: ERROR_WARNING,
    { jumpToLogicPage }: any
  ) => (
    <div>
      One or more logic rules are not valid. Please fix or disable them before
      publishing. Click{' '}
      <a href='#' onClick={jumpToLogicPage}>
        here
      </a>{' '}
      to go to your logic rules.
    </div>
  ),

  // client-side validation
  [VALIDATION_CODES.INVALID_LOGIC_RULE_CONFIG]: (
    { data: logicRulesWithEmptyElements }: ERROR_WARNING,
    { jumpToLogicPage }: any
  ) => (
    <div>
      One or more logic rules are incompletely configured (missing element
      trigger). Please fix them before publishing. Click{' '}
      <a href='#' onClick={jumpToLogicPage}>
        here
      </a>{' '}
      to go to your logic rules.
      <ul style={{ paddingLeft: 20 }}>
        {logicRulesWithEmptyElements.map(
          (logicRule: LogicRule, index: number) => (
            <li key={logicRule.id}>{logicRule.name}</li>
          )
        )}
      </ul>
    </div>
  ),

  // client-side validation
  [VALIDATION_CODES.PRIVATE_LOGIC_RULE]: (
    { data: privateActions }: ERROR_WARNING,
    { jumpToLogicPage }: any
  ) => (
    <div>
      The following logic rules are not supported in code editor:
      <ul>
        {privateActions.message.map((action: any, index: number) => (
          <li key={index}>{action}</li>
        ))}
      </ul>
      Please remove them before publishing.
    </div>
  ),

  // client-side validation
  [VALIDATION_CODES.LINKED_FIELDS]: (
    { data: linkedServars }: ERROR_WARNING,
    { servars, formId, panels, workingSteps }: any
  ) => (
    <div className={styles.publishErrorWarning}>
      You edited linked form fields. This will impact the following linked
      fields and forms:
      {Object.entries(linkedServars ?? {}).map(([fieldId, usages]) => (
        <Fragment key={fieldId}>
          <span className={styles.firstLevelItem}>
            Field: {servars[fieldId].key}
          </span>
          <ul style={{ paddingLeft: 20 }}>
            {[...(usages as any)]
              // Prioritize places where the field is used in the current form
              .sort((u1, u2) =>
                u1.panel_id === formId ? -1 : u2.panel_id === formId ? 1 : 0
              )
              .map((usage, index) => (
                <li key={index}>
                  Form:{' '}
                  {panels[usage.panel_id] ? panels[usage.panel_id].key : ''}{' '}
                  <br />
                  Step:{' '}
                  {usage.panel_id === formId && workingSteps[usage.step_id]
                    ? workingSteps[usage.step_id].key
                    : usage.step_key}
                </li>
              ))}
          </ul>
        </Fragment>
      ))}
    </div>
  )
};

export interface PublishWarningsAndErrorsModalProps {
  onPublish: () => void;
  errorsWarnings: ERROR_WARNINGS;
  setErrorsWarnings: (ew: ERROR_WARNINGS) => void;
}
const PublishWarningsAndErrorsModal = ({
  errorsWarnings,
  setErrorsWarnings,
  onPublish
}: PublishWarningsAndErrorsModalProps) => {
  const history = useHistory();

  // If there are (actionable) errors then put up an error dialog and disallow the publish.
  // If there are warnings, then list the warnings with a confirm button to publish.

  const errors = errorsWarnings && errorsWarnings.errors.length;
  const title = errors
    ? 'Please fix these issues before publishing.'
    : 'Publishing your form will result in these changes.';
  const validationItems = !errorsWarnings
    ? []
    : errors
    ? errorsWarnings.errors
    : errorsWarnings.warnings;

  const { formId } = useParams<{ formId: string }>();
  const panels = useAppSelector((state) => (state as any).panels.panels);
  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const servars = useAppSelector((s) => s.formBuilder.servars);

  const jumpToLogicPage = (e: any) => {
    e.preventDefault();
    setErrorsWarnings(null); // close the dialog
    history.push(`/forms/${formId}/logic/`);
  };

  return (
    <Dialog
      isOpen={Boolean(errorsWarnings)}
      onClose={() => setErrorsWarnings(null)}
      shouldShowCloseIcon={false}
      title={title}
    >
      <ol className={styles.publishErrorWarningList}>
        {validationItems.map((validationItem, index) => (
          <li key={index}>
            <ValidationItem
              item={validationItem}
              formId={formId}
              panels={panels}
              servars={servars}
              workingSteps={workingSteps}
              jumpToLogicPage={jumpToLogicPage}
            />
          </li>
        ))}
      </ol>
      <div className='dialog-form-action text-center'>
        {errors ? (
          <Positive title='Close' onClick={() => setErrorsWarnings(null)} />
        ) : (
          <>
            <Neutral title='Cancel' onClick={() => setErrorsWarnings(null)} />
            <Positive
              title='Publish Changes'
              onClick={(e: any) => {
                e.preventDefault();
                onPublish();
                setErrorsWarnings(null);
              }}
            />
          </>
        )}
      </div>
    </Dialog>
  );
};

export default memo(PublishWarningsAndErrorsModal);
