import '../../../style/dialog-form.css';
import {
  CheckboxField,
  DropdownField,
  DropdownMultiField,
  TextField
} from '../../../components/Core';
import styles from '../styles.module.scss';
import Label from '../../../components/Dialog/Label';
import { useAppSelector } from '../../../hooks';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { WarningIcon } from '../../../components/Icons';
import { produce } from 'immer';

// Event types
export const triggerEvents = {
  submit: 'Form Step is Submitted',
  load: 'Form Step is Loaded',
  change: 'Field Value is Changed',
  form_complete: 'Form is Completed',
  error: 'Form Runs into Error',
  view: 'Form Element is Viewed',
  action: 'Form Element is Clicked'
} as const;

export type TriggerEvents = keyof typeof triggerEvents;
export const stepEvents = ['submit', 'load'];
export const elementEvents = ['view', 'change', 'action'];

const titleMap: Record<string, string> = {
  change: 'Field',
  view: 'Element',
  action: 'Element'
};

export default function RuleFieldsPanel({
  rule,
  setRule,
  fieldsOnly = false,
  validation = false
}: any) {
  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const event = rule.trigger_event as string;
  const colProps = {
    md: fieldsOnly ? 12 : 6,
    sm: 12
  };

  const servars = useAppSelector((state) => state.formBuilder.servars);

  let condition = null;
  if (elementEvents.includes(event)) {
    const servarOptions = Object.values(servars)
      .map((servar: any) => ({
        value: servar.id,
        label: servar.key
      }))
      .sort((a: any, b: any) => {
        a = a.label.toLowerCase();
        b = b.label.toLowerCase();
        return a > b ? 1 : b > a ? -1 : 0;
      });
    const elementOptions: { value: string; label: string }[] = [];
    // add all subgrids first but only for action/click events because they don't work for view yet
    if (event === 'action')
      Object.values(workingSteps).forEach((step: any) => {
        elementOptions.push(
          ...step.subgrids
            .map((subgrid: any) => ({
              value: subgrid.id,
              label: subgrid.key
            }))
            .sort((a: any, b: any) => {
              a = a.label.toLowerCase();
              b = b.label.toLowerCase();
              return a > b ? 1 : b > a ? -1 : 0;
            })
        );
      });
    // add all elements already selected so that they show up
    elementOptions.push(
      ...rule.elements
        .map((elementId: string) => ({
          value: elementId,
          label: elementId
        }))
        // make sure not already in options as a container
        .filter(
          (option: { value: string }) =>
            !elementOptions.find((opt) => opt.value === option.value)
        )
    );

    condition = (
      <>
        <Label
          className={styles.fieldLabel}
          style={{ display: 'inline-block' }}
        >
          {titleMap[event]} that triggers rule
        </Label>
        <DropdownMultiField
          creatable={['view', 'action'].includes(event)}
          required
          options={event === 'change' ? servarOptions : elementOptions}
          formatCreateLabel={(inputValue: string) => `Add "${inputValue}"`}
          selected={rule.elements}
          placeholder={
            event === 'change'
              ? 'Select fields'
              : event === 'action'
              ? 'Enter element IDs or choose a container'
              : 'Enter element IDs'
          }
          onChange={(items: { value: string }[]) => {
            setRule({ ...rule, elements: items.map((item) => item.value) });
          }}
        />
        {event === 'action' && (
          <CheckboxField
            checked={rule.metadata?.after_click}
            text='Run after element click actions'
            onChange={(isChecked) => {
              const newRule = produce(rule, (draft: any) => {
                draft.metadata.after_click = isChecked;
              });
              setRule(newRule);
            }}
            style={{ marginTop: '10px' }}
          />
        )}
        {validation && (!rule.elements || rule.elements.length === 0) && (
          <div className={styles.error}>
            <WarningIcon width={24} height={24} color='#d6504a' />
            <span>
              {`At least one ${
                event === 'change' ? 'field' : 'element'
              } is required.`}
            </span>
          </div>
        )}
      </>
    );
  } else if (stepEvents.includes(event)) {
    condition = (
      <>
        <Label
          className={styles.fieldLabel}
          style={{ display: 'inline-block' }}
        >
          Form step that triggers rule
        </Label>
        <DropdownMultiField
          options={Object.values(workingSteps)
            .sort((a: any, b: any) =>
              a.key > b.key ? 1 : b.key > a.key ? -1 : 0
            )
            .map((workingStep: any) => ({
              value: workingStep.id,
              label: workingStep.key
            }))}
          selected={rule.steps}
          onChange={(items: { value: string }[]) => {
            setRule({ ...rule, steps: items.map((item) => item.value) });
          }}
        />
        {event === 'submit' && (
          <CheckboxField
            checked={rule.metadata?.after_click}
            text='Run after form data is submitted'
            onChange={(isChecked) => {
              const newRule = produce(rule, (draft: any) => {
                draft.metadata.after_click = isChecked;
              });
              setRule(newRule);
            }}
            style={{ marginTop: '10px' }}
          />
        )}
      </>
    );
  }

  return (
    <Row
      className={classNames(styles.ruleFieldPanel, {
        [styles.card]: !fieldsOnly
      })}
    >
      <Col {...colProps} className={styles.ruleFieldPanelCol}>
        <div className={styles.ruleField}>
          <Label className={styles.fieldLabel}>Event that triggers rule</Label>
          <DropdownField
            required
            selected={rule.trigger_event}
            options={Object.entries(triggerEvents).map(([value, display]) => ({
              value,
              display
            }))}
            onChange={(event: any) => {
              const triggerEvent = event.target.value;
              const newRule = { ...rule, trigger_event: triggerEvent };
              // clear out additional trigger configs contextually
              if (!stepEvents.includes(triggerEvent)) newRule.steps = [];
              if (!elementEvents.includes(triggerEvent)) newRule.elements = [];
              setRule(newRule);
            }}
          />
        </div>
        {condition && <div className={styles.ruleField}>{condition}</div>}
      </Col>
      <Col {...colProps} className={classNames(styles.ruleFieldPanelCol)}>
        <div className={styles.ruleField} style={{ height: '100%' }}>
          <Label className={styles.fieldLabel}>Description (optional)</Label>
          <TextField
            style={{ height: '100%' }}
            type='textarea'
            placeholder='Describe your rule'
            value={rule.description}
            onComplete={(description: string) =>
              setRule({ ...rule, description: description.trim() })
            }
          />
        </div>
      </Col>
    </Row>
  );
}
