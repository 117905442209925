import { LogicRule } from '../FormLogicPage/LogicPageComponent';
import { getAllFieldsInDSL } from './components/RuleBuilder/transform/utils';

export const patchDSLFieldKeys = (
  dsl: IRuleDSL,
  servarId: string,
  newKey: string
) => {
  const newDsl = JSON.parse(JSON.stringify(dsl));
  const fields = getAllFieldsInDSL(newDsl, true);

  fields.forEach((field) => {
    if (field.value === servarId) {
      field.meta.field_key = newKey;
    }
  });

  return newDsl;
};

export const isDSLUsingField = (dsl: IRuleDSL, servarId: string): boolean => {
  return getAllFieldsInDSL(dsl, true).some((field) => field.value === servarId);
};

export const validateDSLFields = (
  dsl: IRuleDSL,
  servarFields: any
): boolean => {
  const fields = getAllFieldsInDSL(dsl, true);
  const servarFieldKeys: any = Object.values(servarFields).reduce(
    (acc: any, { key }: any) => {
      acc[key] = true;
      return acc;
    },
    {}
  );

  return fields.every((field) => {
    return servarFieldKeys[field.meta?.field_key];
  });
};

export const validateRule = (rule: LogicRule, servarFields: any[]): boolean => {
  return rule.valid && validateDSLFields(rule.dsl as IRuleDSL, servarFields);
};
