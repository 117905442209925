import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Neutral, Negative } from '../../../components/Core/Button';
import { useAppSelector } from '../../../hooks';
import useFeatheryRedux from '../../../redux';

export default function UploadMetaImage() {
  const fileInput = useRef<HTMLInputElement>(null);
  const { formId } = useParams<{ formId: string }>();

  const {
    editMetaImage,
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  const org = useAppSelector((state) => state.accounts.organization) as any;
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const enabled = org && org.tier > 0;

  const handleRevert = async () => {
    try {
      await editMetaImage({ panelId: formId, image: '' });
      addInfoToast('Meta image reset to default');
    } catch (e) {
      addInfoToast('Cannot reset meta image');
    }
  };

  return (
    <>
      <Neutral
        disabled={!enabled}
        title={panel.seo_image ? 'Update Meta Image' : 'Add Meta Image'}
        onClick={() => fileInput.current && fileInput.current.click()}
      />
      {panel.seo_image && (
        <Negative disabled={!enabled} title='Clear' onClick={handleRevert} />
      )}
      <input
        ref={fileInput}
        type='file'
        onChange={async (event) => {
          if (!event.target.files || !event.target.files[0]) return;
          const image = event.target.files[0];
          if (image) {
            await editMetaImage({ panelId: formId, image });
            addInfoToast('Meta image updated');
          } else {
            addInfoToast('Please select an image');
          }
          // Clear file input
          event.target.value = '';
        }}
        accept='image/*'
        style={{
          position: 'absolute',
          height: 1,
          width: 1,
          bottom: 0,
          opacity: 0
        }}
      />
    </>
  );
}
