import { memo, useState } from 'react';
import Dialog from '../Dialog';
import useFeatheryRedux from '../../redux';
import { Neutral, Positive } from '../Core/Button';
import { FolderIcon } from '../Icons';
import { TextField } from '../Core';
import Label from '../Dialog/Label';
import dialogStyles from '../Dialog/styles.module.scss';

function CreateFolderModal({ show, setShow, name }: any) {
  const {
    folders: { addFolder },
    toasts: { addToast }
  } = useFeatheryRedux();
  const [folder, setFolder] = useState(null);

  function reset() {
    setFolder(null);
    setShow(false);
  }

  function submit() {
    if (!folder) return;
    addFolder({ folder, name });
    addToast({
      icon: <FolderIcon />,
      text: (
        <>
          <strong>{folder}</strong> created.
        </>
      )
    });
    reset();
  }

  return (
    <Dialog
      isOpen={show}
      onClose={reset}
      shouldShowCloseIcon={false}
      title={`Create new folder`}
      alignCenter
      style={{ padding: '24px 40px' }}
    >
      <div style={{ textAlign: 'left', paddingBlock: 16, display: 'block' }}>
        <label style={{ display: 'block' }}>
          <Label>Folder Name</Label>
          <TextField
            autoFocus
            value={folder ?? ''}
            onChange={setFolder}
            placeholder='New folder'
            className={dialogStyles.input}
          />
        </label>
        {!folder && folder !== null && (
          <p style={{ color: 'var(--red-border)', paddingTop: 3, margin: 0 }}>
            Folder is required.
          </p>
        )}
      </div>
      <div className='dialog-form-action'>
        <Neutral title='Cancel' onClick={reset} />
        <Positive
          title='Create Folder'
          disabled={!folder}
          onClick={(event: MouseEvent) => {
            event.preventDefault();
            submit();
          }}
        />
      </div>
    </Dialog>
  );
}

export default memo(CreateFolderModal);
