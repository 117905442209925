import { memo, useMemo } from 'react';

import Table from '../../../Table';
import { fieldTypeNameMap } from '../../../../utils/elements';

import useDraftForm from '../../../../utils/useDraftForm';
import { deepEquals } from '../../../../utils/core';
import { useAppSelector } from '../../../../hooks';

export interface SelectedFieldData {
  selectId: string;
  selectType: '' | 'servar' | 'hidden';
  selectServarType?: string;
}

const getColumns = (includeStep: boolean) => {
  const columns = [
    { key: 'key', name: 'ID' },
    { key: 'type', name: 'Type' }
  ];
  if (includeStep) columns.push({ key: 'usedIn', name: 'Steps' });
  return columns;
};

const ServarSelectorTab = ({
  selectData,
  setSelectData,
  searchFilter,
  currentStepId = '',
  // If true, only leverage field state from the current form in production
  inFormBuilder = false,
  excludeServarTypes = [],
  includeServarTypes = null,
  currentForm = true,
  fieldsInUse
}: any) => {
  const servars = useAppSelector(
    (state) =>
      inFormBuilder
        ? Object.values(state.formBuilder.servars)
        : state.fields.servars ?? [],
    deepEquals
  );

  const servarSelectId = useMemo(() => {
    return selectData.selectType === 'servar' ? selectData.selectId : '';
  }, [selectData]);

  const formBuilderSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );
  let formSteps = useDraftForm(!inFormBuilder).steps;
  formSteps = inFormBuilder ? formBuilderSteps : formSteps;

  const renderServarData = useMemo(() => {
    const usedInMap: Record<string, string[]> = {};
    const currentStepServars = new Set();
    const currentFormServars = new Set();
    Object.values(formSteps).forEach((step) =>
      (step as any).servar_fields.forEach((field: any) => {
        const servarId = field.servar.id;
        if (servarId in usedInMap) usedInMap[servarId].push((step as any).id);
        else usedInMap[servarId] = [(step as any).id];
        if ((step as any).id === currentStepId)
          currentStepServars.add(servarId);
        currentFormServars.add(servarId);
      })
    );
    return servars
      .filter(
        (s: any) =>
          !inFormBuilder || currentForm === currentFormServars.has(s.id)
      )
      .filter(
        (s: any) =>
          !excludeServarTypes.includes(s.type) &&
          (!includeServarTypes || includeServarTypes.includes(s.type))
      )
      .filter(
        (s: any) =>
          !searchFilter ||
          s.key.toLowerCase().includes(searchFilter.toLowerCase())
      )
      .map((s: any) => {
        // Generate the list of steps each field is in
        let usedInText = '';
        let usedSteps = [];
        if (s.id in usedInMap) {
          // Sort the steps a field is in by
          // 1. Is it the current step
          // 2. Alphabetically
          usedSteps = usedInMap[s.id]
            .sort((x: any, y: any) =>
              x === currentStepId
                ? -1
                : y === currentStepId
                ? 1
                : formSteps[x].key < formSteps[y].key
                ? -1
                : 1
            )
            .map((stepId: any) => formSteps[stepId].key);
          usedInText = usedSteps[0];
          if (usedSteps.length > 1) usedInText += ` + ${usedSteps.length - 1}`;
        }

        return {
          ...s,
          type: fieldTypeNameMap[s.type],
          usedIn: usedInText,
          usedSteps
        };
      })
      .sort((x: any, y: any) => {
        // Sort fields by
        // 1. Is it in the current step
        // 2. Alphabetically by the first step it's in
        if (currentStepServars.has(x.id)) return -1;
        if (currentStepServars.has(y.id)) return 1;
        if (fieldsInUse.has(x.id)) return -1;
        if (fieldsInUse.has(y.id)) return 1;
        const xInForm = currentFormServars.has(x.id);
        const yInForm = currentFormServars.has(y.id);
        if (xInForm && yInForm) return x.usedSteps[0] < y.usedSteps[0] ? -1 : 1;
        if (xInForm) return -1;
        if (yInForm) return 1;
        return 0;
      });
  }, [formSteps, servars, currentStepId, fieldsInUse, searchFilter]);

  return (
    <Table
      name='Form Field'
      data={renderServarData}
      columns={getColumns(currentForm && inFormBuilder)}
      showSelected
      onSelect={(servar: any) => {
        const newData: SelectedFieldData =
          servar.id === selectData.selectId
            ? {
                selectId: '',
                selectType: ''
              }
            : {
                selectId: servar.id,
                selectServarType: servar.type,
                selectType: 'servar'
              };
        setSelectData(newData);
      }}
      initSelectId={servarSelectId}
      type='modal'
    />
  );
};

export default memo(ServarSelectorTab);
