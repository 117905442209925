import '../../style/dialog-form.css';

import { TextField } from '../Core';
import { memo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Neutral, Positive } from '../Core/Button';
import { UNDO_TITLES, UNDO_TYPES } from '../../utils/constants';
import Label from '../Dialog/Label';
import Dialog from '../Dialog';
import useDialogFocus from '../../utils/useDialogFocus';
import useFeatheryRedux from '../../redux';
import { useAppSelector } from '../../hooks';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.scss';
import ruleFieldsStyles from '../../pages/FormLogicRulePage/styles.module.scss';
import classNames from 'classnames';
import RuleFieldsPanel from '../../pages/FormLogicRulePage/components/RuleFieldsPanel';
import { LogicRule } from '../../pages/FormLogicPage/LogicPageComponent';

function RuleCreateModal({
  show,
  setShow
}: {
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();

  const {
    formBuilder: { setPanelDataWithUndoRedo }
  } = useFeatheryRedux();
  const workingSteps = useAppSelector((s) => s.formBuilder.workingSteps);
  const workingLogicRules = useAppSelector(
    (s) => s.formBuilder.workingLogicRules
  );
  const focusElement = useDialogFocus(show);

  const initData = (nextNum: number): LogicRule => ({
    id: uuidv4(),
    name: `Rule ${nextNum}`,
    description: '',
    index: 1,
    trigger_event: 'submit',
    steps: [],
    elements: [], // Array of element ids or servar keys or empty. Used for onView event only.
    dsl: {
      branches: []
    },
    code: '// Javascript rule code',
    enabled: true,
    valid: true,
    mode: 'rule_builder',
    metadata: {},
    updated_at: new Date().toISOString(),
    created_at: new Date().toISOString()
  });

  const [data, setData] = useState(
    initData(Object.keys(workingLogicRules).length + 1)
  );
  const [error, setError] = useState('');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const { name } = data;
    if (
      Object.values(workingLogicRules).find((rule: any) => rule.name === name)
    ) {
      setError('You already have a rule with that name');
      return;
    }
    setError('');

    // The index is used for determining the run order for the rules that run off the same event
    const newIndex =
      Object.values(workingLogicRules)
        .filter((rule: any) => rule.trigger_event === data.trigger_event)
        .reduce((acc: number, rule: any) => Math.max(acc, rule.index), 0) + 1;
    const newRule = { ...data, index: newIndex };

    setPanelDataWithUndoRedo({
      id: newRule.id,
      oldValue: {},
      newValue: {},
      workingSteps: { ...workingSteps },
      oldRulesValue: {},
      newRulesValue: { [newRule.id]: newRule },
      workingLogicRules: { ...workingLogicRules, [newRule.id]: newRule },
      title: UNDO_TITLES.NEW_LOGIC_RULE,
      type: UNDO_TYPES.LOGIC_RULES
    });
    setData(initData(Object.keys(workingLogicRules).length + 2));
    setShow(false);
    // send them to the rule edit screen
    history.push(`/forms/${formId}/logic/${data.id}?edit`);
  };

  return (
    <Dialog isOpen={show} onClose={() => setShow(false)} title='New Logic Rule'>
      <form onSubmit={handleSubmit}>
        <div className={ruleFieldsStyles.ruleField}>
          <Label className={ruleFieldsStyles.fieldLabel}>Name</Label>
          <TextField
            required
            ref={focusElement}
            placeholder='Enter rule name'
            value={data.name}
            onComplete={(name: string) =>
              setData({ ...data, name: name.trim() })
            }
          />
        </div>
        <RuleFieldsPanel rule={data} setRule={setData} fieldsOnly />

        <div
          className={classNames(
            'dialog-form-action',
            'text-center',
            styles.ruleCreateActions
          )}
        >
          <Neutral title='Cancel' onClick={() => setShow(false)} />
          <Positive title='Create' type='submit' />
        </div>
      </form>
      {error && <div>{error}</div>}
    </Dialog>
  );
}

export default memo(RuleCreateModal);
