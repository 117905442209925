import '../../style/dialog-form.css';

import { memo } from 'react';
import { Positive } from '../Core/Button';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Dialog from '../Dialog';
import { formatDate } from '../../utils/format';

function CollaborationTaskDetailsModal({
  show,
  close,
  collaborator
}: {
  show: boolean;
  close: () => void;
  collaborator: any;
}) {
  let collabLabel = 'Invitees:';
  let collabEmail = collaborator.invitees.join(', ');
  if (collaborator.status === 'Completed') {
    collabLabel = 'By:';
    collabEmail = collaborator.completed_by;
  } else if (collaborator.status === 'Pending') {
    collabLabel = 'Pending For:';
  }

  const CollaborationTaskDetails = ({ label, value }: any) => (
    <div className={styles.collaboratorDetail}>
      <div className={styles.dataLabel}>{label}</div>
      <div className={styles.dataValue}>{value}</div>
    </div>
  );

  const primaryData = [
    ['Role:', collaborator.template_label],
    ['Status:', collaborator.status],
    [collabLabel, collabEmail],
    [
      'Invited:',
      collaborator.invited_at
        ? formatDate(collaborator.invited_at)
        : 'Not yet invited'
    ],
    ['Completed:', formatDate(collaborator.completed_at)]
  ];
  const secondaryData = [
    ['First View:', formatDate(collaborator.first_viewed)],
    ['First Submit:', formatDate(collaborator.first_submitted)],
    ['Last View:', formatDate(collaborator.last_viewed)],
    ['Last Submit:', formatDate(collaborator.last_submitted)],
    ['IP Address:', collaborator.ip_address || 'Not available']
  ];

  return (
    <Dialog isOpen={show} onClose={close} title='Collaboration Task Details'>
      <div className={styles.collaborationTaskDetails}>
        {primaryData.map((data, index) => (
          <CollaborationTaskDetails
            key={index}
            label={data[0]}
            value={data[1]}
          />
        ))}
      </div>
      <div className={styles.collaborationTaskDetails}>
        {secondaryData.map((data, index) => (
          <CollaborationTaskDetails
            key={index}
            label={data[0]}
            value={data[1]}
          />
        ))}
      </div>

      <div
        className={classNames(
          'dialog-form-action',
          'text-center',
          styles.ruleCreateActions
        )}
      >
        <Positive title='OK' onClick={close} />
      </div>
    </Dialog>
  );
}

export default memo(CollaborationTaskDetailsModal);
