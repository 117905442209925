import {
  encodeGetParams,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  URL
} from './utils';

export default {
  getAIExtractions: (token: string) => {
    return httpGet({ token, url: `${URL.AI}extraction/` });
  },
  deleteAIExtraction: (token: string, { extractionId }: any) => {
    return httpDelete({
      token,
      baseUrl: `${URL.AI}extraction/`,
      path: `${extractionId}/`
    });
  },
  createAIExtraction: (token: string, data: any) => {
    return httpPost({
      token,
      url: `${URL.AI}extraction/`,
      body: data,
      invalid: [URL.SERVARS]
    });
  },
  editAIExtraction: (token: string, data: any) => {
    const { extractionId, ...extractionData } = data;
    return httpPatch({
      token,
      baseUrl: `${URL.AI}extraction/`,
      path: `${extractionId}/`,
      body: extractionData,
      invalid: [URL.SERVARS]
    });
  },
  getAIExtractionRuns: (token: string, { extractionId, ...query }: any) => {
    return httpGet({
      token,
      url: `${URL.AI}extraction/${extractionId}/run/?${encodeGetParams(query)}`,
      cachable: false
    });
  },
  editAIExtractionRun: (token: string, runId: string, data: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.AI}extraction/`,
      path: `run/${runId}/`,
      body: data
    });
  },
  deleteAIExtractionRun: (token: string, runId: string) => {
    return httpDelete({
      token,
      baseUrl: `${URL.AI}extraction/run/`,
      path: `${runId}/`
    });
  },
  updateAIExtractionEntry: (token: string, runId: string, data: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.AI}extraction/`,
      path: `run/${runId}/entry/`,
      body: data
    });
  },
  commitAIExtractionEntries: (token: string, runId: string, data: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.AI}extraction/`,
      path: `run/${runId}/commit/`,
      body: data
    });
  },
  analyzeQuestion: (token: string, question: string) => {
    return httpPost({
      token,
      url: `${URL.AI}analyze_question/`,
      body: { question }
    });
  }
};
