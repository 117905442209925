/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useFeatheryRedux from '../redux';

import Switch from '../components/Core/Switch';
import HeaderFilterResultsTable from '../components/HeaderFilterResultsTable';
import {
  DeleteFormModal,
  FormActivationModal,
  PanelCopyModal,
  PanelCreateModal
} from '../components/Modals';

import { formatDate, formatNumeric } from '../utils/format';

import { FormBlankStateIcon } from '../components/Icons';

import { chosenPlan, UsageEnforcement } from '../components/PricingPlans';

import styles from './forms.module.scss';
import pageStyles from './styles.module.scss';
import classNames from 'classnames';
import { useHostedURL } from '../components/Core/Button/Share/ShareFormOverlay';
import testIds from '../utils/testIds';
import { useAppSelector } from '../hooks';
import { TagsContainer } from '../components/TagsContainer';
import MoveFormModal from '../components/Modals/MoveFormModal';

function getFormColumns(hasTag: any) {
  return [
    { key: 'key', name: 'Name' },
    { key: 'theme_name', name: 'Theme' },
    {
      key: 'updated_at_pretty',
      name: 'Last Edited',
      sortBy: 'updated_at'
    },
    ...(hasTag
      ? [{ key: 'tags', name: 'Tags', noSort: true, filterKey: 'tag_metadata' }]
      : []),
    {
      key: 'active_switch',
      name: 'Status',
      sortBy: 'active',
      headerCellStyle: { width: '120px' }
    }
  ];
}

export default function FormsPage() {
  const history = useHistory();

  const account = useAppSelector((state) => state.accounts.account);
  const org = useAppSelector((state) => state.accounts.organization);
  const panelsData = useAppSelector((state) => state.panels.panels);
  const themes = useAppSelector((state) => state.themes).themes;
  const [createFolder, setCreateFolder] = useState('');
  const {
    editPanel,
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  const [formToDelete, setFormToDelete] = useState(null);
  const [formToMove, setFormToMove] = useState<any>(null);
  const [createStep, setCreateStep] = useState(org?.new ? 1 : 0);
  const [copyPanel, setCopyPanel] = useState(null);
  const [sharePanel, setSharePanel] = useState(null);

  const [{ formId: formIdToActivate, activate }, setFormActivation] = useState({
    formId: '',
    activate: false
  });
  const [showFormActivationModal, setShowFormActivationModal] = useState(false);
  const [activateErrorMessage, setActivateErrorMessage] = useState('');
  const [redirectToBillingSettings, setRedirectToBillingSettings] =
    useState(false);

  const currentPlan = useMemo(() => chosenPlan(org), [org?.tier]);

  const changeFormStatus = async (panelId: any, active: any) => {
    try {
      await editPanel({ panelId: panelId, active });
      addInfoToast(`Your form has been turned ${active ? 'on' : 'off'}.`);
    } catch (error: any) {
      if (['active-form-limit', 'submission-exceeded'].includes(error.code)) {
        // Put up dialog with warning.  The Dialog will have a CTA that optionally redirects to the settings page to upgrade.
        setFormActivation({ formId: panelId, activate: active });
        setActivateErrorMessage(
          error.code === 'active-form-limit'
            ? "You have reached your plan's active form limit. Please upgrade and try again."
            : `You have reached your plan's monthly limit of ${formatNumeric(
                currentPlan.monthlySubmissions
              )} submissions so your forms have been turned off. Please upgrade and try again.`
        );
        setShowFormActivationModal(true);
      } else addInfoToast(`Error: ${error.message}.`);
    }
  };

  function FormOnOffSwitch({ active, panelId: formId }: any) {
    return (
      <div
        className={styles.formOnOff}
        onClick={(e) => {
          // This is needed to allow the switch to get the click inside the forms table which has onClick
          e.stopPropagation();
        }}
      >
        <Switch
          id={'sw_onoff_' + formId}
          checked={active}
          className={styles.formOnOffSwitch}
          onChange={(event: any) => {
            const newActive = event.target.checked;
            if (!newActive) {
              // put up confirm dialog
              setFormActivation({ formId, activate: newActive });
              setShowFormActivationModal(true);
            } else {
              changeFormStatus(formId, newActive);
            }
          }}
          disabled={account.role === 'viewer'}
        />
        {active ? 'ON' : 'OFF'}
      </div>
    );
  }

  const getHostedUrl = useHostedURL(org, sharePanel);
  useEffect(() => {
    if (!sharePanel) return;
    const url = getHostedUrl();
    navigator.clipboard.writeText(url).then(() => {
      addInfoToast('Form link copied');
    });
  }, [getHostedUrl, sharePanel]);

  const [dataToRender, hasTag] = useMemo(() => {
    const data = Object.values(panelsData);
    const toRender = data.map((form: any) => {
      const tags = <TagsContainer tags={form.tag_metadata} />;

      const render = {
        ...form,
        tags,
        value: String(form.value),
        active_switch: (
          <FormOnOffSwitch active={form.active} panelId={form.id} />
        ),
        updated_at_pretty: formatDate(form.updated_at)
      };
      if ('theme' in form)
        render.theme_name = themes[form.theme]?.key ?? 'No theme';
      return render;
    });
    const hasTag = data.some((form: any) => form.tag_metadata?.length);
    return [toRender, hasTag];
  }, [panelsData, themes]);

  let onCreate;
  if (account.role !== 'viewer')
    onCreate = (folder: any) => {
      setCreateFolder(folder);
      setCreateStep(1);
    };

  return redirectToBillingSettings ? (
    <Redirect to='/settings/billing' />
  ) : (
    <>
      <div className={pageStyles.pageHeadRow}>
        <h1 className={classNames('page-head', pageStyles.pageHeadTitle)}>
          Forms
        </h1>
        <UsageEnforcement />
      </div>
      {dataToRender.length === 0 && account.role !== 'viewer' && (
        <div className={styles.blankStateContainer}>
          <FormBlankStateIcon onClick={() => setCreateStep(1)} />
          <span className={styles.blankStateHeader}>Create a new form</span>
          <span className={styles.blankStateBody}>
            Use our easy-to-edit templates to hit the ground running.
          </span>
          <button
            data-testid={testIds.createFormButton}
            className={classNames('btn btn-custom', styles.blankStateButton)}
            onClick={() => setCreateStep(1)}
          >
            Create Form
          </button>
        </div>
      )}
      <HeaderFilterResultsTable
        data={dataToRender}
        columns={getFormColumns(hasTag)}
        defaultSort={{ order: -1, key: 'updated_at' }}
        name='Form'
        useSearch
        hasFolders
        hasOverflowMenu
        onCreate={onCreate}
        onSelect={(panel: any) => {
          let nextUrl = `/forms/${panel.id}`;
          if (account.role === 'viewer') nextUrl += '/results';
          history.push(nextUrl);
        }}
        onCopy={(panel: any) => setCopyPanel(panel)}
        onDelete={(panel: any) => setFormToDelete(panel)}
        onShare={(panel: any) => setSharePanel(panel)}
        onMoveToFolder={(panel: any) => setFormToMove(panel)}
      />
      <DeleteFormModal
        setShow={() => setFormToDelete(null)}
        show={Boolean(formToDelete)}
        formId={(formToDelete as any)?.id}
        name={(formToDelete as any)?.key}
      />
      <MoveFormModal
        key={formToMove?.id}
        show={Boolean(formToMove)}
        setShow={() => setFormToMove(null)}
        formId={formToMove?.id}
        name={formToMove?.key}
      />
      <PanelCopyModal
        panel={copyPanel}
        close={() => setCopyPanel(null)}
        panelsData={panelsData}
      />
      <PanelCreateModal
        step={createStep}
        changeStep={setCreateStep}
        newOrg={org?.new}
        folder={createFolder}
      />
      <FormActivationModal
        activate={activate}
        activateErrorMessage={activateErrorMessage}
        formId={formIdToActivate}
        changeFormStatus={changeFormStatus}
        show={showFormActivationModal}
        setShow={setShowFormActivationModal}
        setShowChangePlan={setRedirectToBillingSettings}
      />
    </>
  );
}
