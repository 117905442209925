import Dialog from '../../Dialog';
import { useState } from 'react';
import classNames from 'classnames';
import styles from '../styles.module.scss';
import { Neutral, Positive } from '../../Core/Button';

export default function PDFActionRenderer({ ActionInputFields, index }: any) {
  const [show, setShow] = useState(false);
  const saveButton = document.getElementsByClassName('rollout-button--primary')[
    index
  ] as any;

  return (
    <>
      <button
        style={{ width: '160px' }}
        className={classNames('btn-custom', styles.connectButton)}
        onClick={() => setShow(true)}
      >
        Configure PDF
      </button>
      <Dialog
        isOpen={show}
        onClose={() => setShow(false)}
        shouldShowCloseIcon
        title='PDF Generator'
        size='lg'
      >
        {ActionInputFields && <ActionInputFields />}
        <div className='dialog-form-action text-center'>
          <Neutral title='Cancel' onClick={() => setShow(false)} />
          <Positive title='Save' onClick={() => saveButton.click()} />
        </div>
      </Dialog>
    </>
  );
}
