import { PropsWithChildren } from 'react';
import { useExtractionUpdater, usePDFState, usePDFUpdater } from '../context';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = PropsWithChildren<any>;

const MAX_ZOOM = 1.6;
const MIN_ZOOM = 0.5;

const Zoomable = ({ children }: Props) => {
  const { zoom } = usePDFState();
  const { setZoom } = usePDFUpdater();
  const { setSelectedValueId } = useExtractionUpdater();

  const handleResetZoom = () => {
    if (zoom !== 1) {
      setZoom(1);
    }
  };

  const handleUpdateZoom = (delta: number) => {
    if (zoom + delta <= MAX_ZOOM && zoom + delta >= MIN_ZOOM) {
      setZoom(zoom + delta);
    }
  };

  return (
    <div
      className={styles.zoomable}
      onClick={(e) => {
        // @ts-ignore
        if (['zoomable-content', 'data-layer-page'].includes(e.target.id)) {
          setSelectedValueId(null);
        }
      }}
    >
      <div id='zoomable-content' className={styles.zoomableContent}>
        {children}
      </div>
      <div className={styles.zoomableControls}>
        <div
          onClick={handleResetZoom}
          className={classNames(styles.zoomableDisplay, {
            [styles.highlight]: zoom !== 1,
            [styles.clickable]: zoom !== 1
          })}
        >
          <span>{Math.round(zoom * 100)}%</span>
        </div>
        <div className={styles.zoomableButtons}>
          <button onClick={() => handleUpdateZoom(0.1)}>+</button>
          <button onClick={() => handleUpdateZoom(-0.1)}>-</button>
        </div>
      </div>
    </div>
  );
};

export default Zoomable;
