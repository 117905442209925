import '../../style/dialog-form.css';

import { Form } from 'react-bootstrap';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { Fragment, useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import { DropdownField, TextField } from '../Core';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Label from '../Dialog/Label';
import useIntegrations from './useIntegrations';
import { PlusIcon, TrashIcon } from '../Icons';
import { Link, useParams } from 'react-router-dom';

const DEFAULT_HEADERS = [{ name: '', value: '' }];

function WebhookSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.WEBHOOK,
    panelId: formId,
    includeInactive: true
  });

  // Local/draft integration settings
  const metadata = integration?.data.metadata ?? {};
  const [url, setUrl] = useState(metadata.webhook ?? '');
  const metaHeaders = metadata.headers ?? [];
  const [headers, setHeaders] = useState(
    metaHeaders.length === 0 ? DEFAULT_HEADERS : metaHeaders
  );
  const [trigger, setTrigger] = useState(metadata.trigger ?? 'form_completion');
  const [isPartial, setIsPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial = !url;
      setIsPartial(partial);
      if (partial) return;
    }

    const metadata = { webhook: url, trigger, headers };

    return {
      isUpdate: integration?.data,
      metadata
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.WEBHOOK]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <Form>
        <Form.Text className='text-muted'>
          Form data will be sent to the webhook URL when the specified event
          occurs. The format of the data is &#123;&#60;FIELD ID&gt;: &lt;FIELD
          VALUE&gt;&#125;
        </Form.Text>
        <br />
        <Form.Text className='text-muted'>
          For fully customizable payloads and triggers, use{' '}
          <Link to={`/forms/${formId}/logic/connectors`}>API connectors</Link>
        </Form.Text>
        <Form.Group>
          <Label>Webhook URL</Label>
          <TextField
            className={classNames('dialog-form-input', styles.marginBottom)}
            placeholder='https://webhook.com'
            value={url || ''}
            onChange={setUrl}
            error={isPartial && !url}
          />
        </Form.Group>
        <Form.Group>
          <Label>Headers</Label>
          <div
            className={classNames(styles.twoColumnContainer, styles.rightWide)}
          >
            {headers.map(({ name, value }: any, index: number) => (
              <Fragment key={index}>
                <TextField
                  placeholder='Name'
                  className='dialog-form-input'
                  value={name}
                  onComplete={(newName: any) => {
                    const newHeaders = [...headers];
                    newHeaders[index] = { name: newName, value };
                    setHeaders(newHeaders);
                  }}
                  error={isPartial && !name}
                />
                <div className={styles.fieldSelectorContainer}>
                  <TextField
                    placeholder='Value'
                    className='dialog-form-input'
                    value={value}
                    onComplete={(newValue: any) => {
                      const newHeaders = [...headers];
                      newHeaders[index] = { name, value: newValue };
                      setHeaders(newHeaders);
                    }}
                  />
                  <TrashIcon
                    height={16}
                    width={16}
                    className={classNames(
                      'tr-icon',
                      styles.customPropertyDelete
                    )}
                    style={{ marginLeft: '8px' }}
                    onClick={() => {
                      const newHeaders = [...headers];
                      newHeaders.splice(index, 1);
                      setHeaders(newHeaders);
                    }}
                  />
                </div>
              </Fragment>
            ))}
          </div>
          <PlusIcon
            className={styles.customPropertyAdd}
            style={{ marginBottom: 0 }}
            onClick={() => {
              const newHeaders = [...headers];
              newHeaders.push({ name: '', value: '' });
              setHeaders(newHeaders);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Label>Send data when</Label>
          <DropdownField
            selected={trigger}
            onChange={(event: any) => setTrigger(event.target.value)}
            options={[
              { value: 'form_completion', display: 'the form is completed' },
              {
                value: 'data_received',
                display: 'any user data is received (e.g. step submission)'
              }
            ]}
          />
        </Form.Group>
      </Form>
    </IntegrationsSidebar>
  );
}

export default WebhookSettingsSidebar;
