import styles from '../styles.module.scss';
import { useRef } from 'react';
import { Neutral } from '../../../components/Core/Button';
import { useAppSelector } from '../../../hooks';
import useFeatheryRedux from '../../../redux';

export default function FaviconUpload() {
  const fileInput = useRef<HTMLInputElement>(null);

  const { editFavicon } = useFeatheryRedux();

  const org = useAppSelector((state) => state.accounts.organization) as any;
  const enabled = org && org.tier > 0;

  return (
    <>
      <label className={styles.settingsLabel}>
        Custom Favicon (16 x 16px recommended)
      </label>
      <div className={styles.teamFieldSection}>
        <Neutral
          disabled={!enabled}
          title={org.favicon || 'Upload Favicon'}
          onClick={() => fileInput.current && fileInput.current.click()}
        />
        {/* Input component must be hidden, and it also remains empty since we track files in state here */}
        {/* Since the input is always empty, we have to check for existing data and ignore the required attribute */}
        <input
          ref={fileInput}
          type='file'
          onChange={(event) => {
            if (!event.target.files || !event.target.files[0]) return;
            const image = event.target.files[0];
            editFavicon({ favicon: image });
            // Clear file input
            event.target.value = '';
          }}
          accept='image/*'
          style={{
            position: 'absolute',
            height: 1,
            width: 1,
            bottom: 0,
            opacity: 0
          }}
        />
      </div>
    </>
  );
}
