import { Fragment, memo, useState } from 'react';
import useFeatheryRedux from '../../../redux';
import Dialog from '../../../components/Dialog';
import { Positive } from '../../../components/Core/Button';
import { UsersIcon } from '../../../components/Icons';
import { CollapsibleSection } from '../../../components/Core';
import modalStyles from '../../../components/Modals/styles.module.scss';
import PermissionsConfig from '../components/PermissionsConfig';
import { isSSO } from '../../../utils/useGetJwt';
import { HorizontalFormAttribute } from '../../../components/Core/HorizontalFormAttribute';
import { Spacer } from '../../../components/Spacer';
import { useAppSelector } from '../../../hooks';
import BulkInviteUploader from '../components/BulkInviteUploader';

type EmailInvite = {
  email: string;
  firstName?: string;
  lastName?: string;
  customAttributes: Record<string, string>;
};

type BulkInvite = {
  email: string;
  role: string;
  firstName?: string;
  lastName?: string;
  user_groups_ids?: string[];
  customAttributes: Record<string, string>;
};

const createEmptyInvites = (size = 5): EmailInvite[] =>
  Array(size).fill({
    email: '',
    firstName: '',
    lastName: '',
    customAttributes: {}
  });

function InviteTeamModal({
  step,
  setStep
}: {
  step: number;
  setStep: (step: number) => void;
}) {
  const {
    inviteUsers,
    toasts: { addToast, addErrorToast }
  } = useFeatheryRedux();

  const [invites, setInvites] = useState<EmailInvite[]>(createEmptyInvites());
  const [bulkFile, setBulkFile] = useState<File | null>(null);
  const [bulkInvites, setBulkInvites] = useState<BulkInvite[] | null>(null);
  const [error, setError] = useState<string>('');

  const customAttributes: { id: string; label: string }[] = useAppSelector(
    (state) => state.accounts.organization?.custom_attributes ?? []
  );

  const closeModal = () => {
    setInvites(createEmptyInvites());
    setBulkFile(null);
    setBulkInvites(null);
    setStep(0);
    setError('');
  };

  const inviteLabel = isSSO ? 'Add Users' : 'Invite Team';

  const handleSendInvites = (invitesToSend: any[]) => {
    setError('');
    setBulkInvites(null);
    setBulkFile(null);
    inviteUsers({ invites: invitesToSend })
      .then(() => {
        if (!isSSO) {
          addToast({
            icon: <UsersIcon />,
            text: `${invitesToSend.length} ${
              invitesToSend.length > 1 ? 'invites' : 'invite'
            } sent`
          });
        }
        closeModal();
      })
      .catch((e: any) => {
        addErrorToast({ text: e.message });
      });
  };

  const handleCSVInvites = () => {
    if (!bulkInvites || bulkInvites.length === 0) {
      setError('No invites to send. Please upload a valid CSV file.');
      return;
    }

    const completeInvites = bulkInvites.map((invite) => ({
      email: invite.email,
      first_name: invite.firstName,
      last_name: invite.lastName,
      role: invite.role.toLowerCase(),
      custom_attributes: invite.customAttributes,
      user_groups: invite.user_groups_ids
    }));
    handleSendInvites(completeInvites);
    closeModal();
  };

  return (
    <Dialog
      isOpen={step > 0}
      title={step === 1 ? inviteLabel : 'Edit Permissions'}
      size='sm'
      onClose={closeModal}
    >
      {step === 1 && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (bulkInvites) {
              handleCSVInvites();
            } else if (invites.some((invite) => invite.email)) {
              setStep(2);
            } else {
              setError('Please add at least one invite or upload a CSV file');
            }
          }}
        >
          <div className={modalStyles.invitesContainer}>
            <Spacer />
            {!bulkFile &&
              invites.map((invite, index) => (
                <div key={index} style={{ width: '100%' }}>
                  <div className={modalStyles.inviteUserLabel}>
                    User {index + 1}
                  </div>
                  <HorizontalFormAttribute
                    labelProps={{
                      value: 'Email',
                      disabled: true
                    }}
                    inputProps={{
                      type: 'email',
                      placeholder: 'user@company.com',
                      value: invite.email,
                      onChange: (val: any) => {
                        const newInvites = [...invites];
                        newInvites[index] = { ...invites[index], email: val };
                        setInvites(newInvites);
                      }
                    }}
                  />
                  <Spacer />
                  <CollapsibleSection
                    iconSize={12}
                    iconOnLeft
                    isReversed
                    title='Optional Attributes'
                    collapsible
                    expanded={false}
                    separator={false}
                    resetHeaderStyle={true}
                    customClasses={{
                      title: modalStyles.inviteOptionalAttributesTitle,
                      header: modalStyles.inviteOptionalAttributesHeader,
                      expandIndicator:
                        modalStyles.inviteOptionalAttributesExpandIndicator
                    }}
                  >
                    <HorizontalFormAttribute
                      labelProps={{
                        value: 'First Name',
                        disabled: true
                      }}
                      inputProps={{
                        placeholder: 'Jane',
                        value: invite.firstName,
                        onChange: (val: any) => {
                          const newInvites = [...invites];
                          newInvites[index] = {
                            ...invites[index],
                            firstName: val
                          };
                          setInvites(newInvites);
                        }
                      }}
                    />
                    <Spacer />
                    <HorizontalFormAttribute
                      labelProps={{
                        value: 'Last Name',
                        disabled: true
                      }}
                      inputProps={{
                        placeholder: 'Doe',
                        value: invite.lastName,
                        onChange: (val: any) => {
                          const newInvites = [...invites];
                          newInvites[index] = {
                            ...invites[index],
                            lastName: val
                          };
                          setInvites(newInvites);
                        }
                      }}
                    />
                    {customAttributes.map((attr) => (
                      <Fragment key={attr.id}>
                        <Spacer />
                        <HorizontalFormAttribute
                          labelProps={{
                            value: attr.label,
                            disabled: true
                          }}
                          inputProps={{
                            placeholder: 'Value',
                            value: invite.customAttributes[attr.id] ?? '',
                            onChange: (val: any) => {
                              const newInvites = [...invites];
                              newInvites[index] = {
                                ...invites[index],
                                customAttributes: {
                                  ...invites[index].customAttributes,
                                  [attr.id]: val
                                }
                              };
                              setInvites(newInvites);
                            }
                          }}
                        />
                      </Fragment>
                    ))}
                  </CollapsibleSection>
                </div>
              ))}

            {bulkFile && bulkInvites && (
              <div>Number of form submissions: {bulkInvites.length}</div>
            )}

            <BulkInviteUploader
              onBulkInvitesUpdate={setBulkInvites}
              onErrorUpdate={setError}
              onFileUpdate={setBulkFile}
            />
            {error && (
              <div className={modalStyles.inviteOptionalAttributesTitle}>
                {error}
              </div>
            )}
          </div>
          <div className='dialog-form-action text-center'>
            <Positive
              title={bulkInvites ? 'Send Invites' : 'Next'}
              type='submit'
              disabled={error}
            />
          </div>
        </form>
      )}
      {step === 2 && (
        <PermissionsConfig
          cta={isSSO ? 'Enable' : 'Send Invites'}
          onBack={() => setStep(1)}
          onSubmit={(data: any) => {
            const seenEmails = new Set<string>();
            const completeInvites = invites
              .filter((invite) => {
                return (
                  invite.email &&
                  !seenEmails.has(invite.email) &&
                  seenEmails.add(invite.email)
                );
              })
              .map((invite) => ({
                email: invite.email,
                first_name: invite.firstName,
                last_name: invite.lastName,
                custom_attributes: invite.customAttributes
              }));
            const numInvites = completeInvites.length;
            if (numInvites > 0) {
              const term = numInvites > 1 ? 'invites' : 'invite';
              const invitePayloads = completeInvites.map((invite) => ({
                ...invite,
                ...data
              }));
              return inviteUsers({ invites: invitePayloads })
                .then(() => {
                  if (!isSSO) {
                    addToast({
                      icon: <UsersIcon />,
                      text: `${numInvites} ${term} sent`
                    });
                  }
                  closeModal();
                })
                .catch((e: any) => {
                  addErrorToast({ text: e.message });
                });
            } else closeModal();
          }}
        />
      )}
    </Dialog>
  );
}

export default memo(InviteTeamModal);
