import { PageProps } from './types';
import SalesforcePage from './configurations/SalesforcePage';
import { CustomPage } from './configurations/CustomPage';

export const ConfigureRequestPage = (props: PageProps) => {
  const { state } = props;

  switch (state.template) {
    case 'salesforce':
      return <SalesforcePage {...props} />;
    default:
      return <CustomPage {...props} />;
  }
};
