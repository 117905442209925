import {
  InlineTooltip,
  NumberInput,
  YesNoSwitch
} from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { Form } from 'react-bootstrap';
import styles from '../styles.module.scss';

const SignaturePropertiesSection = ({ title, formData, updateData }: any) => {
  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <Form.Label className={styles.flexLabel}>
          Enable Email Verification
          <InlineTooltip
            text='Email verification is often used as proof of identity for e-signatures. Turning this off may impact the verifiability of the e-signature.'
            inline
            warning={true}
          />
        </Form.Label>
        <YesNoSwitch
          id='verify-email'
          checked={formData.verify_email}
          onChange={(e: any) => updateData('verify_email', e.target.checked)}
        />
      </div>
      <div className='field-group'>
        <Form.Label className={styles.flexLabel}>
          Enable Envelope Expiration
          <InlineTooltip
            text='The envelope must be signed within a set number of days before expiration.'
            inline
          />
        </Form.Label>
        <YesNoSwitch
          id='envelope-expires'
          checked={formData.expire_days != null}
          onChange={(e: any) =>
            updateData('expire_days', e.target.checked ? 5 : null)
          }
        />
      </div>
      {formData.expire_days != null && (
        <div className='field-group'>
          <Form.Label>Days Until Envelope Expires</Form.Label>
          <NumberInput
            classNames={{ root: 'form-control' }}
            min={1}
            max={365}
            value={formData.expire_days}
            onComplete={({ value }: any) => updateData('expire_days', value)}
          />
        </div>
      )}
    </SettingsContainer>
  );
};

export default SignaturePropertiesSection;
