import { APIConnectorTemplatePage } from './pages/APIConnectorTemplatePage';
import { ConfigureRequestPage } from './pages/ConfigureRequestPage';
import { MapResponseDataPage } from './pages/MapResponseDataPage';
import { MapResponseStructurePage } from './pages/MapResponseStructurePage';
import { SelectAPIConnectorPage } from './pages/SelectAPIConnectorPage';
import { TestRequestPage } from './pages/TestRequestPage';

export const SELECT_API_SERVICE_PAGE = 0;
export const API_CONNECTOR_TEMPLATE_PAGE = 1;
export const CONFIGURE_REQUEST_PAGE = 2;
export const MAP_RESPONSE_STRUCTURE_PAGE = 3;
export const TEST_REQUEST_PAGE = 4;
export const MAP_RESPONSE_DATA_PAGE = 5;

export const PAGE_COMPONENTS: any = {
  [SELECT_API_SERVICE_PAGE]: SelectAPIConnectorPage,
  [API_CONNECTOR_TEMPLATE_PAGE]: APIConnectorTemplatePage,
  [CONFIGURE_REQUEST_PAGE]: ConfigureRequestPage,
  [MAP_RESPONSE_STRUCTURE_PAGE]: MapResponseStructurePage,
  [TEST_REQUEST_PAGE]: TestRequestPage,
  [MAP_RESPONSE_DATA_PAGE]: MapResponseDataPage
};
