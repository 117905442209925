import { memo } from 'react';
import { CheckIcon, RefreshIcon } from '../../../Icons';
import { useAppSelector } from '../../../../hooks';
import TimeAgo from 'react-timeago';
import classNames from 'classnames';

export const STATE = {
  NO_DRAFT: '',
  UNSAVED: 'Unsaved changes',
  SAVING: 'Saving...',
  SAVED: 'Auto saved',
  NO_CONNECTION: 'No connection',
  CONFLICT: 'This version is out of date'
};

function DraftStatus({ draftState }: { draftState: string }) {
  const draftTimestamp = useAppSelector((s) => s.formBuilder.draftTimestamp);

  return (
    <div className='status'>
      {draftState === STATE.SAVED && (
        <div className='status-ok'>
          <CheckIcon width={10} height={10} />
        </div>
      )}
      {[
        STATE.NO_CONNECTION,
        STATE.CONFLICT,
        STATE.SAVING,
        STATE.UNSAVED
      ].includes(draftState) && (
        <div
          className={classNames('dot', {
            'status-ok': draftState === STATE.SAVING,
            'status-neutral': draftState === STATE.UNSAVED,
            'status-invalid': [STATE.NO_CONNECTION, STATE.CONFLICT].includes(
              draftState
            )
          })}
        >
          &#x2022;
        </div>
      )}
      {draftState}
      {draftState === STATE.SAVED && (
        <TimeAgo
          date={parseInt(draftTimestamp)}
          formatter={(value, unit, suffix) => {
            if (unit === 'second') {
              if (value < 5) return 'just now';
              else if (value < 10) return '5 seconds ' + suffix;
              else
                return (
                  Math.floor(value / 10) * 10 +
                  ' ' +
                  unit +
                  (value > 1 ? 's' : '') +
                  ' ' +
                  suffix
                );
            } else {
              return value + ' ' + unit + (value > 1 ? 's' : '') + ' ' + suffix;
            }
          }}
        />
      )}
      {draftState === STATE.CONFLICT && (
        <RefreshIcon
          className='clickable'
          width={10}
          color={'var(--grey-80)'}
          onClick={() => location.reload()}
        />
      )}
    </div>
  );
}

export default memo(DraftStatus);
