import { JSHINT } from 'jshint';
import { IAnnotation, IMarker } from 'react-ace';
import styles from './styles.module.scss';
import { Ace } from 'ace-builds';
import { TriggerEvents } from '../RuleFieldsPanel';
import { eventDefsMap, featheryBaseDef, typeDefToGraph } from './featheryDefs';
import { isValidJsIdentifier, stripBeginAndEndQuotes } from './utils';
import jsonpath from 'jsonpath';

interface ICodeError {
  line: number;
  character: number;
  reason: string;
  evidence: string;
  code: string;
}

type ValidateCodeResult = {
  errors: ICodeError[];
  annotations: IAnnotation[];
  lineMarkers: IMarker[];
  isValid: boolean;
};

// The JSHint browser option opens all browser/DOM variables as globals.
// We don't want to do that.  Instead, we'll whitelist the safe globals we want.
// https://jshint.com/docs/options/#browser also jshint vars.js line 78
export const browserGlobals = {
  atob: false,
  Blob: false,
  btoa: false,
  clearInterval: false,
  clearTimeout: false,
  crypto: false,
  fetch: false,
  File: false,
  FileList: false,
  FileReader: false,
  FormData: false,
  Intl: false,
  location: false,
  setInterval: false,
  setTimeout: false,
  TextDecoder: false,
  TextEncoder: false,
  URL: false,
  URLSearchParams: false,
  // Privileged operators
  document: false,
  window: false,
  Navigator: false,
  navigator: false
};

/**
 * Code Validation
 */
export const validateCode = (
  code: string,
  editor: Ace.Editor,
  triggerEvent: TriggerEvents | '',
  fieldKeys: string[]
): ValidateCodeResult => {
  const result: ValidateCodeResult = {
    errors: [],
    annotations: [],
    lineMarkers: [],
    isValid: false
  };

  // only allow fields that are valid identifiers at the global level
  const globalFields = fieldKeys.reduce((acc: any, field: any) => {
    if (isValidJsIdentifier(field)) acc[field] = false;
    return acc;
  }, {});

  // Need the line break below to avoid a weird JSHint error
  const asyncWrappedCode = `(async () => {
    ${code}
  })()`;

  JSHINT(asyncWrappedCode, {
    esversion: 9, // es version 9 is es2020
    undef: true, // prohibits the use of explicitly undeclared variables
    unused: false, // Disable the warning when you define and never use your variables.
    asi: true, // suppresses warnings about missing semicolons
    // white list of allowed globals
    globals: {
      feathery: false,
      console: false,
      ...globalFields,
      ...browserGlobals
    },
    sub: true, // This option suppresses warnings about using [] notation when it can be expressed in dot notation: person['name'] vs. person.name.
    typed: true //This option allows/defines globals for typed array constructors (ArrayBuffer, etc.)
  });

  // get JSHINT errors
  const linterErrors: ICodeError[] | undefined = JSHINT.data()?.errors;

  // now do extra validation on the code to flag and feathery context coding errors
  const featheryErrors = validateFeatheryContextUsage(
    editor,
    triggerEvent,
    fieldKeys
  );
  const allErrors = [...(linterErrors ?? []), ...featheryErrors];

  if (allErrors) {
    // only want one error annotation per line (the first one)
    const uniqueLineErrors = allErrors.reduce((acc: any, curr: any) => {
      if (!acc[curr.line]) acc[curr.line] = curr;
      return acc;
    }, {});

    // but show all errors in the error panel
    result.errors = allErrors
      // sort by line number, then character number to get the errors in order
      .sort((a: any, b: any) =>
        a.line > b.line
          ? 1
          : a.line < b.line
          ? -1
          : a.character > b.character
          ? 1
          : -1
      )
      .map((err: any) => ({
        line: err.line - 1,
        character: err.character,
        reason: err.reason,
        evidence: err.evidence,
        code: err.code
      }));

    // mark the lines with errors in pink
    result.lineMarkers = Object.values(uniqueLineErrors).map((err: any) => ({
      startRow: err.line - 2,
      startCol: err.character - 1,
      endRow: err.line - 2,
      endCol: err.character,
      className: styles.errorLineMarker,
      type: 'fullLine'
    }));

    // add annotations to the gutter for each error (only one error per line)
    result.annotations = Object.values(uniqueLineErrors).map((err: any) => ({
      row: err.line - 2,
      column: 0,
      text: err.reason,
      type: 'error'
    }));
  }

  return {
    ...result,
    isValid: !allErrors.length
  };
};

export interface ContextToken extends Ace.Token {
  identifier: string;
  row: number;
  column: number;
}

export const getFeatheryContextChain = (
  row: number,
  numRows: number,
  featheryStartTokenIndex: number,
  session: Ace.EditSession
): ContextToken[] => {
  // In order to find any feathery context chain that we may currently be in,
  // walk FORWARD from the current feathery token collecting sub-prop tokens until
  // there is a break in the parentage context.
  // A break is an identifier without a receding . or [.
  // Note: ignoring whitepace and new lines (i.e. a chain can span multiple lines).
  const featheryTokenContext: ContextToken[] = [];
  let featheryContext = false;
  let sawDotOrBracket = true;
  rowLoop: for (let curRow = row; curRow < numRows; curRow++) {
    const tokens = session.getTokens(curRow);

    const startToken = curRow === row ? featheryStartTokenIndex : 0;
    for (let i = startToken; i < tokens.length; i++) {
      const token = tokens[i];
      const prevToken = i > 0 ? tokens[i - 1] : null;
      if (token.type === 'identifier') {
        // an identifier inside a [] ends a feathery context chain - can't validate further
        if (prevToken?.value === '[') break rowLoop;
        if (sawDotOrBracket) {
          featheryTokenContext.push({
            ...token,
            identifier: token.value,
            row: curRow,
            column: 0 // not really critical
          });
          if (token.value === 'feathery') featheryContext = true;
        } else break rowLoop;
      } else if (token.type === 'string') {
        if (sawDotOrBracket)
          featheryTokenContext.push({
            ...token,
            identifier: stripBeginAndEndQuotes(token.value),
            row: curRow,
            column: 0 // not really critical
          });
        else break rowLoop;
      } else if (token.type === 'text' && token.value.trim() === '') {
        // ignore whitespace and new lines
      } else if (
        // [] and . are valid tokens in a feathery context chain but do not add them to the context
        // Ignore ., [,  and ] tokens but keep going, otherwise break because the context chain is broken
        !['[', ']', '.'].includes(token.value)
      ) {
        // any other token type is a break in the context chain
        break rowLoop;
      } else if (['[', '.'].includes(token.value)) sawDotOrBracket = true;
    }
  }
  if (!featheryContext) return [];
  return featheryTokenContext;
};

export const validateFeatheryContextUsage = (
  editor: Ace.Editor,
  event: TriggerEvents | '',
  fieldKeys: string[]
): ICodeError[] => {
  const errors: ICodeError[] = [];

  const addError = (contextToken: ContextToken) => {
    errors.push({
      line: contextToken.row + 2,
      character: contextToken.column,
      reason: `Invalid property: ${contextToken.identifier}`,
      evidence: contextToken.value,
      code: 'feathery'
    });
  };

  const featheryDef = eventDefsMap[event] ?? featheryBaseDef;
  const featheryTypeDefGraph = typeDefToGraph(featheryDef, fieldKeys);

  if (editor) {
    const session = editor.session;
    // using codeLines to optimize extra peformant validation on feathery context
    let codeLines: string[] = [];
    codeLines = editor.session.getLines(0, 99999999); // all the lines

    // for each code line that contains 'feathery', find the offset
    codeLines.forEach((line, row) => {
      // If the line contain 'feathery' text, then validate the feathery context.

      // NOTE: This code is not validating global level fields.  I am concerned about
      // the performance of that validation since each code line would have to be
      // checked for all possible fields.  It is a TODO for the future and would involve:
      // * Optimizing to validate only the current line being edited (editor.getSelectionRange().start.row)
      // * However when we first show the editor for an existing rule, we would have to validate
      //   all lines.  It will be ok if that one takes a bit more time.
      // This is not critical right now.

      if (line.includes('feathery')) {
        // tokenize the line and walk the tokens.  For each token, check if it is a feathery identifier.
        // If so, then validate feathery context - make sure all child props are valid.
        const tokens = session.getTokens(row);
        tokens.forEach((token, tokenIndex) => {
          if (token.value === 'feathery') {
            const isAttr =
              tokenIndex > 0 && tokens[tokenIndex - 1].value === '.';
            if (isAttr) return; // If just accessing an attribute called feathery, it's not the global context object

            const featheryContextChain = getFeatheryContextChain(
              row,
              codeLines.length,
              tokenIndex,
              session
            );
            if (featheryContextChain.length > 0) {
              // Check each level of the feathery context chain to make sure it is valid
              // using jsonpath to query featheryTypeDefGraph
              let contextPath = '$';
              featheryContextChain.forEach((contextToken, index) => {
                contextPath += `['${contextToken.identifier}']`;
                try {
                  const queryResult = jsonpath.query(
                    featheryTypeDefGraph,
                    contextPath
                  );
                  // not a valid path into the graph
                  if (queryResult.length === 0) {
                    addError(contextToken);
                  }
                } catch (e) {
                  addError(contextToken);
                }
              });
            }
          }
        });
      }
    });
  }

  return errors;
};
