/* eslint-disable react-hooks/exhaustive-deps */

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { memo, useMemo } from 'react';

import AssetsMenuItem from '../AssetItem/AssetsMenuItem';
import { elementTypeToAsset } from '../../../../utils/themes';
import styles from './styles.module.scss';
import { CollapsibleSection } from '../../../Core';
import ListItem from './ListItem';
import classNames from 'classnames';

const ElementListSource = ({
  mode = 'drag',
  elementType,
  icon,
  label,
  message,
  onElementMouseDown,
  theme,
  setTheme,
  handleUpdates,
  context,
  deleteAssetFromTheme
}: any) => {
  const assetsProp = elementTypeToAsset(elementType);
  const assets = useMemo(() => {
    const assetsList = theme?.[assetsProp] ?? [];
    if (assetsProp === 'servar_field_assets') {
      return assetsList.filter((a: any) => a.servar.type === elementType);
    } else {
      return assetsList;
    }
  }, [theme]);

  let source;
  if (assets.length > 0) {
    source = (
      <CollapsibleSection
        key={elementType}
        title={label}
        expanded
        collapsible={mode !== 'click'}
        customClasses={{
          root: styles.listContainer,
          header: classNames(
            styles.listHeader,
            mode === 'click' ? styles.reducedPadding : ''
          ),
          content: classNames(
            styles.listContent,
            mode === 'click' ? styles.noPadding : ''
          ),
          title: styles.listTitle
        }}
      >
        <ListItem
          mode={mode}
          icon={icon}
          label='Primary'
          elementType={elementType}
          onElementMouseDown={onElementMouseDown}
          key='element'
          disabled={message}
        />
        {[...assets]
          .sort((a: any, b: any) => (a.key > b.key ? 1 : -1))
          .map((asset: any, index: any) => (
            <AssetsMenuItem
              theme={theme}
              setTheme={setTheme}
              handleUpdates={handleUpdates}
              mode={mode}
              key={`asset-${index}`}
              icon={icon}
              asset={asset}
              elementType={elementType}
              onElementMouseDown={onElementMouseDown}
              listStyling
              context={context}
              deleteAssetFromTheme={deleteAssetFromTheme}
              disableContextMenu={mode === 'click'}
            />
          ))}
      </CollapsibleSection>
    );
  } else {
    source = (
      <ListItem
        mode={mode}
        icon={icon}
        label={label}
        elementType={elementType}
        onElementMouseDown={onElementMouseDown}
        key='element'
        disabled={message}
        separator
        style={{
          borderTop:
            mode === 'click' && label === 'Container' ? 'none' : undefined,
          paddingLeft: mode === 'click' ? '12px' : undefined
        }}
      />
    );
  }

  // If message is defined, we need to disable the field source and show a tooltip
  return message ? (
    <OverlayTrigger placement='right' overlay={<Tooltip>{message}</Tooltip>}>
      {/* OverlayTrigger doesn't work without extra div around custom component */}
      <div className={styles.tooltipDiv}>{source}</div>
    </OverlayTrigger>
  ) : (
    source
  );
};

export default memo(ElementListSource);
