import { HTTPMethodBadge } from './HTTPMethodBadge';
import styles from './styles.module.scss';

type APIConnectorInputProps = {
  method?: string;
  url?: string;
  onClick?: () => void;
  disabled?: boolean;
};

export const APIConnectorInput = ({
  method = '',
  url = '',
  onClick = () => {},
  disabled = false
}: APIConnectorInputProps) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <button
      className={styles.apiConnectorInput}
      onClick={handleClick}
      disabled={disabled}
    >
      <HTTPMethodBadge method={method} />
      {url || 'Click to Configure'}
    </button>
  );
};
