import { memo } from 'react';

import styles from '../styles.module.scss';
import testIds from '../../../../utils/testIds';

const BASE_ICON_URL = 'https://feathery.s3.us-west-1.amazonaws.com/templates/';

function TemplateSelectCard({ template, index, onSelect = () => {} }: any) {
  if (template === 'generate')
    return <GenerateCard index={index} template={template} />;
  if (template === 'migrate')
    return <MigrateCard index={index} template={template} />;
  if (template === 'blank')
    return <BlankCard index={index} template={template} onSelect={onSelect} />;
  if (template === undefined) return null;

  const fileName = template.replaceAll(' ', '+');
  return (
    <>
      <div
        className={styles.card}
        style={{ gridColumn: `${index + 1}/${index + 2}` }}
      >
        <img
          data-testid={testIds.template(template)}
          src={`${BASE_ICON_URL}${fileName}.jpg`}
          alt='Template'
          onClick={() => onSelect(template)}
        />
        <div className={styles.label}>{template}</div>
      </div>
    </>
  );
}

export function BlankCard({ index, template, onSelect }: any) {
  return (
    <>
      <div
        onClick={() => onSelect(template)}
        className={styles.card}
        style={{ gridColumn: `${index + 1}/${index + 2}` }}
      >
        <div className={styles.imageWrapper}>
          <img
            data-testid={testIds.template(template)}
            src={`${BASE_ICON_URL}blank_template.png`}
            alt='Template'
          />
        </div>
        <div className={styles.label}>Blank Form</div>
      </div>
    </>
  );
}

export function GenerateCard({ index, template }: any) {
  return (
    <>
      <div
        onClick={() => {
          window.open('https://ai.feathery.io/', '_blank');
        }}
        className={styles.card}
        style={{ gridColumn: `${index + 1}/${index + 2}` }}
      >
        <div className={styles.imageWrapper}>
          <img
            data-testid={testIds.template(template)}
            src={`${BASE_ICON_URL}generate_ai.png`}
            alt='Generate with AI'
          />
        </div>
        <div className={styles.label}>Generate with AI</div>
      </div>
    </>
  );
}

export function MigrateCard({ index, template }: any) {
  return (
    <>
      <div
        onClick={() => {
          window.open('https://ai.feathery.io/typeform', '_blank');
        }}
        className={styles.card}
        style={{ gridColumn: `${index + 1}/${index + 2}` }}
      >
        <div className={styles.imageWrapper}>
          <img
            data-testid={testIds.template(template)}
            src={`${BASE_ICON_URL}migrate_typeform.png`}
            alt='Migrate a Typeform'
          />
        </div>
        <div className={styles.label}>Migrate a Typeform</div>
      </div>
    </>
  );
}

export default memo(TemplateSelectCard);
