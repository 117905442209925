import { NavBar } from '../../components/NavBar';
import { PageProps } from '../types';
import {
  API_CONNECTOR_TEMPLATE_PAGE,
  CONFIGURE_REQUEST_PAGE,
  MAP_RESPONSE_STRUCTURE_PAGE,
  SELECT_API_SERVICE_PAGE
} from '../../constants';
import { useEffect, useMemo, useState } from 'react';
import { TextField } from '../../../../Core';
import {
  BasicValueComponent,
  ValueComponentProps
} from '../../components/KeyValueInput';
import { useFields } from '../../../../../hooks/useFields';
import { searchForFields } from '../../utils';
import styles from '../styles.module.scss';
import { ErrorMarkerIcon } from '../../../../Icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RolloutCredentialPicker } from '../../components/RolloutCredentialPicker';
import { replaceValue, ROLLOUT_TOKENS, validateState } from '../utils';
import { GUIDE_TEXT } from '../../api_templates';

export default function SalesforcePage(props: PageProps) {
  const [errors, setErrors] = useState<any>({});
  const {
    state,
    setState,
    onSubmit = () => {},
    onChange = () => {},
    goto = () => {},
    apiConnectors = [],
    options
  } = props;

  const fields = useFields(true);
  const fieldKeys = useMemo(() => {
    const fieldMap = fields.reduce((acc, f) => ({ ...acc, [f.key]: true }), {});
    ROLLOUT_TOKENS.forEach((token) => (fieldMap[token] = true));
    fieldMap.feathery_user_id = true;
    fieldMap.feathery_auth_email = true;
    return fieldMap;
  }, [fields]);

  const handleSubmit = () => {
    if (!validateState(state, { setErrors, apiConnectors, fieldKeys })) return;
    else onSubmit(MAP_RESPONSE_STRUCTURE_PAGE);
  };

  const FieldTextInput = useMemo(() => {
    const FieldTextInputComponent = (props: ValueComponentProps) => {
      const { onComplete = () => {}, ...rest } = props;
      const [fieldTextError, setFieldTextError] = useState(false);

      const validate = (text: string) => {
        const fieldNames = searchForFields(text);

        if (fieldNames.length) {
          const invalidFields = fieldNames.find((f) => !fieldKeys[f]);

          if (invalidFields) {
            setFieldTextError(true);
          }
        }
      };

      useEffect(() => {
        validate(rest.value);
      }, []);

      const handleComplete = (text: string) => {
        validate(text);
        onComplete(text);
      };

      return (
        <BasicValueComponent
          {...rest}
          type='textarea'
          onComplete={handleComplete}
          error={fieldTextError}
        />
      );
    };

    return FieldTextInputComponent;
  }, [fieldKeys]);

  const guideText: JSX.Element[] = [GUIDE_TEXT['salesforce']];

  return (
    <>
      <div className={styles.page}>
        <div className={styles.content}>
          {guideText.length > 0 && (
            <div className={styles.field}>{guideText}</div>
          )}
          <div className={styles.field}>
            <div className={styles.label}>Name</div>
            <TextField
              value={state.name}
              onChange={(val: string) => {
                onChange('name', val);
                validateState(
                  { ...state, name: val },
                  {
                    targets: ['name'],
                    setErrors,
                    apiConnectors
                  }
                );
              }}
              error={errors.name}
              errorMessage={errors.name}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Salesforce Account</div>
            <RolloutCredentialPicker
              app='salesforce'
              hideLabel
              credentialKey={state.tokens?.salesforce ?? null}
              onChange={(credential, data) => {
                const { previousCredential } = data ?? {};

                if (
                  previousCredential &&
                  previousCredential.data.userCustomDomainUrl &&
                  credential &&
                  credential.data.userCustomDomainUrl
                ) {
                  const newState = replaceValue(
                    state,
                    previousCredential.data.userCustomDomainUrl,
                    credential.data.userCustomDomainUrl
                  );
                  newState.tokens.salesforce = credential.credentialKey;
                  setState(newState);
                } else {
                  onChange('tokens', {
                    ...(state.tokens ?? {}),
                    salsforce: credential.credentialKey
                  });
                }
              }}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>
              Salesforce Query{' '}
              {errors['params'] && (
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip style={{ zIndex: 99999999 }}>
                      {errors['params']}
                    </Tooltip>
                  }
                >
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <ErrorMarkerIcon />
                  </div>
                </OverlayTrigger>
              )}
            </div>
            <FieldTextInput
              onComplete={(value) => {
                const params = { q: value };
                onChange('params', params);
                validateState(
                  { ...state, params },
                  { targets: ['params'], setErrors, fieldKeys }
                );
              }}
              index={0}
              rows={4}
              value={state.params.q}
            />
          </div>

          <button
            onClick={() => {
              onChange('template', '');
            }}
            className='tw-text-primary !tw-p-0 tw-float-right hover:tw-underline'
          >
            Switch to advanced configuration
          </button>
        </div>
      </div>

      <NavBar
        next
        back={
          options?.startPage != null &&
          options.startPage < CONFIGURE_REQUEST_PAGE
        }
        onClick={(btn: string) => {
          if (btn === 'next') handleSubmit();
          else if (btn === 'back') {
            goto(
              state.id ? SELECT_API_SERVICE_PAGE : API_CONNECTOR_TEMPLATE_PAGE
            );
          }
        }}
      />
    </>
  );
}
