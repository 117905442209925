import styles from './styles.module.scss';
import { Switch } from '../';

function YesNoSwitch({ checked, switchStyle, ...props }: any) {
  const noClass = checked ? styles.disabled : styles.enabled;
  const yesClass = checked ? styles.enabled : styles.disabled;
  return (
    <div className={styles.switchContainer} style={switchStyle}>
      <span className={noClass}>No</span>
      <Switch checked={checked} className={styles.switch} {...props} />
      <span className={yesClass}>Yes</span>
    </div>
  );
}

export default YesNoSwitch;
