import { DropdownField } from '../../../../../../../components/Core';
import { useRuleBuilderUpdater } from '../../../context';
import { RuleOperand } from '../../../context/RuleDSL';
import { RuleOperand as Operand } from '../../RuleOperand';
import classNames from 'classnames';
import ruleStyles from '../../../../../../../components/NavigationRules/styles.module.scss';
import styles from '../styles.module.scss';
import { useParams } from 'react-router-dom';
import useIntegrations from '../../../../../../../components/Integrations/useIntegrations';
import { INTEGRATIONS } from '../../../../../../../components/Integrations';

type EmailActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
};

const EditMode = ({ action }: EmailActionProps) => {
  const { formId } = useParams<{ formId: string }>();
  const { updateAction, updateOperand } = useRuleBuilderUpdater((s) => ({
    updateAction: s.updateAction,
    updateOperand: s.updateOperand
  }));

  const { email: emailInteg } = useIntegrations({
    types: [INTEGRATIONS.EMAIL],
    panelId: formId
  });
  const emailTemplates = emailInteg.data.secret_metadata.email_templates.filter(
    (email: any) => email.trigger === 'logic_rule'
  );

  const [emailTemplate] = action.parameters;

  const emailOptions = Object.keys(emailTemplates).map((id: string) => ({
    display: emailTemplates[id].subject,
    value: id
  }));

  const handleEmailChange = (newValue: string) => {
    if (emailTemplate) {
      updateOperand(emailTemplate.id, {
        type: 'value',
        value: newValue,
        meta: {
          email_subject: emailTemplates[newValue].subject,
          id: emailTemplates[newValue].id
        }
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'value';
      operand.value = newValue;
      operand.meta.key = {
        email_subject: emailTemplates[newValue].subject,
        id: emailTemplates[newValue].id
      };

      updateAction(action.id, {
        parameters: [operand.toJSON()]
      });
    }
  };

  return (
    <DropdownField
      className={classNames(ruleStyles.ruleTextField, ruleStyles.valueInput)}
      selected={emailTemplate?.value ?? ''}
      title={emailTemplate?.value ?? ''}
      options={emailOptions}
      onChange={(event: any) => handleEmailChange(event.target.value)}
      triggerCleanUp
    />
  );
};

const ViewMode = ({ action }: EmailActionProps) => {
  const { formId } = useParams<{ formId: string }>();
  const [emailTemplate] = action.parameters;

  const { email: emailInteg } = useIntegrations({
    types: [INTEGRATIONS.EMAIL],
    panelId: formId
  });
  const emailTemplates = emailInteg.data.secret_metadata.email_templates.filter(
    (email: any) => email.trigger === 'logic_rule'
  );

  const getDisplay = (operand: IRuleOperand) => {
    return (
      emailTemplates[operand.value]?.subject ?? 'No email set for logic rule'
    );
  };

  return <Operand operand={emailTemplate} getDisplay={getDisplay} bold />;
};

export const EmailAction = ({ action, mode = 'view' }: EmailActionProps) => {
  return (
    <div className={styles.actionContent}>
      {mode === 'view' ? (
        <ViewMode action={action} />
      ) : (
        <EditMode action={action} />
      )}
    </div>
  );
};
