import { v4 as uuidv4 } from 'uuid';

export const getDefaultEntity = () => ({
  name: '',
  criteria: '',
  field_id: null,
  options: [],
  default_option: ''
});

export const defaultQuestionGroup = () => ({
  id: uuidv4(),
  question_type: 'one_value',
  entities: [getDefaultEntity()],
  field_ids: [],
  criteria: ''
});

export const getDefaultQuestionGroups = (extraction: any) => {
  return extraction?.question_groups && extraction.question_groups.length
    ? extraction.question_groups.map((group: any) => {
        return {
          ...group,
          entities: group.entities
            ? group.entities.map((entity: any, i: number) => ({
                ...entity,
                field_id: group.field_ids[i]
              }))
            : [getDefaultEntity()],
          toggle_page_question: !!group.page_question
        };
      })
    : [defaultQuestionGroup()];
};
