import {
  DropdownField,
  InlineTooltip,
  TextField,
  YesNoSwitch
} from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { Form } from 'react-bootstrap';
import { onFreePlan } from '../../../components/PricingPlans/plans';
import { useAppSelector } from '../../../hooks';

const FormBehaviorSection = ({ title, formData, handleValueChange }: any) => {
  const org = useAppSelector((state) => state.accounts.organization);

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <Form.Label>
          Complete Submission When All Required Fields Filled
          <InlineTooltip
            text='NOTE: If your last form steps have optional fields, your submissions may be set to "Completed" earlier than you expect'
            inline
          />
        </Form.Label>
        <YesNoSwitch
          id='submission-complete-required-fields-fill'
          checked={formData.complete_required_fields_submission ?? true}
          onChange={(e: any) =>
            handleValueChange('complete_required_fields_submission')(
              e.target.checked
            )
          }
        />
      </div>

      <div className='field-group'>
        <Form.Label>
          After Form Completion
          <InlineTooltip
            text='What happens to the form after your user completes it'
            inline
          />
        </Form.Label>
        <DropdownField
          name='completion_behavior'
          selected={formData.completion_behavior || ''}
          onChange={(e: any) =>
            handleValueChange('completion_behavior')(e.target.value)
          }
          options={[
            { value: '', display: 'Hide Form' },
            {
              value: 'redirect',
              display: 'Redirect to URL'
            },
            {
              value: 'show_completed_screen',
              display: 'Show Form Completed Screen'
            }
          ]}
        />
      </div>

      {formData.completion_behavior === 'redirect' && (
        <div className='field-group'>
          <Form.Label>Redirect URL</Form.Label>
          <TextField
            placeholder='https://homepage.com'
            value={formData.redirect_url ?? ''}
            onComplete={handleValueChange('redirect_url')}
          />
        </div>
      )}

      <div className='field-group'>
        <Form.Label>
          Autoscroll
          <InlineTooltip
            text='When on a new step, autoscroll to the top'
            inline
          />
        </Form.Label>
        <DropdownField
          name='autoscroll'
          selected={formData.autoscroll || 'top_of_form'}
          onChange={(e: any) => handleValueChange('autoscroll')(e.target.value)}
          options={[
            { value: 'top_of_form', display: 'To top of the form' },
            {
              value: 'top_of_window',
              display: 'To top of the site (if embedded)'
            },
            { value: 'none', display: 'None' }
          ]}
        />
      </div>

      <div className='field-group'>
        <Form.Label>
          Autofocus Fields
          <InlineTooltip
            text='On each step, automatically set the cursor to the first field'
            inline
          />
        </Form.Label>
        <YesNoSwitch
          id='autofocus'
          checked={formData.autofocus ?? true}
          onChange={(e: any) =>
            handleValueChange('autofocus')(e.target.checked)
          }
        />
      </div>

      <div className='field-group'>
        <Form.Label>Press &quot;Enter&quot; to Submit</Form.Label>
        <YesNoSwitch
          id='enter_submit'
          checked={formData.enter_submit ?? true}
          onChange={(e: any) =>
            handleValueChange('enter_submit')(e.target.checked)
          }
        />
      </div>

      <div className='field-group'>
        <Form.Label>
          Browser Autocomplete
          <InlineTooltip
            text='Allow the browser to complete form fields for the user'
            inline
          />
        </Form.Label>
        <YesNoSwitch
          id='browser-autocomplete'
          checked={formData.autocomplete ?? true}
          onChange={(e: any) =>
            handleValueChange('autocomplete')(e.target.checked)
          }
        />
      </div>

      <div className='field-group'>
        <Form.Label>Show Form Validation Errors</Form.Label>
        <DropdownField
          name='error_type'
          selected={formData.error_type || 'html5'}
          onChange={(e: any) => handleValueChange('error_type')(e.target.value)}
          options={[
            { value: 'html5', display: 'Browser Native' },
            { value: 'inline', display: 'Inline' }
          ]}
        />
      </div>

      {(onFreePlan(org) || org?.show_brand) && (
        <div className='field-group'>
          <Form.Label>
            Feathery Badge Position
            <InlineTooltip
              text='Free plans display a "Built on Feathery" badge on your forms. You may configure its position here.'
              inline
            />
          </Form.Label>
          <DropdownField
            selected={formData.brand_position ?? 'bottom_right'}
            onChange={(e: any) =>
              handleValueChange('brand_position')(e.target.value)
            }
            options={[
              { value: 'bottom_right', display: 'Bottom Right Corner' },
              { value: 'bottom_left', display: 'Bottom Left Corner' },
              { value: 'top_right', display: 'Top Right Corner' },
              { value: 'top_left', display: 'Top Left Corner' }
            ]}
          />
        </div>
      )}
    </SettingsContainer>
  );
};

export default FormBehaviorSection;
