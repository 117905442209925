import '../../style/dialog-form.css';
import '../../style/tabs-customized.scss';

import { Tab, Tabs } from 'react-bootstrap';
import { VideoPropertiesPanel, VideoStylePanel } from '../Panels';

import FormFieldEditor from './FormFieldEditor';

import { cellOperation, elementOperation, getAsset } from '../../utils/themes';
import styles from './formFieldEditor.module.scss';
import useFeatheryRedux from '../../redux';
import { EDITOR_TABS } from '../../types/formBuilder';
import { calculateOverrideObjects } from '../Panels/utils';
import { TYPE_VIDEO } from '../../utils/elements';
import { useAppSelector } from '../../hooks';

function VideoEditor({
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  handleUpdates,
  onClose,
  isAsset,
  cellNode
}: any) {
  const activeTab = useAppSelector((state) => state.formBuilder.activeTab);
  const {
    formBuilder: { selectTab }
  } = useFeatheryRedux();
  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps
  );
  const asset = getAsset(theme, TYPE_VIDEO, element.source_asset);

  const onDelete = () => {
    onClose();
    handleUpdates([
      mode === 'builder'
        ? cellOperation({ viewport, remove: true })
        : elementOperation({ viewport, remove: true, asset })
    ]);
  };

  const commonProps = {
    mode,
    viewport,
    theme,
    baseProps,
    baseStyle,
    overrideProps,
    overrideStyle,
    handleUpdates,
    cellNode
  };

  return (
    <FormFieldEditor
      mode={mode}
      onDelete={onDelete}
      element={element}
      elementType={TYPE_VIDEO}
      assetName={asset?.key}
      cellNode={cellNode}
    >
      {(mode === 'builder' || isAsset) && (
        <Tabs
          activeKey={activeTab}
          onSelect={(tab) => selectTab(tab)}
          unmountOnExit
        >
          <Tab
            eventKey={EDITOR_TABS.PROPERTIES}
            title='Properties'
            className={styles.detailPanelTab}
          >
            <VideoPropertiesPanel {...commonProps} />
          </Tab>
          <Tab
            eventKey={EDITOR_TABS.STYLES}
            title='Style'
            className={styles.detailPanelTab}
          >
            <VideoStylePanel {...commonProps} />
          </Tab>
        </Tabs>
      )}
      {mode === 'theme' && !isAsset && <VideoStylePanel {...commonProps} />}
    </FormFieldEditor>
  );
}

export default VideoEditor;
