import { ReactElement } from 'react';
import { ConnectDragSource, DragSourceMonitor, useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { CustomDragOptions } from '../index';
import { useAppSelector } from '../../../hooks';

interface useCustomDragLayerProps {
  type: string;
  renderer?: () => ReactElement;
  onStart: () => any;
  onEnd: () => void;
  opts?: CustomDragOptions;
}

const useCustomDragLayer = (
  getProps: () => useCustomDragLayerProps,
  deps: any[]
): [any, ConnectDragSource] => {
  const editingText = useAppSelector((state) => state.formBuilder.editingText);

  const props = getProps();
  const [dragState, dragRef, preview] = useDrag(() => {
    return {
      type: props.type,
      item: () => {
        const item = props.onStart();
        if (!item) return {};
        if (!props.opts?.useDefaultPreview) {
          Object.defineProperty(item, 'customRenderer', {
            value: props.renderer,
            writable: false
          });
        }
        Object.defineProperty(item, 'opts', {
          value: props.opts,
          writable: false
        });
        return item;
      },
      end: (dragObject, sourceMonitor: DragSourceMonitor) => {
        const item = sourceMonitor.getItem();
        Object.defineProperty(item, 'didDrop', {
          value: sourceMonitor.didDrop(),
          writable: false
        });
        props.onEnd();
      },
      canDrag: () =>
        props.opts?.onCanDrag ? props.opts.onCanDrag() : !editingText,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    };
  }, [...deps, editingText]);
  preview(getEmptyImage(), { captureDraggingState: true });
  return [dragState, dragRef];
};

export default useCustomDragLayer;
