import { Switch } from '../../../Core';
import { useState } from 'react';
import Dialog from '../../../Dialog';
import { Neutral, Positive } from '../../../Core/Button';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function MultiselectToggle({
  servar,
  onChange,
  disabled = false
}: any) {
  const [show, setShow] = useState(false);

  const multiple = servar.metadata.multiple;

  if (!['file_upload', 'button_group', 'matrix'].includes(servar.type))
    return null;

  const multiDisabled =
    servar.type === 'file_upload' && !multiple && servar.repeated;

  let switchComponent = (
    <Switch
      id='multiple'
      checked={multiple}
      label={`Allow Multiple ${
        servar.type === 'file_upload' ? 'Files' : 'Selections'
      }`}
      onChange={(event: any) => {
        const checked = event.target.checked;
        if (multiple && servar.has_data && !checked) {
          setShow(true);
        } else onChange(checked);
      }}
      disabled={multiDisabled || disabled}
    />
  );
  if (multiDisabled)
    switchComponent = (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip id='multi-disabled-tooltip'>
            Repeating file upload fields don&apos;t support multiple files per
            field.
          </Tooltip>
        }
      >
        <div>{switchComponent}</div>
      </OverlayTrigger>
    );
  return (
    <>
      <div style={{ marginBottom: '5px' }}>{switchComponent}</div>
      <Dialog
        isOpen={show}
        onClose={() => setShow(false)}
        shouldShowCloseIcon
        title='Turn field to single select?'
        size='sm'
      >
        <p style={{ padding: '40px 0', fontSize: '16px' }}>
          You have existing submissions with multiple selections. They will be
          reduced to single values.
        </p>
        <div
          className='dialog-form-action'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Neutral title='No' onClick={() => setShow(false)} />
          <Positive
            title='Yes'
            onClick={(event: any) => {
              event.preventDefault();
              onChange(false);
              setShow(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
}
