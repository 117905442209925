import { memo } from 'react';
import { Neutral, Positive } from '../Core/Button';
import { useAppSelector } from '../../hooks';

import Dialog from '../Dialog';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../utils/format';

const RestoreLastPublishedConfirmModal = ({
  show,
  setShow,
  onRestore
}: any) => {
  const { formId } = useParams<{ formId: string }>();
  const panel = useAppSelector((state) => state.panels.panels[formId]);

  return (
    <Dialog
      isOpen={show}
      onClose={() => setShow(false)}
      shouldShowCloseIcon={false}
      title='Changes you made since your last publish will be lost'
    >
      <div>
        <p>
          You are about to restore your published form from{' '}
          {formatDate(panel.updated_at)}. Are you sure you want to continue?
        </p>
      </div>
      <div className='dialog-form-action text-center'>
        <Neutral title='Cancel' onClick={() => setShow(false)} />
        <Positive
          title='Confirm and restore'
          onClick={(e: any) => {
            e.preventDefault();
            onRestore();
            setShow(false);
          }}
        />
      </div>
    </Dialog>
  );
};

export default memo(RestoreLastPublishedConfirmModal);
