import { ContainerPropertiesPanel, ContainerStylePanel } from '../../Panels';
import styles from '../formFieldEditor.module.scss';
import FormFieldEditor from '../FormFieldEditor';
import { cellOperation } from '../../../utils/themes';
import useFeatheryRedux from '../../../redux';
import { EDITOR_TABS } from '../../../types/formBuilder';
import { Tab, Tabs } from 'react-bootstrap';
import { useAppSelector } from '../../../hooks';
import KeyField from './KeyField';

const ContainerEditor = ({
  viewport,
  handleUpdates,
  onClose,
  cellNode
}: any) => {
  const activeContainerTab = useAppSelector(
    (state) => state.formBuilder.activeContainerTab
  );

  const {
    formBuilder: { selectContainerTab }
  } = useFeatheryRedux();

  const customHeaderRows = [
    <KeyField key={`${cellNode?.uuid}-key-field`} cellNode={cellNode} />
  ];

  return (
    <FormFieldEditor
      mode='builder'
      cellNode={cellNode}
      customHeaderRows={customHeaderRows}
      onDelete={() => {
        onClose();
        handleUpdates([cellOperation({ viewport, remove: true })]);
      }}
    >
      <Tabs
        activeKey={activeContainerTab}
        onSelect={(tab) => selectContainerTab(tab)}
        unmountOnExit
      >
        <Tab
          eventKey={EDITOR_TABS.PROPERTIES}
          title='Properties'
          className={styles.detailPanelTab}
        >
          <ContainerPropertiesPanel
            viewport={viewport}
            handleUpdates={handleUpdates}
            cellNode={cellNode}
          />
        </Tab>
        <Tab
          eventKey={EDITOR_TABS.STYLES}
          title='Style'
          className={styles.detailPanelTab}
        >
          <ContainerStylePanel
            viewport={viewport}
            handleUpdates={handleUpdates}
            cellNode={cellNode}
          />
        </Tab>
      </Tabs>
    </FormFieldEditor>
  );
};

export default ContainerEditor;
