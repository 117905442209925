import { useMemo, useState } from 'react';
import { INTEGRATION_TAGS } from '../types';

import styles from './styles.module.scss';
import classNames from 'classnames';

export default function useFilterTag() {
  const [filterTag, setFilterTag] = useState('All');

  const filterPills = useMemo(() => {
    const pills = ['All', ...Object.values(INTEGRATION_TAGS)].map((label) => {
      const selected = filterTag === label;
      return (
        <FilterPill
          key={label}
          label={label}
          selected={selected}
          onClick={() => setFilterTag(selected ? 'All' : label)}
        />
      );
    });
    return <div className={styles.filterPillContainer}>{pills}</div>;
  }, [filterTag, setFilterTag]);

  return { filterTag, filterPills };
}

function FilterPill({
  label,
  selected,
  onClick = () => {}
}: {
  label: string;
  selected: boolean;
  onClick: any;
}) {
  return (
    <div
      className={classNames(styles.filterPill, selected && styles.selected)}
      onClick={onClick}
    >
      {label}
    </div>
  );
}
