export default function PublishRocket() {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.4833 20.0172C19.4984 19.6194 19.7357 19.263 20.093 19.0874C25.3153 16.521 28.4657 10.9526 27.9438 5.13391C27.8897 4.59494 27.4565 4.10986 26.861 4.05596C21.0159 3.53642 15.4223 6.67279 12.8443 11.872C12.668 12.2276 12.3133 12.4642 11.9167 12.4809C10.2491 12.5508 6.72955 12.8476 6.12509 13.5419C5.15055 14.6738 4.50086 16.0212 4.23016 17.4765C4.18213 17.7156 4.30456 17.9547 4.52185 18.0432C4.57804 18.0661 4.65294 18.0693 4.71361 18.0693V18.0693C4.72498 18.0693 4.7231 18.0691 4.73446 18.0687C5.90908 18.0274 7.11824 18.1946 8.27772 18.5143C8.75609 18.6461 9.00502 19.1266 8.94049 19.6185C8.89816 19.9413 8.93314 20.1641 9.15697 20.3869C11.512 22.7314 11.5643 23.0531 12.3402 22.9518C12.8192 22.8893 13.2828 23.1429 13.4143 23.6077C13.7409 24.7623 13.9112 25.9524 13.8672 27.178C13.8672 27.4475 14.0838 27.6631 14.3545 27.6631C14.3601 27.6631 14.3658 27.6631 14.3714 27.6631C14.4322 27.6631 14.4929 27.6578 14.5526 27.646C15.98 27.3648 17.3014 26.6742 18.415 25.7767C19.1207 25.1679 19.4176 21.7511 19.4833 20.0172ZM20.0393 11.9789C19.173 11.1165 19.173 9.71521 20.0393 8.85285C20.9055 7.99049 22.3132 7.99049 23.1794 8.85285C24.0457 9.71521 24.0457 11.1165 23.1794 11.9789C22.3132 12.7874 20.9055 12.7874 20.0393 11.9789Z'
        stroke='none'
      />
      <path
        d='M5.87367 23C5.45683 23.1251 5.12335 23.3752 4.83156 23.7086C4.55118 24.0942 4.03507 25.8352 4.0017 27.2222C3.99112 27.6618 4.33803 28.0097 4.77766 27.9979C6.1473 27.9615 7.90715 27.4479 8.29137 27.1684C8.62484 26.8766 8.87495 26.5432 9 26.1263'
        stroke='none'
      />
    </svg>
  );
}
