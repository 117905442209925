import styles from './styles.module.scss';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { memo } from 'react';

export default memo(function CheckboxField({
  required,
  disabled,
  disabledMessage,
  checked = false,
  text,
  onChange: handleChange,
  className,
  style,
  ...props
}: {
  required?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  checked?: boolean;
  text?: any;
  onChange?: (checked: boolean) => void;
  className?: string;
  style?: any;
  [prop: string]: any;
}) {
  const checkbox = (
    <div style={style} className={styles.fieldWrapper}>
      <input
        type='checkbox'
        required={required}
        disabled={disabled}
        checked={checked}
        onChange={(e) => handleChange && handleChange(e.target.checked)}
        onClick={(e) => props.onClick && props.onClick(e)}
      />
      <span className={styles.indicator}>
        <svg viewBox='0 0 15 15'>
          <polyline points='4.2,8.2 6.6,10.5 12.2,3.2'></polyline>
        </svg>
      </span>
      {/* we don't want to click on label if it's highlighted */}
      <span
        className={styles.display}
        onClick={(e) => text?.props?.highlighted && e.preventDefault()}
      >
        {text}
      </span>
    </div>
  );
  return (
    <label
      className={classNames(
        styles.checkboxField,
        disabled && styles.disabled,
        className
      )}
    >
      {disabled && disabledMessage ? (
        <OverlayTrigger
          placement='bottom'
          overlay={<Tooltip>{disabledMessage}</Tooltip>}
        >
          {checkbox}
        </OverlayTrigger>
      ) : (
        checkbox
      )}
    </label>
  );
});
