import * as Icons from '../../../Icons';
import { LetterSpacingIcon, LineHeightIcon } from '../../../Icons';

import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import {
  DropdownField,
  FColorPicker,
  NumberInput,
  PaddingInput,
  PropertyLabel,
  RadioButtonGroup,
  TextField
} from '../../../Core';
import { memo } from 'react';
import {
  assetUpdateOperations,
  CTA_PADDING_PROPS,
  elementOperation,
  FONT_STYLE_ATTRIBUTES,
  FONT_WEIGHT_OPTIONS,
  getAsset,
  globalUpdateOperations,
  level1UpdateOperations,
  level2UpdateOperations
} from '../../../../utils/themes';
import { objectPick } from '../../../../utils/core';

import { calculateOverrideObjects } from '../../utils';
import FontPicker from '../../../Core/FontPicker';
import produce from 'immer';
import { resetRichTextStyles } from '../../../../utils/step';
import {
  fieldTypeNameMap,
  TYPE_BUTTON,
  TYPE_TEXT
} from '../../../../utils/elements';
import { Viewport } from '../../../RenderingEngine/GridInGrid/engine/viewports';

function FontStyleSection({
  mode,
  viewport,
  level2,
  level1,
  theme,
  handleUpdates,
  baseProps,
  overrideProps,
  baseStyle,
  overrideStyle = {},
  elementType = '',
  richTextMixedStyles,
  labelCustomData = () => {},
  handleCustomStyleChange,
  customValues
}: any) {
  const { result: props } = calculateOverrideObjects(baseProps, overrideProps);
  const { result: elementStyle } = calculateOverrideObjects(
    baseStyle,
    overrideStyle
  );
  const asset = getAsset(theme, elementType, (props as any).source_asset);

  function isOverrideStyle(styles: any) {
    if (
      styles.some(
        (style: any) =>
          richTextMixedStyles && richTextMixedStyles.includes(style)
      )
    )
      return false;
    return styles.some((style: any) => style in overrideStyle);
  }

  function handleStyleChange(styleUpdate: any) {
    let same = true;
    Object.entries(styleUpdate).forEach(([styleKey, styleVal]) => {
      if (elementStyle[styleKey] !== styleVal) same = false;
    });
    if (!same) handleUpdates([elementOperation({ viewport, styleUpdate })]);
  }

  function getAssetUpdateOps(props: any) {
    let assetOperations = [];
    // Only account for special rich text logic if in rich text editor
    if (richTextMixedStyles && asset) {
      const resetTextFormatted = resetRichTextStyles(
        asset.properties.text_formatted,
        props
      );
      assetOperations = assetUpdateOperations({
        viewport,
        elementType,
        asset,
        newProps: {
          text: asset.properties.text,
          text_formatted: resetTextFormatted
        }
      });
    }
    return assetOperations;
  }

  function labelData(...props: string[]) {
    const newStyle = objectPick(elementStyle, props);
    const key = viewport === Viewport.Mobile ? 'Desktop' : 'Theme';
    return {
      mode,
      highlighted: isOverrideStyle(props),
      themeOperations: {
        instance: {
          label: `Reset to ${key}`,
          operation: () => {
            handleUpdates([elementOperation({ viewport, styleReset: props })]);
          }
        },
        ...(mode === 'builder' && {
          global: {
            label: 'Global styles',
            operation: () => {
              handleUpdates([
                ...globalUpdateOperations({
                  viewport,
                  newStyle,
                  asset,
                  elementType,
                  level2: level2.type
                }),
                ...getAssetUpdateOps(props),
                elementOperation({ viewport, styleReset: props })
              ]);
            }
          },
          level2: {
            label: level2.label,
            operation: () => {
              handleUpdates([
                ...level2UpdateOperations({
                  viewport,
                  newStyle,
                  asset,
                  elementType,
                  level2: level2.type
                }),
                ...getAssetUpdateOps(props),
                elementOperation({ viewport, styleReset: props })
              ]);
            }
          },
          ...(level1 && {
            level1: {
              label: fieldTypeNameMap[level1],
              operation: () => {
                handleUpdates(
                  level1UpdateOperations({
                    viewport,
                    newStyle,
                    asset,
                    elementType,
                    level2: level2.type,
                    level1
                  })
                );
              }
            }
          }),
          ...(asset && {
            asset: {
              label: asset.key,
              operation: () => {
                // Rich text is stored in the text_formatted object as mobile_font_* attributes.
                // The font_* properties we're working with here must be mapped back for mobile rich text styles
                // Assume we're in the builder since that's the only place where this operation can happen
                const prefix = viewport === Viewport.Mobile ? 'mobile_' : '';
                const transformedStyles = Object.entries(newStyle).reduce(
                  (styles, [styleKey, styleVal]) => ({
                    ...styles,
                    [`${prefix}${styleKey}`]: styleVal
                  }),
                  {}
                );
                const newTextFormatted = produce(
                  asset.properties.text_formatted ?? [],
                  (draft: any) => {
                    draft.forEach((op: any) => {
                      op.attributes = {
                        ...op.attributes,
                        ...transformedStyles
                      };
                    });
                  }
                );
                // Update asset style and reset placed asset style
                handleUpdates([
                  ...assetUpdateOperations({
                    viewport,
                    elementType,
                    asset,
                    newProps: {
                      text: asset.properties.text,
                      text_formatted: newTextFormatted
                    }
                  }),
                  elementOperation({ viewport, styleReset: props })
                ]);
              }
            }
          })
        })
      }
    };
  }

  return (
    <>
      {elementType === 'file_upload' && (
        <Row>
          <Col sm='3'>
            <PropertyLabel
              label='Padding'
              centered={false}
              {...labelCustomData(...CTA_PADDING_PROPS)}
            />
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <PaddingInput
              value={{
                top: elementStyle.cta_padding_top,
                right: elementStyle.cta_padding_right,
                bottom: elementStyle.cta_padding_bottom,
                left: elementStyle.cta_padding_left
              }}
              onComplete={(newPadding: any) =>
                handleCustomStyleChange({
                  cta_padding_top: newPadding.top,
                  cta_padding_right: newPadding.right,
                  cta_padding_bottom: newPadding.bottom,
                  cta_padding_left: newPadding.left
                })
              }
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col sm='3'>
          <PropertyLabel label='Font' {...labelData('font_family')} />
        </Col>
        <Col>
          <FontPicker
            selected={elementStyle.font_family}
            onChange={(event: any) =>
              handleStyleChange({ font_family: event.target.value })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col sm='3'>
          <PropertyLabel label='Weight' {...labelData('font_weight')} />
        </Col>
        <Col>
          <DropdownField
            selected={elementStyle.font_weight}
            onChange={(e: any) =>
              handleStyleChange({ font_weight: parseInt(e.target.value) })
            }
            options={FONT_WEIGHT_OPTIONS}
          />
        </Col>
      </Row>
      <Row>
        <Col sm='3'>
          <PropertyLabel label='Size' {...labelData('font_size')} />
        </Col>
        <Col>
          <NumberInput
            required
            min={0}
            value={elementStyle.font_size}
            onComplete={({ value }: any) => {
              handleStyleChange({ font_size: value });
            }}
            units={['px']}
            triggerCleanUp
          />
        </Col>
      </Row>
      <Row>
        <Col sm='3'>
          <PropertyLabel label='Color' {...labelData('font_color')} />
        </Col>
        <Col>
          <FColorPicker
            value={elementStyle.font_color}
            onChange={(color: any) => {
              handleStyleChange({ font_color: color });
            }}
          />
        </Col>
      </Row>
      {/* TODO: add support for text elements, once the width control has been added to text elements */}
      {/* text_align is currently only implemented for button & button_group */}
      {(elementType === TYPE_BUTTON || elementType === 'button_group') && (
        <Row>
          <Col sm='3'>
            <PropertyLabel label='Align' {...labelCustomData('text_align')} />
          </Col>
          <Col>
            <RadioButtonGroup
              selected={customValues.text_align}
              name='text-editor-text-alignment'
              onChange={(value: any) => {
                handleCustomStyleChange({ text_align: value });
              }}
              options={[
                { value: 'start', display: <Icons.TextLeft /> },
                { value: 'center', display: <Icons.TextCenter /> },
                { value: 'end', display: <Icons.TextRight /> }
              ]}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col sm='3'>
          <PropertyLabel
            label='Style'
            {...labelData(...FONT_STYLE_ATTRIBUTES)}
          />
        </Col>
        <Col>
          <RadioButtonGroup
            selected={elementStyle.font_italic}
            name='text-editor-italic-toggle'
            onChange={(value: any) =>
              handleStyleChange({ font_italic: value === 'true' })
            }
            options={[
              { value: false, display: <Icons.CursorIcon /> },
              { value: true, display: <Icons.ItalicIcon /> }
            ]}
          />
        </Col>
        <Col>
          <RadioButtonGroup
            selected={
              elementStyle.font_underline
                ? 'underline'
                : elementStyle.font_strike
                ? 'strike-through'
                : 'no-style'
            }
            name='text-editor-line-toggle'
            onChange={(value: any) =>
              handleStyleChange({
                font_strike: value === 'strike-through',
                font_underline: value === 'underline'
              })
            }
            options={[
              { value: 'no-style', display: <Icons.TextNoStyleIcon /> },
              { value: 'underline', display: <Icons.TextUnderlineIcon /> },
              {
                value: 'strike-through',
                display: <Icons.TextStrikeThroughIcon />
              }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col sm='3'>
          <PropertyLabel label='Case' {...labelData('text_transform')} />
        </Col>
        <Col>
          <RadioButtonGroup
            selected={elementStyle.text_transform}
            name='text-editor-case-toggle'
            onChange={(value: any) =>
              handleStyleChange({ text_transform: value })
            }
            options={[
              { value: 'none', display: <Icons.CaseNormalIcon /> },
              { value: 'uppercase', display: <Icons.CaseUpperIcon /> },
              { value: 'lowercase', display: <Icons.CaseLowerIcon /> },
              { value: 'capitalize', display: <Icons.CaseCapitalIcon /> }
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col sm='2'>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='line-height-tooltip'>Line Height</Tooltip>}
          >
            <div>
              <PropertyLabel
                label={<LineHeightIcon />}
                svgLabel
                {...labelCustomData('line_height')}
              />
            </div>
          </OverlayTrigger>
        </Col>
        <Col>
          <NumberInput
            min={0}
            value={customValues.line_height}
            onComplete={({ value }: any) =>
              handleCustomStyleChange({ line_height: value })
            }
            units={['px']}
            triggerCleanUp
          />
        </Col>
        <Col sm='2' />
        <Col sm='2'>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='line-height-tooltip'>Letter Spacing</Tooltip>}
          >
            <div>
              <PropertyLabel
                label={<LetterSpacingIcon />}
                svgLabel
                {...labelData('letter_spacing')}
              />
            </div>
          </OverlayTrigger>
        </Col>
        <Col>
          <NumberInput
            min={0}
            value={elementStyle.letter_spacing}
            onComplete={({ value }: any) => {
              handleStyleChange({ letter_spacing: value });
            }}
            units={['px']}
            triggerCleanUp
          />
        </Col>
      </Row>
      {elementType === TYPE_TEXT && (
        <Row>
          <Col sm='3'>
            <PropertyLabel label='Link' {...labelData('font_link')} />
          </Col>
          <Col>
            <TextField
              placeholder='https://feathery.io'
              value={elementStyle.font_link}
              onComplete={(link: any) =>
                handleStyleChange({
                  font_link: link,
                  font_color: '2C80FFFF',
                  font_underline: true
                })
              }
              triggerCleanUp
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export default memo(FontStyleSection);
