import classNames from 'classnames';
import styles from '../styles.module.scss';
import { Card, Tag } from '../../Core';
import { CheckIcon } from '../../Icons';
import { INTEGRATIONS } from '../types';
import useIntegrations from '../useIntegrations';

function IntegrationCard({
  className,
  integrationType,
  panelId,
  onClick,
  logo,
  title,
  description,
  tags,
  isSelected
}: any) {
  // Global/cached integration settings
  const integration = useIntegrations({ type: integrationType, panelId });

  return (
    <Card
      className={classNames(
        className,
        styles.integrationCard,
        integrationType && styles.hover,
        isSelected && styles.integrationCardActive
      )}
      onClick={onClick}
    >
      <div className={styles.integrationCardBody}>
        <div className={styles.integrationLogoColumn}>
          <div className={styles.logoContainer}>{logo}</div>
        </div>
        <div className={styles.integrationInfoColumn}>
          <span className={styles.integrationTitleText}>{title}</span>
          <span className={styles.integrationDescriptionText}>
            {description}
          </span>
          <div className={styles.tagsContainer}>
            {tags.map((tag: any) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </div>
        </div>
        {![INTEGRATIONS.ZAPIER, INTEGRATIONS.DOCUMENTS].includes(
          integrationType
        ) && (
          <div className={styles.integrationStatusColumn}>
            {integration ? (
              <div className={styles.connectedStatus}>
                <CheckIcon height={24} width={24} /> Connected
              </div>
            ) : (
              <div className={styles.disconnectedStatus}>Not Connected</div>
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

export default IntegrationCard;
