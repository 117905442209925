import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * Wraps content in a tooltip only if the tooltip is needed
 * (typically if the content length is longer and would be cut off.)
 */
export default function DynamicTextTooltip({
  text = '',
  placement = 'top',

  // length of tooltip text beyond which we need to add the tooltip
  maxLength = 12,

  allowShow = true,
  children
}: any) {
  const [showTooltip, setShowTooltip] = useState(false);

  return text.length > maxLength ? (
    <OverlayTrigger
      placement={placement}
      onToggle={(show) => setShowTooltip(allowShow && show)}
      show={allowShow && showTooltip}
      overlay={<Tooltip id='inline-tooltip'>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  ) : (
    <>{children}</>
  );
}
