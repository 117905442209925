import { memo } from 'react';

function Delete({ onClick = (e: any) => {}, label = 'Delete' }) {
  return (
    <button onClick={onClick} className='delete-button'>
      {label}
    </button>
  );
}

export default memo(Delete);
