import { ErrorMarkerIcon } from '../../../components/Icons';
import classNames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  error?: string;
};

const ErrorCard = ({ error }: Props) => {
  return (
    <div className={classNames(styles.infoCard, styles.white, styles.flex)}>
      <div
        className={classNames(
          styles.icon,
          styles.errorIcon,
          styles.error,
          styles.white
        )}
      >
        <ErrorMarkerIcon width={30} height={30} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>Error</div>
        <div className={styles.text}>
          {error || 'Something went wrong. Please contact support.'}
        </div>
      </div>
    </div>
  );
};

export default ErrorCard;
