import classNames from 'classnames';

import useFeatheryRedux from '../../../redux';
import styles from './styles.module.scss';

const CopyableText = ({
  text,
  toastMessage
}: {
  text: string;
  toastMessage?: string;
}) => {
  const {
    toasts: { addToast }
  } = useFeatheryRedux();

  return (
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>
      <button
        className={classNames('fy-btn-primary', styles.copyButton)}
        onClick={() => {
          navigator.clipboard.writeText(text);
          addToast({
            text: (
              <>
                <strong>{toastMessage || 'Text copied to clipboard.'}</strong>
              </>
            )
          });
        }}
      >
        Copy
      </button>
    </div>
  );
};

export default CopyableText;
