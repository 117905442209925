import { useEffect, useState } from 'react';
import { TextField } from '../../../Core';
import { InfoIcon, TrashIcon } from '../../../Icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './styles.module.scss';

export type KeyComponentProps = {
  value: string;
  index: number;
  onComplete?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
};

const BasicKeyComponent = ({
  value,
  onComplete = () => {},
  disabled = false
}: KeyComponentProps) => {
  return (
    <TextField
      className={styles.textField}
      placeholder='Key'
      value={value}
      onComplete={onComplete}
      disabled={disabled}
    />
  );
};

export interface ValueComponentProps
  extends React.ComponentPropsWithoutRef<typeof TextField> {
  keyValue?: string;
  value: string;
  index: number;
  onComplete?: (value: string) => void;
  disabled?: boolean;
  error?: boolean;
}

export const BasicValueComponent = ({
  value,
  onComplete = () => {},
  disabled = false,
  error = false,
  ...props
}: ValueComponentProps) => {
  return (
    <TextField
      className={styles.textField}
      placeholder='Value'
      value={value}
      onComplete={onComplete}
      disabled={disabled}
      error={error}
      errorMessage={''}
      {...props}
    />
  );
};

type KeyValueInputProps = {
  value: any;
  onChange: (value: any) => void;
  addButtonText?: string;
  readOnlyValues?: any;
  readOnlyInfo?: string;
  editableKeys?: boolean;
  allowEmptyKeys?: boolean;
  hideAddButton?: boolean;
  hideDeleteButton?: boolean;
  KeyComponent?: React.ComponentType<any>;
  ValueComponent?: React.ComponentType<any>;
};

export const KeyValueInput = (props: KeyValueInputProps) => {
  const {
    value,
    onChange,
    addButtonText = 'Add',
    readOnlyValues,
    readOnlyInfo,
    allowEmptyKeys = false,
    editableKeys = true,
    hideAddButton = false,
    hideDeleteButton = false,
    KeyComponent = BasicKeyComponent,
    ValueComponent = BasicValueComponent
  } = props;

  const hasExistingHeaders = Object.keys(value).length > 0;
  const [keyValuePairs, setKeyValuePairs] = useState<any[]>(
    hasExistingHeaders ? Object.entries(value) : [['', '']]
  );

  useEffect(() => {
    const hasExistingHeaders = Object.keys(value).length > 0;
    setKeyValuePairs(hasExistingHeaders ? Object.entries(value) : [['', '']]);
  }, [value]);

  const handleAdd = () => {
    setKeyValuePairs((pairs) => [...pairs, ['', '']]);
  };

  const handleChange = (
    index: number,
    { key, value }: { key?: string; value?: string } = {}
  ) => {
    setKeyValuePairs((pairs) => {
      if (key) pairs[index][0] = key;
      if (value) pairs[index][1] = value;
      return [...pairs];
    });

    onChange(Object.fromEntries(keyValuePairs.filter(([key]) => !!key)));
  };

  const handleDelete = (index: number) => {
    setKeyValuePairs((pairs) => {
      pairs.splice(index, 1);

      if (pairs.length === 0) {
        return [['', '']];
      }

      onChange(Object.fromEntries(pairs.filter(([key]) => !!key)));

      return [...pairs];
    });
  };

  return (
    <div className={styles.keyValueInputContainer}>
      {Object.entries(readOnlyValues || {}).map(([key, value]: any, index) => (
        <div key={`${key}-${value}-${index}`} className={styles.keyValueInput}>
          <KeyComponent index={index} value={key} disabled />
          <div>=</div>
          <ValueComponent index={index} value={value} disabled />
          {readOnlyInfo && (
            <OverlayTrigger
              placement='left'
              overlay={
                <Tooltip style={{ zIndex: '999999999' }}>
                  {readOnlyInfo}
                </Tooltip>
              }
            >
              <div>
                <InfoIcon width={16} height={16} color='var(--grey-80)' />
              </div>
            </OverlayTrigger>
          )}
        </div>
      ))}
      {keyValuePairs.map(([key, value], index) => (
        <div key={`${key}-${value}-${index}`} className={styles.keyValueInput}>
          <KeyComponent
            value={key}
            index={index}
            disabled={!editableKeys}
            onComplete={(key: any) =>
              editableKeys && handleChange(index, { key })
            }
          />
          <div>=</div>
          <ValueComponent
            keyValue={key}
            value={value}
            index={index}
            onComplete={(value: any) => handleChange(index, { value })}
            disabled={!allowEmptyKeys && !key}
          />
          <div>
            {!hideDeleteButton && (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleDelete(index)}
              >
                <TrashIcon width={16} height={16} />
              </div>
            )}
          </div>
        </div>
      ))}
      <div className={styles.keyValueInputActions}>
        {!hideAddButton && (
          <button
            className={styles.keyValueAddButton}
            onClick={(e) => {
              e.preventDefault();
              handleAdd();
            }}
          >
            + {addButtonText}
          </button>
        )}
      </div>
    </div>
  );
};
