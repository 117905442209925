import styles from './styles.module.scss';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

function Switch({ containerClassName, ...props }: any) {
  return (
    <div className={classNames(styles.fSwitch, containerClassName)}>
      <Form.Switch {...props} />
    </div>
  );
}

export default Switch;
