import classNames from 'classnames';
import { memo, useMemo } from 'react';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styles from './styles.module.scss';

const getClassNames = (overrides: any = {}) => {
  const cn = classNames;
  const o = overrides;

  return {
    className: cn(styles.multiFieldDropdown, o.className),
    control: (state: any) =>
      cn(
        styles.multiFieldControl,
        {
          [styles.multiFieldControlHovered]: state.isHovered,
          [styles.multiFieldControlFocused]: state.isFocused
        },
        typeof o.multiFieldControl === 'function'
          ? o.multiFieldControl(state)
          : o.multiFieldControl
      ),
    container: () => cn(styles.multiFieldContainer, o.multiFieldContainer),
    valueContainer: () =>
      cn(styles.multiFieldValueContainer, o.multiFieldValueContainer),
    multiValue: () => cn(styles.multiFieldMultiValue, o.multiFieldMultiValue),
    multiValueLabel: () =>
      cn(styles.multiFieldMultiValueLabel, o.multiFieldMultiValueLabel),
    multiValueRemove: (state: any) =>
      cn(
        styles.multiFieldMultiValueRemove,
        {
          [styles.multiFieldMultiValueRemoveHovered]: state.isHovered
        },
        o.multiFieldMultiValueRemove
      ),
    menu: () => cn(styles.multiFieldMenu, o.multiFieldMenu),
    menuList: () => cn(styles.multiFieldMenuList, o.multiFieldMenuList),
    clearIndicator: () =>
      cn(styles.multiFieldClearIndicator, o.multiFieldClearIndicator),
    indicatorSeparator: () =>
      cn(styles.multiFieldIndicatorSeparator, o.multiFieldIndicatorSeparator),
    indicatorsContainer: () =>
      cn(styles.multiFieldIndicatorsContainer, o.multiFieldIndicatorsContainer),
    dropdownIndicator: () =>
      cn(styles.multiFieldDropdownIndicator, o.multiFieldDropdownIndicator),
    inputContainer: () => cn(o.multiFieldInputContainer),
    input: () => cn(o.multiFieldInput),
    placeholder: () => cn(o.multiFieldPlaceholder)
  };
};

const DropdownMultiField = ({
  selected = [],
  options = [],
  placeholder,
  required = false,
  disabled = false,
  hideCaret = false,
  isMulti = true,
  onChange = () => {},
  creatable = false,
  classNames: _classNames = {},
  className: _className = '',
  ...props
}: any) => {
  const optionObjects = useMemo(
    () =>
      options.map((o: any) =>
        typeof o === 'object' ? o : { value: o, label: o }
      ),
    [options]
  );

  // SVG component for the custom dropdown indicator
  const CustomDropdownIndicator = () => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 10 6'
      width='10px'
      height='6px'
      style={{ marginRight: '10px' }}
    >
      <path
        d='M0 0.776454L0.970744 0L5 4.2094L9.02926 0L10 0.776454L5 6L0 0.776454Z'
        fill='black'
      />
    </svg>
  );

  // Custom components for React Select
  const customComponents = {
    DropdownIndicator: CustomDropdownIndicator
  };

  const { className = '', ...classNameOverrides } = getClassNames({
    ...(_classNames || {}),
    className: _className ?? ''
  });

  let value;
  if (optionObjects.length) {
    if (isMulti) {
      value = optionObjects.filter((o: any) => selected.includes(o.value));
    } else value = optionObjects.filter((o: any) => o.value === selected);
  } else if (isMulti) {
    value = selected.map((s: string) => ({ value: s, label: s }));
  } else value = [{ value: selected, label: selected }];

  const selectProps = {
    classNamePrefix: 'multi-field',
    className,
    classNames: classNameOverrides,
    styles: {
      dropdownIndicator: (baseStyles: any) => ({
        ...baseStyles,
        display: hideCaret ? 'none' : 'block'
      })
    },
    value: value,
    required,
    isDisabled: disabled,
    onChange,
    options: optionObjects,
    isMulti,
    placeholder,
    components: hideCaret ? {} : customComponents,
    ...props
  };

  // Ensure the ordering of the selected values remains the same after filtering the options
  if (optionObjects.length && isMulti) {
    selectProps.value = selected.map((s: string) =>
      selectProps.value.find((v: any) => v.value === s)
    );
  }

  return creatable ? (
    <CreatableSelect {...selectProps} />
  ) : (
    <Select {...selectProps} />
  );
};

export default memo(DropdownMultiField);
