import { ComponentProps, memo, useState } from 'react';

import Dialog from '../../Dialog';

import FieldSelector, { SelectedFieldData } from './FieldSelector';
import { DynamicTextTooltip } from '../../Core';
import classNames from 'classnames';
import useFieldKey from '../../../utils/useFieldKey';
import ruleStyles from '../../NavigationRules/styles.module.scss';

const DEFAULT_ONSELECT = ({ selectId, selectType }: SelectedFieldData) => {};

interface Props extends Omit<ComponentProps<'button'>, 'onSelect'> {
  selectId?: string;
  selectType?: string | '' | 'servar' | 'hidden';
  currentStepId?: string;
  placeholder?: string;
  disableSelect?: boolean;
  onSelect?: (data: SelectedFieldData) => void;
  inFormBuilder?: boolean;
  onlyHiddenFields?: boolean;
  error?: boolean;
  disabled?: boolean;
  className?: string;
  size?: 'xlg' | 'lg' | 'md' | 'sm';
  excludeServarTypes?: string[];
  includeServarTypes?: string[] | null;
  includeReserved?: boolean;
  labelOverride?: string;
}

const FieldSelectorWithModal = ({
  selectId = '',
  selectType = '' as string | '' | 'servar' | 'hidden',
  currentStepId = '',
  placeholder = 'Field',
  disableSelect = false,
  onSelect = DEFAULT_ONSELECT,
  // If true, only leverage field state from the current form in production
  inFormBuilder = false,
  onlyHiddenFields = false,
  error = false,
  className = '',
  size = 'xlg',
  excludeServarTypes = [],
  includeServarTypes = null,
  includeReserved = false,
  labelOverride = '',
  ...props
}: Props) => {
  const displaySelectKey = useFieldKey(inFormBuilder)(selectId);

  const [show, setShow] = useState(false);

  return (
    <>
      <Dialog
        isOpen={show}
        onClose={() => {
          setShow(false);
        }}
        shouldShowCloseIcon
        title={
          labelOverride ||
          (onlyHiddenFields ? 'Select Hidden Field' : 'Select Field')
        }
        size={size}
      >
        <FieldSelector
          selectId={selectId}
          selectType={selectType}
          currentStepId={currentStepId}
          show={show}
          setShow={setShow}
          disableSelect={disableSelect}
          onSelect={onSelect}
          inFormBuilder={inFormBuilder}
          onlyHiddenFields={onlyHiddenFields}
          excludeServarTypes={excludeServarTypes}
          includeServarTypes={includeServarTypes}
          includeReserved={includeReserved}
        />
      </Dialog>
      <DynamicTextTooltip text={displaySelectKey} maxLength={12}>
        <button
          disabled={props.disabled}
          className={classNames(
            ruleStyles.ruleTarget,
            ruleStyles.fieldTarget,
            displaySelectKey && ruleStyles.selected,
            error && ruleStyles.fieldTargetError,
            className
          )}
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}
          {...props}
        >
          <span className={ruleStyles.fieldSpan}>
            {displaySelectKey || placeholder}
          </span>
        </button>
      </DynamicTextTooltip>
    </>
  );
};

export default memo(FieldSelectorWithModal);
