import { useHistory, useParams } from 'react-router-dom';
import LogicPageComponent from './LogicPageComponent';
import FormLogicRulePage from '../FormLogicRulePage';
import { Tab, Tabs } from 'react-bootstrap';
import APIConnectorPage from '../APIConnectorPage';
import styles from '../styles.module.scss';

export default function FormLogicPage() {
  const { formId, ruleId, tab } =
    useParams<{ ruleId: string; formId: string; tab: string }>();
  const history = useHistory();

  const isConnectorsTab = ruleId === 'connectors' || tab === 'connectors';

  const setTab = (eventKey: string | null) => {
    if (eventKey === 'connectors') {
      if (ruleId) {
        history.push(`/forms/${formId}/logic/${ruleId}/connectors`);
      } else {
        history.push(`/forms/${formId}/logic/connectors`);
      }
    } else {
      if (ruleId && ruleId !== 'connectors') {
        history.push(`/forms/${formId}/logic/${ruleId}`);
      } else {
        history.push(`/forms/${formId}/logic`);
      }
    }
  };

  return (
    <div className={styles.tabs} style={{ overflowY: 'scroll' }}>
      <Tabs
        activeKey={isConnectorsTab ? 'connectors' : 'rules'}
        onSelect={setTab}
        fill={false}
      >
        <Tab eventKey='rules' title='Rules'>
          {ruleId ? <FormLogicRulePage /> : <LogicPageComponent />}
        </Tab>
        <Tab eventKey='connectors' title='API Connectors'>
          <APIConnectorPage formId={formId} />
        </Tab>
      </Tabs>
    </div>
  );
}
