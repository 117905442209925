import { useAppSelector } from '../../../hooks';
import {
  DropdownField,
  InfoCard,
  InlineTooltip,
  WarningCard,
  YesNoSwitch
} from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from '../styles.module.scss';
import {
  BorderlessEyeIcon,
  DownloadIcon,
  RefreshIcon,
  TrashIcon
} from '../../../components/Icons';
import { openTab } from '../../../utils/domOperations';
import { downloadTranslationFile } from '../../../components/Modals/UpdateTranslationModal/utils';
import classNames from 'classnames';
import { produce } from 'immer';
import { useHostedURL } from '../../../components/Core/Button/Share/ShareFormOverlay';
import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { PUBLISH_STATUS } from '../../../redux/utils';
import { UpdateTranslationModal } from '../../../components/Modals';

const InternationalSection = ({
  title,
  formData,
  handleValueChange,
  setPublishStatus,
  translations,
  setTranslations,
  updatedTranslations,
  setUpdatedTranslations
}: any) => {
  const { formId } = useParams<{ formId: string }>();
  const org = useAppSelector((state) => state.accounts.organization);
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const getHostedUrl = useHostedURL(org, panel);

  const languageCodes = useAppSelector(
    (state) => state.integrations.languageCodes
  );
  const codeDisplayMap = useMemo(
    () =>
      languageCodes.reduce((curCodes, { code, name }) => {
        curCodes[code] = name;
        return curCodes;
      }, {} as Record<string, string>),
    [languageCodes]
  );

  const dropdownOptions = useMemo(
    () =>
      languageCodes
        .map(({ code, name }) => ({ display: name, value: code }))
        .sort(({ display: d1 }, { display: d2 }) => (d1 < d2 ? -1 : 1)),
    [languageCodes]
  );

  const updateTranslations = (newTranslations: any, language: string) => {
    setTranslations(newTranslations);
    setUpdatedTranslations({ ...updatedTranslations, [language]: true });
    setPublishStatus(PUBLISH_STATUS.ACTIVE);
  };
  const [editLanguage, setEditLanguage] = useState<undefined | string>();

  const [availableTranslations, missingCodes] = useMemo(() => {
    const langCodes = [
      ...new Set(
        Object.values(translations).flatMap((variants: any) =>
          Object.keys(variants)
        )
      )
    ];
    const missingCodes = new Set(
      Object.values(translations).flatMap((variants: any) => {
        const variantKeys = new Set(Object.keys(variants));
        return langCodes.filter((langCode) => !variantKeys.has(langCode));
      })
    );
    return [
      langCodes.sort((lang1, lang2) =>
        codeDisplayMap[lang1] < codeDisplayMap[lang2] ? -1 : 1
      ),
      [...missingCodes]
    ];
  }, [translations]);
  const err = useMemo(() => {
    if (missingCodes.length === 0) return '';
    else {
      const remainder = missingCodes.length - 1;
      let err = `'${codeDisplayMap[missingCodes[0]]}'`;
      if (remainder === 0) err += ' is';
      else err += ` + ${remainder} others are`;
      return `${err} missing translations.`;
    }
  }, [missingCodes, codeDisplayMap]);

  return (
    <SettingsContainer title={title}>
      <UpdateTranslationModal
        editLanguage={editLanguage}
        translations={translations}
        hide={() => setEditLanguage(undefined)}
        onTranslationUpdate={(
          language: string,
          newLanguageTranslations: Record<string, string>
        ) => {
          const newTranslations = produce(translations, (draft: any) => {
            Object.entries(draft).forEach(
              ([phrase, variants]: [string, any]) => {
                if (phrase in newLanguageTranslations) {
                  variants[language] = newLanguageTranslations[phrase];
                } else {
                  delete variants[language];
                }
              }
            );
          });
          updateTranslations(newTranslations, language);
        }}
      />
      <div className='field-group'>
        <Form.Label>
          Right to Left
          <InlineTooltip
            text='The form flow is displayed from right to left (RTL) rather than from left to right'
            inline
          />
        </Form.Label>
        <YesNoSwitch
          id='rtl'
          checked={formData.right_to_left ?? false}
          onChange={(e: any) =>
            handleValueChange('right_to_left')(e.target.checked)
          }
        />
      </div>

      <div className={styles.translationsInitialInstructions}>
        To show your form in different languages, download the{' '}
        <b>Default Translation</b> document and add translations for each
        phrase.{' '}
        <a
          href='https://docs.feathery.io/platform/international-forms/translate-forms'
          target='_blank'
          rel='noreferrer'
        >
          Learn more
        </a>
      </div>
      {!formData.default_language && (
        <InfoCard text="If the default form language is not set, it will be treated as a fallback. Other translations will be prioritized if they match the user's available languages." />
      )}
      {err && <WarningCard text={err} />}
      <div className={styles.translationsContainer}>
        <div key='default' className={styles.translationContainer}>
          Default Translation
          <div>
            <DropdownField
              selected={formData.default_language ?? ''}
              onChange={(e: any) =>
                handleValueChange('default_language')(e.target.value)
              }
              options={[{ display: 'None', value: '' }, ...dropdownOptions]}
            />
          </div>
          <div className={styles.iconsContainer}>
            <OverlayTrigger
              placement='bottom'
              overlay={<Tooltip id='default-preview'>Form Preview</Tooltip>}
            >
              <div className={styles.iconContainer}>
                <BorderlessEyeIcon
                  onClick={() => openTab(getHostedUrl('test'))}
                />
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip id='default-download'>Download Translation</Tooltip>
              }
            >
              <div className={styles.iconContainer}>
                <DownloadIcon
                  onClick={() =>
                    downloadTranslationFile(panel.key, translations)
                  }
                />
              </div>
            </OverlayTrigger>
            {availableTranslations.length > 0 && (
              <div className={styles.spacer} />
            )}
          </div>
        </div>
        {availableTranslations.map((langCode) => (
          <div key={langCode} className={styles.translationContainer}>
            {codeDisplayMap[langCode]}
            <div className={styles.iconsContainer}>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='preview-translation'>
                    {updatedTranslations[langCode]
                      ? 'Save your settings to preview'
                      : 'Form Preview'}
                  </Tooltip>
                }
              >
                <div
                  className={classNames(
                    styles.iconContainer,
                    updatedTranslations[langCode] && styles.disabled
                  )}
                >
                  <BorderlessEyeIcon
                    onClick={() =>
                      openTab(getHostedUrl('test', { _locale: langCode }))
                    }
                    {...(updatedTranslations[langCode]
                      ? { color: '#bdc2ce' }
                      : {})}
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='download-translation'>
                    Download Translation
                  </Tooltip>
                }
              >
                <div className={styles.iconContainer}>
                  <DownloadIcon
                    onClick={() =>
                      downloadTranslationFile(panel.key, translations, langCode)
                    }
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='update-translation'>Update Translation</Tooltip>
                }
              >
                <div className={styles.iconContainer}>
                  <RefreshIcon
                    width={20}
                    height={20}
                    color='#414859'
                    onClick={() => setEditLanguage(langCode)}
                  />
                </div>
              </OverlayTrigger>
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip id='delete-translation'>Delete Translation</Tooltip>
                }
              >
                <div className={styles.iconContainer}>
                  <TrashIcon
                    height={20}
                    width={20}
                    onClick={() => {
                      const newTranslations = produce(
                        translations,
                        (draft: any) => {
                          Object.values(draft).forEach(
                            (variants: any) => delete variants[langCode]
                          );
                        }
                      );
                      updateTranslations(newTranslations, langCode);
                    }}
                  />
                </div>
              </OverlayTrigger>
            </div>
          </div>
        ))}
      </div>
      <div
        className={styles.addTranslationToggle}
        onClick={() => setEditLanguage('')}
      >
        Add New Translation
      </div>
    </SettingsContainer>
  );
};

export default InternationalSection;
