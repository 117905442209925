import classNames from 'classnames';
import styles from './styles.module.scss';
import { CloseIcon } from '../../Icons';
import { Negative, Positive } from '../../Core/Button';

type ConfirmWarningProps = {
  show: boolean;
  message?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmWarning = (props: ConfirmWarningProps) => {
  const {
    show = false,
    onConfirm = () => {},
    onCancel = () => {},
    message = 'Are you sure you want to exit? Unsaved changes could be lost.',
    cancelLabel = 'Cancel',
    confirmLabel = 'Continue'
  } = props;

  if (!show) {
    return null;
  }

  return (
    <div className={styles.confirmWarning}>
      <div
        className={classNames(
          styles.validationRuleDelete,
          styles.ruleDeleteCancel
        )}
        tabIndex={0}
        onClick={() => {
          onCancel();
        }}
      >
        <CloseIcon height={16} width={16} />
      </div>
      <div className={styles.body}>
        <div className={styles.title}>Are you sure?</div>
        <div className={styles.message}>{message}</div>
        <div className={styles.buttons}>
          <Negative
            title={cancelLabel}
            btnClass={styles.noButton}
            onClick={() => {
              onCancel();
            }}
          />
          <Positive
            title={confirmLabel}
            onClick={async () => {
              onConfirm();
            }}
          />
        </div>
      </div>
    </div>
  );
};
