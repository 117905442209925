import classNames from 'classnames';
import styles from '../styles.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AuthorizeOauthButton = ({
  authorize,
  hasToken,
  integrationEnabled,
  domain
}: any) => {
  const domainProvided = domain;
  const disabled = hasToken && integrationEnabled;
  const buttonComponent = (
    <button
      className={classNames(
        'btn-custom',
        styles.connectButton,
        disabled && styles.disabled,
        hasToken && styles.authorized
      )}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!disabled && domainProvided) authorize();
      }}
    >
      {hasToken ? 'Unauthorize' : 'Authorize'}
    </button>
  );
  return !domainProvided || disabled ? (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip id='inline-tooltip'>
          {!domainProvided
            ? 'Egnyte Domain name needs to be provided before authorization.'
            : 'This integration must first be disconnected before you can unauthorize.'}
        </Tooltip>
      }
    >
      {buttonComponent}
    </OverlayTrigger>
  ) : (
    buttonComponent
  );
};

export default AuthorizeOauthButton;
