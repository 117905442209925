import { memo, useState } from 'react';

import styles from './styles.module.scss';
import { CloseIcon, InfoIcon } from '../../Icons';

const InfoCard = ({ text }: any) => {
  const [closed, setClosed] = useState(false);

  return closed ? null : (
    <div className={styles.cardContainer}>
      <div className={styles.payloadContainer}>
        <div className={styles.iconContainer}>
          <InfoIcon height={20} width={20} />
        </div>
        {text}
      </div>
      <CloseIcon className={styles.closeIcon} onClick={() => setClosed(true)} />
    </div>
  );
};

export default memo(InfoCard);
