import { useAppSelector } from '../../../hooks';
import { TextField } from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import UploadMetaImage from '../components/UploadMetaImage';

const SEOSection = ({ title, formData, handleValueChange }: any) => {
  const org = useAppSelector((state) => state.accounts.organization);
  const seoEnabled = org && org.tier > 0;

  let seoTitle = (
    <TextField
      value={formData.seo_title ?? ''}
      onComplete={handleValueChange('seo_title')}
      disabled={!seoEnabled}
    />
  );
  let seoDescription = (
    <TextField
      type='textarea'
      value={formData.seo_description ?? ''}
      onComplete={handleValueChange('seo_description')}
      disabled={!seoEnabled}
    />
  );
  let seoMetaImage = <UploadMetaImage />;
  if (!seoEnabled) {
    seoTitle = (
      <OverlayTrigger
        overlay={
          <Tooltip id='seo-title'>
            SEO access is available on paid plans
          </Tooltip>
        }
      >
        {seoTitle}
      </OverlayTrigger>
    );
    seoDescription = (
      <OverlayTrigger
        overlay={
          <Tooltip id='seo-title'>
            SEO access is available on paid plans
          </Tooltip>
        }
      >
        {seoDescription}
      </OverlayTrigger>
    );
    seoMetaImage = (
      <OverlayTrigger
        overlay={
          <Tooltip id='seo-meta-image'>
            SEO access is available on paid plans
          </Tooltip>
        }
      >
        {seoMetaImage}
      </OverlayTrigger>
    );
  }

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <Form.Label>Title Tag</Form.Label>
        {seoTitle}
      </div>
      <div className='field-group'>
        <Form.Label>Meta Description</Form.Label>
        {seoDescription}
      </div>
      <div className='field-group'>
        <Form.Label>Meta Image</Form.Label>
        {seoMetaImage}
      </div>
    </SettingsContainer>
  );
};

export default SEOSection;
