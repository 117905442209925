import { useEffect, useState } from 'react';
import { PageProps } from './types';
import { NavBar } from '../components/NavBar';
import { Spinner } from 'react-bootstrap';
import { CheckCircleIcon, ErrorMarkerIcon } from '../../../Icons';
import { mapAPIConnectorToRaw } from '../utils';
import classNames from 'classnames';
import useFeatheryRedux from '../../../../redux';
import styles from './styles.module.scss';
import { JSONEditor } from '../../../JSONEditor';

export const TestRequestPage = (props: PageProps) => {
  const {
    state,
    onChange = () => {},
    onSubmit = () => {},
    onBack = () => {},
    setState = () => {},
    onComplete = () => {}
  } = props;

  const [error, setError] = useState<any>(null);
  const [response, setResponse] = useState<any>({});
  const [isFetching, setFetching] = useState(true);
  const { testCustomAPI } = useFeatheryRedux();

  useEffect(() => {
    const fetchResponse = async () => {
      try {
        const { status_code: statusCode, data } = await testCustomAPI({
          ...mapAPIConnectorToRaw(state),
          field_mapping: state.testParameters ?? {}
        });

        if (statusCode >= 300) {
          setResponse({ statusCode, data });
          setError('Retrieved an error when requesting the configured API.');
        } else {
          onChange('responseStructure', JSON.stringify(data, null, 2));
        }
      } catch (err: any) {
        if (err.name === 'APIError') {
          setError(err.message);
        } else {
          setError('Error 400');
        }
      } finally {
        setFetching(false);
      }
    };

    fetchResponse();
  }, []);

  const handleSubmit = () => {
    if (!isFetching && !error) {
      onSubmit();
    }
  };

  const handleSkip = () => {
    setState({
      responseStructure: '{}',
      responseMapping: {}
    });

    onComplete();
  };

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div
          className={styles.block}
          style={{ alignItems: 'center', justifyContent: 'center' }}
        >
          {isFetching && (
            <>
              <Spinner
                className={styles.loadingSpinner}
                style={{ height: '50px', width: '50px' }}
              />
              <div className={styles.alert} style={{ marginTop: '20px' }}>
                <div className={styles.title}>Testing your request.</div>
                <div className={styles.body}>
                  Do not close this, this may take a while.
                </div>
              </div>
            </>
          )}
          {!isFetching && error && (
            <>
              <ErrorMarkerIcon width={50} height={50} />
              <div
                className={classNames(styles.alert, styles.error)}
                style={{ marginTop: '20px' }}
              >
                <div className={styles.title}>
                  Error{response.statusCode ? `: ${response.statusCode}` : ''}
                </div>
                <div className={styles.body}>
                  Something went wrong! Please revisit your request
                  configuration and try again.
                  {response.data && ' Response payload below.'}
                </div>
              </div>
              {response.data && (
                <div
                  style={{
                    marginTop: '10px',
                    width: '100%'
                  }}
                  className={styles.field}
                >
                  <JSONEditor
                    height='250px'
                    code={JSON.stringify(response.data)}
                    readOnly
                  />
                </div>
              )}
            </>
          )}
          {!isFetching && !error && (
            <>
              <CheckCircleIcon width={50} height={50} />
              <div className={styles.alert} style={{ marginTop: '20px' }}>
                <div className={styles.title}>Success!</div>
                <div className={styles.body}>
                  We have successfully received a response from the configured
                  API. You can now proceed to the next step.
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <NavBar
        next
        back
        secondary
        text={{
          secondary: 'Skip'
        }}
        disabled={{
          next: isFetching || error
        }}
        onClick={(btn: string) => {
          if (btn === 'next') handleSubmit();
          else if (btn === 'back') onBack();
          else if (btn === 'secondary') handleSkip();
        }}
      />
    </div>
  );
};
