/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useFeatheryRedux from '../../../redux';

import '../../../style/dialog-form.css';
import { deepEquals, objectApply } from '../../../utils/core';
import { Neutral, Positive } from '../../Core/Button';
import Dialog from '../../Dialog';
import { DropdownField, TextField } from '../../Core';
import Label from '../../Dialog/Label';
import dialogStyles from '../../Dialog/styles.module.scss';
import { uniqifyKey } from '../../../utils/format';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import { useAppSelector } from '../../../hooks';
import TemplatePicker from './components/TemplatePicker';
import testIds from '../../../utils/testIds';
import ModalHeader from './components/ModalHeader';
import styles from './styles.module.scss';

const DEFAULT_FORM_STATE = {
  key: '',
  theme: '',
  template: ''
};

export default function PanelCreateModal({
  step,
  changeStep,
  newOrg,
  folder
}: any) {
  const history = useHistory();
  const {
    createPanel,
    getFields,
    getImages,
    getThemes,
    getPanelThemeAssetUse,
    getIntegrations,
    editOrganization,
    toasts: { addInfoToast }
  } = useFeatheryRedux();
  const isOpen = step !== 0;

  const themes = useAppSelector((s) => s.themes.themes);
  const panelKeys = useAppSelector(
    (state) => Object.values(state.panels.panels).map((p) => p.key),
    deepEquals
  );

  const [error, setError] = useState('');
  const [formData, setFormData] = useState(DEFAULT_FORM_STATE);

  const close = () => {
    if (newOrg) editOrganization({ new: false });
    changeStep(0);
    setError('');
    setFormData(DEFAULT_FORM_STATE);
  };

  const handleChange = (newData: any) => {
    setFormData((formData) => ({
      ...formData,
      ...newData
    }));
  };

  const handleSubmit = useCallback(async () => {
    const { key, theme } = formData;
    if (key.trim().length < 1) {
      setError('You need to enter a form name');
      return;
    }

    const payload = objectApply(formData, {
      key,
      folder: folder ?? '',
      theme: theme || null
    });
    try {
      const response = await createPanel(objectApply(formData, payload));
      // Creating the panel may have generated a new theme or copied images that needs to be fetched
      await Promise.all([
        getThemes(),
        getImages(),
        getPanelThemeAssetUse({ cached: false }),
        getIntegrations(),
        getFields(),
        getFields({ hidden: true })
      ]);
      close();
      history.push(`/forms/${response.id}`);
      if (!response.active) {
        // a form is created in an inactive state only when a plan limit has been hit
        addInfoToast(
          "Your created form is inactive because you're at your account limit."
        );
      }
    } catch (e: any) {
      setError(
        'duplicate-key-error' === e.code
          ? 'Form name already being used'
          : e.message
      );
    }
  }, [formData]);

  const { lockOutFlag, lockoutFunction } =
    useModalSubmissionLockout(handleSubmit);

  let content = null;
  if (step === 1) {
    const onSelect = (key: any) => {
      if (key === 'blank') key = '';

      const themeList = Object.values(themes).filter(
        (theme) => theme.key !== 'Default Guide Theme'
      );
      const newTheme =
        key === '' && themeList.length ? (themeList as any)[0].id : '';
      handleChange({
        template: key,
        theme: newTheme,
        key: key ? uniqifyKey(key, panelKeys) : ''
      });
      changeStep(2);
    };
    content = <TemplatePicker onSelect={onSelect} />;
  } else if (step === 2) {
    content = (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <Label>Name</Label>
        <TextField
          autoFocus
          value={formData.key}
          placeholder='My Form'
          onComplete={(key: any) => handleChange({ key })}
          className={dialogStyles.input}
        />
        <Label>Theme</Label>
        <DropdownField
          disabled={Boolean(formData.template)}
          className={dialogStyles.input}
          selected={formData.theme}
          onChange={(event: any) => handleChange({ theme: event.target.value })}
          options={[
            {
              value: '',
              display: formData.template
                ? `${formData.template} Theme`
                : '+ Create New Theme'
            },
            ...Object.values(themes).map(({ id, key }) => ({
              value: id,
              display: key
            }))
          ]}
        />
        <div className='dialog-form-action text-center'>
          <Neutral title='Back' onClick={() => changeStep(1)} />
          <Positive
            data-testid={testIds.createFormDialogSubmit}
            title='Create'
            lockoutOverride={lockOutFlag}
          />
        </div>
        {error && <div>{error}</div>}
      </form>
    );
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      title='Create New Form'
      header={
        step === 1
          ? (title: string) => {
              return (
                <div className={styles.dialogHeader}>
                  <h2>{title}</h2>
                  <ModalHeader />
                </div>
              );
            }
          : undefined
      }
      size={step === 2 ? 'xsm' : 'flush'}
    >
      {content}
    </Dialog>
  );
}
