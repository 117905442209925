import { CloseIcon } from '../../../../../../components/Icons';
import { useRuleBuilderState, useRuleBuilderUpdater } from '../../context';
import { RuleCondition } from '../RuleCondition';
import { ActionBar } from '../ActionBar';
import { RuleAction } from '../RuleAction';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ConfirmationModal } from '../../../ConfirmationModal';
import { useState } from 'react';

type RuleClauseProps = {
  isOnlyChild?: boolean;
  clause: IRuleClause;
  addClause?: () => void;
  mode?: RuleBuilderMode;
  conditional?: boolean;
};

export const RuleClause = ({
  clause,
  addClause,
  mode = 'view',
  conditional = false,
  isOnlyChild = false
}: RuleClauseProps) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const errors = useRuleBuilderState((s) => s.errors);
  const error = errors[clause.id]?.message;
  const isValidClause = !error;

  const { addActionToClause, addConditionToClause, removeClause } =
    useRuleBuilderUpdater((s) => ({
      addActionToClause: s.addActionToClause,
      addConditionToClause: s.addConditionToClause,
      removeClause: s.removeClause
    }));

  const { when, actions } = clause;

  const handleRemove = (force = false) => {
    if (!force) {
      setShowRemoveModal(true);
    } else {
      removeClause(clause.id);
    }
  };

  const Clause = (
    <div
      className={classNames(styles.ruleClauseContainer, {
        [styles.ruleClauseWhen]: !!when,
        [styles.viewMode]: mode === 'view',
        [styles.editMode]: mode === 'edit',
        [styles.error]:
          !isValidClause && (mode === 'view' || (!when && !actions.length))
      })}
    >
      <div className={styles.ruleClauseTitle}>
        <div className={styles.ruleClauseTitleText}></div>
        {mode === 'edit' && (
          <div className={styles.ruleClauseTitleActions}>
            <CloseIcon width={18} height={18} onClick={() => handleRemove()} />
          </div>
        )}
      </div>
      <div className={styles.ruleClauseContent}>
        {when && (
          <div className={styles.ruleClauseCondition}>
            <RuleCondition
              condition={when}
              mode={mode}
              isOnlyChild={isOnlyChild}
            />
          </div>
        )}
        {mode === 'edit' && !when && (
          <div className={styles.ruleClauseConditionActions}>
            <ActionBar
              addCondition
              onClick={() => addConditionToClause(clause.id)}
            />
          </div>
        )}
        <div className={styles.ruleClauseActions}>
          {actions.map((action) => (
            <RuleAction
              key={action.id}
              action={action}
              mode={mode}
              allowRemove={actions.length > 1}
              conditional={!when && !conditional}
            />
          ))}
          {mode === 'edit' && (
            <ActionBar addAction onClick={() => addActionToClause(clause.id)} />
          )}
        </div>
        {mode === 'edit' && addClause && when && (
          <div className={styles.ruleClauseSpecialActions}>
            <ActionBar addClause onClick={() => addClause && addClause()} />
          </div>
        )}
      </div>
    </div>
  );

  if (mode === 'view' && !isValidClause) {
    return (
      <OverlayTrigger
        placement='right'
        overlay={(props) => (
          <Tooltip {...props}>
            This clause contains errors that are preventing the rule from
            updating.
          </Tooltip>
        )}
      >
        {Clause}
      </OverlayTrigger>
    );
  }

  return (
    <>
      <ConfirmationModal
        title='Remove Clause'
        message='Are you sure you want to remove this clause?'
        show={showRemoveModal}
        setShow={setShowRemoveModal}
        onConfirm={() => handleRemove(true)}
        onCancel={() => setShowRemoveModal(false)}
      />
      {Clause}
    </>
  );
};
