import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';
import { PUBLISH_STATUS } from '../../../redux/utils';
import styles from '../styles.module.scss';

const SaveButton = ({
  submittingStatus,
  onClick
}: {
  submittingStatus: string;
  onClick: any;
}) => {
  return (
    <button
      className={classNames('borderless-button', styles.saveButton)}
      type='submit'
      onClick={onClick}
    >
      {submittingStatus === PUBLISH_STATUS.LOADING ? (
        <Spinner animation='border' size='sm' />
      ) : (
        'Save Changes'
      )}
    </button>
  );
};

export default SaveButton;
