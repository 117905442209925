import { memo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LinkIcon, UnlinkIcon } from '../../../Icons';
import { HandleLinkFn } from '../hooks/types';
import classNames from 'classnames';
import PropertyLabel from '../../PropertyLabel';
import styles from '../styles.module.scss';

type LinkButtonProps = {
  linked?: boolean;
  handleLink: HandleLinkFn;
  disabled?: boolean;
};

const LinkButton = ({
  linked,
  handleLink,
  disabled = false
}: LinkButtonProps) => {
  const color = !disabled ? '#414859' : 'black';

  return (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip id='link-size-tooltip'>
          {!disabled
            ? !linked
              ? 'Link Size'
              : 'Unlink Size'
            : 'Locking ratio is disabled due to percentage values.'}
        </Tooltip>
      }
    >
      <div onClick={!disabled ? handleLink : () => {}}>
        <PropertyLabel
          label={
            !linked ? (
              <LinkIcon color={color} width={20} height={20} />
            ) : (
              <UnlinkIcon color={color} width={20} height={20} />
            )
          }
          svgLabel
          className={classNames(styles.dimensionInputUtilityIcon, {
            [styles.disabled]: disabled
          })}
        />
      </div>
    </OverlayTrigger>
  );
};

export default memo(LinkButton);
