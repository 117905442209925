/* eslint-disable react-hooks/exhaustive-deps */

import '../style/page.scss';

import { useEffect } from 'react';

import { useIntercom } from 'react-use-intercom';
import GlobalNavigation from '../components/NavBar/GlobalNavigation';
import MobileGatePage from './MobileGatePage';
import { IS_PROD_FE } from '../api/utils';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { PendingInvitersModal } from '../components/Modals';

export default function Page({
  active,
  children,
  className = '',
  padding = true,
  alwaysShowScroll = false,
  hideNav = false,
  hideIntercom = false,
  noVerticalScroll = false,
  style
}: any) {
  const { stepId } = useParams<{ stepId: string }>();
  const { update } = useIntercom();

  useEffect(() => {
    // Toggle Intercom on or off for this page
    if (IS_PROD_FE) update({ hideDefaultLauncher: hideIntercom });
  }, [hideIntercom]);

  return window.innerWidth > 478 ? (
    <div key={className} className={`page ${className}`} style={style}>
      <PendingInvitersModal />
      {hideNav ? null : <GlobalNavigation active={active} />}
      <div
        className={classNames(
          'page-content',
          (noVerticalScroll || stepId === 'flow') && 'no-vertical-scrollbar',
          alwaysShowScroll && 'always-vertical-scrollbar'
        )}
        style={padding ? { padding: '0 30px 30px 30px' } : {}}
      >
        {children}
      </div>
    </div>
  ) : (
    <MobileGatePage />
  );
}
