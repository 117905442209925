import { UNDO_TITLES, UNDO_TYPES } from '../utils/constants';
import { PUBLISH_STATUS } from './utils';

type ChangeOperation = 'edit' | 'delete';
export const setPanelDataWithDeltas = (
  state: {
    workingSteps: { [key: string]: any };
    changedSteps: { [key: string]: { operation: ChangeOperation } };
    workingLogicRules: { [key: string]: any };
    changedLogicRules: { [key: string]: { operation: ChangeOperation } };
    unsavedChanges?: boolean;
  },
  newWorkingSteps?: { [key: string]: any },
  changedStepKeys?: string[],
  newworkingLogicRules?: { [key: string]: any },
  changedRuleKeys?: string[]
) => {
  // if there are steps changes, update their changes
  if (newWorkingSteps && changedStepKeys) {
    state.changedSteps = getChangeItemsState(
      state.changedSteps,
      newWorkingSteps,
      changedStepKeys
    );
    state.unsavedChanges = true;
    state.workingSteps = newWorkingSteps;
  }

  // if there are rules changes, update their changes too
  if (newworkingLogicRules && changedRuleKeys) {
    state.changedLogicRules = getChangeItemsState(
      state.changedLogicRules,
      newworkingLogicRules,
      changedRuleKeys
    );
    state.unsavedChanges = true;
    state.workingLogicRules = newworkingLogicRules;
  }
};

const getChangeItemsState = (
  existingChangedItems: { [key: string]: { operation: ChangeOperation } },
  newItemMap: { [key: string]: any },
  changedKeys: string[]
): { [key: string]: { operation: ChangeOperation } } => {
  const changedItems = { ...(existingChangedItems ?? {}) };
  return Object.assign(
    changedItems,
    changedKeys.reduce(
      (changedItems, key) => ({
        ...changedItems,
        [key]: {
          operation: key in newItemMap ? 'edit' : 'delete'
        }
      }),
      {}
    )
  );
};

export const manageUndoStack = (state: { undoStack: any[] }) => {
  // start dropping old changes if the stack gets too long
  if (state.undoStack.length >= 50) state.undoStack = state.undoStack.slice(1);
};

export const setPanelDataWithUndoRedo = (state: any, action: any) => {
  const {
    id,
    oldValue,
    newValue,
    oldRulesValue,
    newRulesValue,
    title,
    type,
    workingSteps,
    workingLogicRules,
    status,
    gigPosition
  } = action.payload;
  manageUndoStack(state);

  const nextWorkingSteps = JSON.parse(JSON.stringify(workingSteps));
  const nextworkingLogicRules = JSON.parse(
    JSON.stringify(workingLogicRules ?? state.workingLogicRules)
  );

  // this is the model that gets pushed onto the stack - id, old value, and new value
  const copyChange = {
    id,
    oldValue,
    newValue,
    oldRulesValue,
    newRulesValue
  };

  (copyChange as any).undoTitle = title;
  (copyChange as any).undoType = type;

  // don't apply duplicate changes
  if (state.undoStack.length > 0) {
    const previousChange = state.undoStack[state.undoStack.length - 1];
    if (JSON.stringify(copyChange) === JSON.stringify(previousChange)) return;
  }

  // push the change to the undo stack, and also clear anything in the redo stack because there's been new changes
  state.undoStack = [...state.undoStack, copyChange];

  state.redoStack = [];

  // get the changed step keys from the old values or the id
  let changedStepKeys = Object.keys(oldValue);
  if (
    type === UNDO_TYPES.STEP ||
    (type === UNDO_TYPES.CONDITION && title === UNDO_TITLES.NEW_STEP)
  ) {
    changedStepKeys = [id];
  }
  // get the changed rule keys from the old rules values
  let changedRuleKeys = Object.keys(oldRulesValue ?? {});
  if (title === UNDO_TITLES.NEW_LOGIC_RULE) {
    changedRuleKeys = [id];
  }

  // update the working step and step changes
  setPanelDataWithDeltas(
    state,
    nextWorkingSteps,
    changedStepKeys,
    nextworkingLogicRules,
    changedRuleKeys
  );
  state.flowPublishStatus = status ?? PUBLISH_STATUS.ACTIVE;
  if (gigPosition) state.gigPosition = gigPosition;
  updateServars(state, nextWorkingSteps);
};

export function updateServars(state: any, workingSteps: any) {
  const newUsage: Record<string, any[]> = {};
  const newServars: Record<string, any> = {};
  Object.entries(state.oldServarUsage).forEach(([servarId, usages]) => {
    const newUsages = (usages as any[]).filter(
      (usage) => usage.panel_id !== state.formId
    );
    if (newUsages.length > 0) {
      newUsage[servarId] = newUsages;
      newServars[servarId] = state.servars[servarId];
    }
  });
  Object.values(workingSteps).forEach((step) => {
    (step as any).servar_fields.forEach((field: any) => {
      const servarId = field.servar.id;
      if (!(servarId in newUsage)) newUsage[servarId] = [];
      if (
        !newUsage[servarId].find(
          (usage: { step_id: string; panel_id: string }) =>
            usage.step_id === (step as any).id &&
            usage.panel_id === state.formId
        )
      )
        newUsage[servarId].push({
          step_id: (step as any).id,
          panel_id: state.formId
        });
      newServars[servarId] = field.servar;
    });
  });
  state.servars = newServars;
  state.servarUsage = newUsage;
}
