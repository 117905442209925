import {
  CONTENT_TYPE,
  httpDelete,
  httpGet,
  httpPatch,
  httpPost,
  URL
} from './utils';

export default {
  getIntegrations: (token: string) => {
    return httpGet({
      token,
      url: URL.INTEGRATIONS
    });
  },
  createIntegration: (token: string, data: any) => {
    const { staticIp, panelId, ...newIntegrationData } = data;
    const url = staticIp ? URL.INTEGRATIONS_STATIC_IP : URL.INTEGRATIONS;
    return httpPost({
      token,
      url: `${url}${panelId}/`,
      body: newIntegrationData,
      invalid: [URL.INTEGRATIONS]
    });
  },
  editIntegration: (token: string, data: any) => {
    const { staticIp, type, panelId, ...newIntegrationData } = data;
    let url = staticIp ? URL.INTEGRATIONS_STATIC_IP : URL.INTEGRATIONS;
    url = `${url}${panelId}/detail/${type}/`;
    return httpPatch({ token, baseUrl: url, body: newIntegrationData });
  },
  switchIntegrationProperty: (token: string, data: any) => {
    const {
      panelId,
      integrationType,
      propertyType,
      propertyValue,
      automation
    } = data;
    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}${panelId}/rollout/${integrationType}/switch/`,
      body: {
        type: propertyType,
        new_property: propertyValue,
        automation_id: automation
      }
    });
  },
  uploadFont: (token: string, data: any) => {
    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}font/`,
      body: data,
      contentType: CONTENT_TYPE.MULTIPART_FORM
    });
  },
  getDynamicFonts: (token: string) => {
    return httpGet({ token, url: `${URL.INTEGRATIONS}font/` });
  },
  exchangeOAuthToken: (
    token: string,
    { code, type, egnyte_domain, panelId = null }: any
  ) => {
    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}oauth/`,
      body: { type, code, egnyte_domain, panel_id: panelId },
      invalid: [URL.INTEGRATIONS]
    });
  },
  revokeOAuthToken: (token: string, { type, egnyte_domain, panelId }: any) => {
    return httpDelete({
      token,
      baseUrl: URL.INTEGRATIONS,
      path: 'oauth/',
      body: { type, egnyte_domain, panel_id: panelId },
      invalid: [URL.INTEGRATIONS]
    });
  },
  getTranslations: (token: string, panelId: string) => {
    return httpGet({
      token,
      url: `${URL.INTEGRATIONS}${panelId}/translation/`
    });
  },
  editTranslations: (token: string, panelId: string, translations: any) => {
    return httpPatch({
      token,
      baseUrl: `${URL.INTEGRATIONS}${panelId}/translation/`,
      body: translations
    });
  },
  fetchRolloutToken: (token: string) => {
    return httpGet({
      token,
      url: `${URL.INTEGRATIONS}rollout/token/`
    });
  },
  getCustomAPIs: (token: string, data: any) => {
    const { panelId } = data;

    return httpGet({
      token,
      url: `${URL.INTEGRATIONS}service/${panelId}/`,
      forceLoad: true
    });
  },
  createCustomAPI: (token: string, data: any) => {
    const { panelId, customAPI } = data;

    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}service/${panelId}/`,
      body: customAPI
    });
  },
  editCustomAPI: (token: string, data: any) => {
    const { customAPI, remove } = data;

    if (remove) {
      return httpDelete({
        token,
        baseUrl: `${URL.INTEGRATIONS}service/detail/${customAPI.id}/`,
        invalid: [URL.INTEGRATIONS]
      });
    }

    return httpPatch({
      token,
      baseUrl: `${URL.INTEGRATIONS}service/detail/${customAPI.id}/`,
      body: customAPI
    });
  },
  testCustomAPI: (token: string, payload: any) => {
    return httpPost({
      token,
      url: `${URL.INTEGRATIONS}test_request/`,
      body: payload
    });
  }
};
