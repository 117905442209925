import { memo } from 'react';

import Dialog from '../../../Dialog';
import { Negative, Neutral } from '../../../Core/Button';

interface ConnectionConfirmationModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onConfirm: () => void;
  connectionsToBeBroken: [string, string][];
}
const ConnectionConfirmationModal = ({
  show,
  setShow,
  onConfirm,
  connectionsToBeBroken
}: ConnectionConfirmationModalProps) => {
  return (
    <Dialog
      alignCenter
      isOpen={show}
      shouldShowCloseIcon={false}
      onClose={() => setShow(false)}
      title='Other connections will also be removed'
    >
      <ul style={{ padding: '0 40px', fontSize: '16px' }}>
        {connectionsToBeBroken.map(([fromStepKey, toStepKey]) => (
          <li key={`${fromStepKey}-${toStepKey}`}>
            {fromStepKey} &#8594; {toStepKey}
          </li>
        ))}
      </ul>
      <div className='dialog-form-action'>
        <Neutral title='Cancel' onClick={() => setShow(false)} />
        <Negative
          title='Confirm'
          onClick={() => {
            onConfirm();
            setShow(false);
          }}
        />
      </div>
    </Dialog>
  );
};

export default memo(ConnectionConfirmationModal);
