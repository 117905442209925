import { httpDelete, httpGet, httpPatch, httpPost, URL } from './utils';

const servarUrl = URL.SERVARS;
const hiddenUrl = `${URL.SERVARS}hidden_field/`;

export default {
  getFields: (token: string, hidden: boolean) => {
    const url = hidden ? hiddenUrl : servarUrl;
    return httpGet({ token, url });
  },
  deleteField: (token: string, hidden: boolean, id: any) => {
    const baseUrl = hidden ? hiddenUrl : servarUrl;
    return httpDelete({
      token,
      baseUrl,
      path: `${id}/`,
      invalid: [URL.PANELS]
    });
  },
  createField: (token: string, hidden: boolean, data: any) => {
    const url = hidden ? hiddenUrl : servarUrl;
    return httpPost({ token, url, body: data });
  },
  editField: (token: string, hidden: boolean, data: any) => {
    const baseUrl = hidden ? hiddenUrl : servarUrl;
    const { id, ...newFieldData } = data;
    return httpPatch({ token, baseUrl, path: `${id}/`, body: newFieldData });
  },
  editFieldValue: (token: string, hidden: boolean, data: any) => {
    const baseUrl = hidden ? hiddenUrl : servarUrl;
    const { id, ...newFieldData } = data;
    return httpPost({
      token,
      url: `${baseUrl}${id}/fuser/`,
      body: newFieldData,
      invalid: [`${URL.USERS}${newFieldData.fuser}/`]
    });
  },
  deleteFieldValue: (token: string, hidden: boolean, data: any) => {
    const baseUrl = hidden ? hiddenUrl : servarUrl;
    const { id, fuser } = data;
    return httpDelete({
      token,
      baseUrl,
      path: `${id}/fuser/`,
      body: { fuser },
      invalid: [`${URL.USERS}${fuser}/`]
    });
  },
  getServarUsage: (token: string) => {
    return httpGet({ token, url: `${URL.SERVARS}usage/` });
  }
};
