import { LinkText } from '../../utils/domOperations';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './styles.module.scss';
import { getFileUrl } from '../../utils/browserUtils';

export function renderFile(value: any, urls: any) {
  return value.map((fileName: any, index: any) => {
    const isImage = Boolean(
      fileName.match(/.(jpg|jpeg|png|gif|svg|tiff|tif|bmp|ico)$/i)
    );
    return !isImage ? (
      <LinkText
        key={urls[index]}
        text={fileName}
        link={getFileUrl(urls[index])}
      />
    ) : (
      <OverlayTrigger
        key={urls[index]}
        // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; key: any; direction: st... Remove this comment to see the full error message
        direction='right'
        overlay={
          <Tooltip id={`file-preview-${urls[index]}`}>
            <div className={styles.assetPreviewTooltip}>
              <img src={urls[index]} alt='Preview of uploaded file.' />
            </div>
          </Tooltip>
        }
      >
        <LinkText text={fileName} link={getFileUrl(urls[index])} />
      </OverlayTrigger>
    );
  });
}
