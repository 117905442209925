import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import ReactGA from 'react-ga4';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
// @ts-ignore
import { TouchBackend } from 'react-dnd-touch-backend';
import { IntercomProvider } from 'react-use-intercom';
import { init as featheryInit } from '@feathery/react';

import { App } from './App';
import './style/index.scss';
import store from './redux/store';
import { ACTIVATION_FORM_ID } from './utils/constants';
import CustomDragLayer from './components/CustomDragLayer';
import { IS_PROD_BE, IS_PROD_FE } from './api/utils';
import { createRoot } from 'react-dom/client';
import initFeatheryAPI from './api';
import { enablePatches } from 'immer';

if (IS_PROD_FE) {
  // Only log in production
  Sentry.init({
    dsn: 'https://ac3cabb36eba453ab20bd66058b2341e@o400594.ingest.sentry.io/5280968',
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    release: process.env.REACT_APP_VERSION
  });
  ReactGA.initialize('G-QKSF4R9ZJK');
}

featheryInit(
  IS_PROD_FE
    ? 'c7f4c49f-bad3-434d-931c-050687538e11'
    : 'efa90250-d841-4820-9a2d-7d409c21f0e5',
  {
    preloadForms: [ACTIVATION_FORM_ID]
  }
);

const stripeKey = IS_PROD_BE
  ? 'pk_live_51HcK2DIewfn1jVhatgfIyqq2wFpt5oQHrojIDS0WpSd6xnCU4hYxTqRLa6r8Tjqni1eLZww3M2ggaRfQpPoPLIZO00vSTMe4rw'
  : 'pk_test_51HcK2DIewfn1jVhaqIjVQOi2e2KEI7K110wT9guILzEoGedlwBG72PFhbxt0Z0mFPoEkMLsyOG9P7WLb6A7rSQ4m00MIczighI';
const stripePromise = loadStripe(stripeKey);

// Don't run this in a useEffect
// We need to make sure this runs before children components render
initFeatheryAPI();

// immer patches
enablePatches();

const dontEmitErrors = [
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded'
];
const e = window.onerror;
window.onerror = function (err: any) {
  if (dontEmitErrors.includes(err.toString().trim())) {
    // Hide error overlay in dev
    const resizeObserverErrDiv = document.getElementById(
      'webpack-dev-server-client-overlay-div'
    );
    const resizeObserverErr = document.getElementById(
      'webpack-dev-server-client-overlay'
    );
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none');
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none');
    }
    return true;
  } else if (e) {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    return e(...arguments);
  }
};

const container = document.getElementById('root');
// @ts-ignore
createRoot(container).render(
  <IntercomProvider
    appId='h90mhuvx'
    autoBoot={IS_PROD_FE}
    autoBootProps={{ hideDefaultLauncher: true }}
  >
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <DndProvider
          backend={TouchBackend}
          options={{ enableMouseEvents: true }}
        >
          <CustomDragLayer />
          <App />
        </DndProvider>
      </Provider>
    </Elements>
  </IntercomProvider>
);
