import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AIExtractionRun } from './types';
import { parseRawRunData } from './utils';
import { useAppSelector } from '../../hooks';
import AIExtractionPanel from './components/AIExtractionPanel';
import PDFViewer from './components/PDFViewer';
import FormNavigation from '../../components/NavBar/FormNavigation';
import ExtractionProvider from './context';
import Zoomable from './components/Zoomable';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useFeatheryRedux from '../../redux';

type URLParams = {
  extractionId: string;
  runId: string;
};

export default function AIExtractionRunPage() {
  const { extractionId, runId } = useParams<URLParams>();

  const run = useAppSelector((state) =>
    state.ai.runs.find((run) => run.id === runId)
  );
  const [parsedRun, setParsedRun] = useState<AIExtractionRun | undefined>();

  const { getAIExtractionRuns } = useFeatheryRedux();

  useEffect(() => {
    getAIExtractionRuns({ extractionId });
  }, []);

  useEffect(() => {
    if (!run) return;
    setParsedRun(parseRawRunData(run));
  }, [run]);

  if (!parsedRun) return null;

  return (
    <>
      <FormNavigation activeItem='aiExtraction' />
      <ExtractionProvider>
        <div className={styles.pageContainer}>
          <div className={styles.pageSidebar}>
            <div className={styles.pageSidebarContent}>
              <AIExtractionPanel run={parsedRun} />
            </div>
            <div className={styles.pageSidebarPlaceholder} />
          </div>
          <div className={classNames(styles.pageContent, styles.centerContent)}>
            <Zoomable defaultMarginTop={20}>
              <PDFViewer run={parsedRun} />
            </Zoomable>
          </div>
        </div>
      </ExtractionProvider>
    </>
  );
}
