import { memo } from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { DRAFT_STATUS, PUBLISH_STATUS } from '../../../redux/utils';
import { CheckIcon, PublishRocket } from '../../Icons';
import classNames from 'classnames';

function Publish({
  status,
  disabled: notAllowed = false,
  onPublish = () => {},
  partial = false,
  saveFlag = false,
  draftStatus = DRAFT_STATUS.OPERATIONAL
}: any) {
  let content;
  const needsRefresh = [
    DRAFT_STATUS.CONFLICT,
    DRAFT_STATUS.CONFLICT_IGNORE,
    DRAFT_STATUS.ERROR
  ].includes(draftStatus);
  if (needsRefresh) content = 'Refresh';
  else {
    if (status === PUBLISH_STATUS.FULFILLED) {
      content = (
        <>
          <div
            className='button-icon'
            style={{
              width: '12px',
              height: '12px',
              paddingTop: '2px',
              marginRight: '6px'
            }}
          >
            <CheckIcon color={'var(--prim-100)'} stroke='1' />
          </div>
          {saveFlag ? 'Saved' : 'Published'}
        </>
      );
    } else {
      let message = 'Publish';
      if (saveFlag) message = 'Save';
      else if (status === PUBLISH_STATUS.AUTO_SAVING) message = 'Saving';
      const spinner = (
        <Spinner
          animation='border'
          role='status'
          style={{ width: '16px', height: '16px' }}
        />
      );
      const saving =
        status === PUBLISH_STATUS.LOADING ||
        status === PUBLISH_STATUS.AUTO_SAVING;
      if (saveFlag) {
        content = saving ? spinner : message;
      } else {
        content = (
          <>
            <div className='button-icon'>
              {saving ? spinner : <PublishRocket />}
            </div>
            {message}
          </>
        );
      }
    }
    content = (
      <div style={{ display: 'flex', alignItems: 'center' }}>{content}</div>
    );
  }

  const disconnected = DRAFT_STATUS.NOT_CONNECTED === draftStatus;
  const isDisabled =
    ([
      PUBLISH_STATUS.FULFILLED,
      PUBLISH_STATUS.AUTO_SAVING,
      PUBLISH_STATUS.LOADING
    ].includes(status) &&
      !needsRefresh) ||
    notAllowed ||
    disconnected;

  return (
    <OverlayTrigger
      placement='bottom'
      delay={{
        show: 500,
        hide: 0
      }}
      overlay={
        notAllowed ? (
          <Tooltip>Your account cannot publish forms.</Tooltip>
        ) : (
          <></>
        )
      }
    >
      <button
        className={classNames(
          'border-button',
          !isDisabled && !disconnected && 'button-changed',
          (notAllowed || disconnected) && 'tw-text-primary/50',
          partial && 'partial'
        )}
        onClick={onPublish}
        disabled={isDisabled}
      >
        {content}
      </button>
    </OverlayTrigger>
  );
}

export default memo(Publish);
