import { memo } from 'react';
import { UndoIcon } from '../../Icons';

function Undo({ onClick, disabled = () => {} }: any) {
  return (
    <button onClick={onClick} disabled={disabled} className='undo-button'>
      <UndoIcon disabled={disabled} />
    </button>
  );
}

export default memo(Undo);
