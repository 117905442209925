import { useMemo, useState } from 'react';
import { PageProps } from './types';
import { APIConnectorInput } from '../components/APIConnectorInput';
import { NavBar } from '../components/NavBar';
import classNames from 'classnames';
import styles from './styles.module.scss';

import {
  API_CONNECTOR_TEMPLATE_PAGE,
  CONFIGURE_REQUEST_PAGE
} from '../constants';

const API_CONNECTOR_DOCS_URL =
  'https://docs.feathery.io/platform/build-forms/logic/advanced/visual-rule-builder/no-code-api-connectors';

export const SelectAPIConnectorPage = (props: PageProps) => {
  const [error, setError] = useState('');
  const {
    state,
    onChange = () => {},
    onSubmit = () => {},
    onComplete = () => {},
    goto = () => {},
    apiConnectors = []
  } = props;

  const validateState = (setErrors = true) => {
    setError('');

    if (!state.id) {
      if (setErrors) setError('Select an API connector to continue.');
      return false;
    }

    if (!apiConnectors.find((c) => c.id === state.id)) {
      if (setErrors) setError('An invalid API connector was selected.');
      return false;
    }

    return true;
  };

  const canProceed = useMemo(
    () => validateState(false),
    [state.id, apiConnectors]
  );

  const handleCreateNew = () => {
    onChange('id', '');
    goto(API_CONNECTOR_TEMPLATE_PAGE);
  };

  const handleEdit = () => {
    if (!validateState()) return;
    else onSubmit(CONFIGURE_REQUEST_PAGE);
  };

  const handleSelect = () => {
    if (!validateState()) return;
    else onComplete(false);
  };

  return (
    <div id={styles.selectAPIConnectorPage} className={styles.page}>
      <div className={styles.content}>
        <div className={styles.field}>
          <div className={styles.label}>Select API Connector</div>
          <APIConnectorInput
            apiConnector={state.id}
            onChange={(apiConnector) => onChange('id', apiConnector)}
            filter={(apiConnector) => !!apiConnector.method}
          />
        </div>
        <div className={classNames(styles.field, styles.actions)}>
          <button className={styles.action} onClick={() => handleCreateNew()}>
            Create new connector
          </button>
          {state.id && (
            <button
              className={classNames(styles.action, styles.secondary)}
              onClick={() => handleEdit()}
            >
              Edit selected connector
            </button>
          )}
        </div>
      </div>
      <NavBar
        submit
        error={error}
        onClick={() => handleSelect()}
        disabled={{ submit: !canProceed }}
        text={{ submit: 'Select' }}
        docs={API_CONNECTOR_DOCS_URL}
      />
    </div>
  );
};
