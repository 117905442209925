import { memo, useState } from 'react';
import classNames from 'classnames';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import useFeatheryRedux from '../../redux';
import { sentryError } from '../../utils/runtime';
import { Neutral, Positive } from '../Core/Button';

import styles from './styles.module.scss';
import useGetJwt from '../../utils/useGetJwt';
import { useAppSelector } from '../../hooks';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#6c7589',
      fontFamily: 'Axiforma, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      '::placeholder': {
        color: '#6c7589'
      }
    },
    invalid: {
      color: '#d6504a',
      iconColor: '#d6504a'
    }
  }
};

let confirmGuard = false;

function PaymentMethod({ showCardEntry = false }) {
  const [paymentToggle, setPaymentToggle] = useState('initial');
  const stripe = useStripe();
  const elements = useElements();
  const getJwt = useGetJwt();
  const email = useAppSelector((state) => state.accounts.account.email);
  const org = useAppSelector((state) => state.accounts.organization);

  const {
    updateCard,
    toasts: { addErrorToast, addInfoToast }
  } = useFeatheryRedux();

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const token = getJwt();
    const response = await FeatheryAPI.initializeCard(token);
    const intentSecret = (await response.json()).intent_secret;

    if (confirmGuard) return;
    confirmGuard = true;
    try {
      const result = await stripe.confirmCardSetup(intentSecret, {
        payment_method: {
          // @ts-expect-error TS(2322) FIXME: Type 'StripeCardElement | null' is not assignable ... Remove this comment to see the full error message
          card: elements.getElement(CardElement),
          billing_details: { email }
        }
      });

      if (result.error) {
        addErrorToast({ title: result.error.message });
        sentryError(new Error('Card setup for plan upgrade failed.'), {
          context: { stripeError: result.error }
        });
      } else {
        await updateCard({ paymentMethod: result.setupIntent.payment_method });
        setPaymentToggle('initial');
        addInfoToast('Your payment method has been saved.');
        setCardEntryComplete(false);
      }
    } finally {
      confirmGuard = false;
    }
  };

  const [cardEntryComplete, setCardEntryComplete] = useState(false);
  const cardElementChange = (event: any) => {
    setCardEntryComplete(event.complete);
  };

  const cardData = org?.card_data;
  return (
    <div className={styles.paymentFieldSection}>
      {paymentToggle === 'initial' && !showCardEntry ? (
        <>
          {cardData && (
            <div
              className={classNames(
                styles.settingsSublabel,
                styles.paymentCardLast4
              )}
            >
              {`Last 4 Digits: ${cardData.last4}`}
              <br />
              {`Expiration: ${cardData.exp_month}/${cardData.exp_year}`}
            </div>
          )}
          <button
            className={classNames('border-button', styles.methodButton)}
            onClick={() => setPaymentToggle('collect')}
          >
            {cardData ? 'Edit Method' : 'Add Method'}
          </button>
        </>
      ) : (
        <>
          <div className={styles.paymentField}>
            <CardElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={cardElementChange}
            />
          </div>
          <div className={styles.paymentButtons}>
            <Neutral
              title='Cancel'
              className='border-button'
              onClick={() => {
                setPaymentToggle('initial');
                setCardEntryComplete(false);
              }}
            />
            <Positive
              title='Save'
              disabled={!cardEntryComplete}
              className={classNames('border-button', styles.saveCardButton)}
              onClick={handleSubmit}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default memo(PaymentMethod);
