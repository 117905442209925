import { Button } from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { useState } from 'react';
import { DeleteFormModal } from '../../../components/Modals';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';

const DeleteSection = ({ title }: any) => {
  const { formId } = useParams<{ formId: string }>();
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <SettingsContainer title={title}>
      <DeleteFormModal
        setShow={setShowDeleteModal}
        show={showDeleteModal}
        formId={formId}
        name={panel?.key ?? ''}
      />
      <Button.Delete
        onClick={() => setShowDeleteModal(true)}
        label='Delete Form'
      />
    </SettingsContainer>
  );
};

export default DeleteSection;
