import { memo, useMemo, useState } from 'react';

import styles from '../styles.module.scss';
import TemplateSelectCard from './TemplateSelectCard';
import { useAppSelector } from '../../../../hooks';
import SideNavigation from '../../../SideNavigation';

const TEMPLATE_CATEGORIES = [
  'Onboarding',
  'Financial Services',
  'Healthcare',
  'Signup & Login',
  'Registration',
  'Payment',
  'Waitlist',
  'Feedback',
  'Job App'
];

const templateCards = (templates: any[], onSelect: any) => {
  const rows = [];
  // 3 templates per row
  for (let i = 0; i < templates.length; i += 3) {
    rows.push(
      <div className={styles.row} key={i}>
        <TemplateSelectCard
          key={templates[i].name}
          template={templates[i].name}
          index={0}
          onSelect={onSelect}
        />
        {templates[i + 1] && (
          <TemplateSelectCard
            key={templates[i + 1].name}
            template={templates[i + 1].name}
            index={1}
            onSelect={onSelect}
          />
        )}
        {templates[i + 2] && (
          <TemplateSelectCard
            key={templates[i + 2].name}
            template={templates[i + 2].name}
            index={2}
            onSelect={onSelect}
          />
        )}
      </div>
    );
  }
  return rows;
};

function TemplatePicker({ onSelect = () => {} }: any) {
  const [filterKey] = useState('All');
  const templates = useAppSelector((s) => s.themes.templates);

  const categorizedTemplates = useMemo(() => {
    const categorized: Record<string, any[]> = {};
    TEMPLATE_CATEGORIES.forEach(
      (category) =>
        (categorized[category] = templates.filter((template) =>
          template.groups.includes(category)
        ))
    );
    categorized[''] = templates.filter(
      (template) => template.groups.length === 0
    );
    return categorized;
  }, [templates]);

  const displayCards = useMemo(() => {
    if (filterKey === 'All') {
      const parsedTemplates = [...TEMPLATE_CATEGORIES, ''].map((category) => {
        const categoryTemplates = categorizedTemplates[category];
        return (
          <div
            key={category}
            title={category || 'Other Templates'}
            className={styles.categorySection}
            results={categoryTemplates.length}
          >
            <div className={styles.category}>
              {category || 'Other Templates'}
            </div>
            {templateCards(categoryTemplates, onSelect)}
          </div>
        );
      });
      return [
        <div key='blank' className={styles.categorySection}>
          <div className={styles.category}>Start from</div>
          {templateCards(
            [{ name: 'blank' }, { name: 'generate' }, { name: 'migrate' }],
            onSelect
          )}
        </div>,
        ...parsedTemplates
      ];
    } else {
      return (
        <div title={'Other'}>
          {templateCards(categorizedTemplates[filterKey], onSelect)}
        </div>
      );
    }
  }, [categorizedTemplates, filterKey]);

  return (
    <div className={styles.templateContainer}>
      <SideNavigation title={'Categories'} showAll outlined>
        {displayCards}
      </SideNavigation>
    </div>
  );
}

export default memo(TemplatePicker);
