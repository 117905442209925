import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const getAIExtractions = createAsyncThunk(
  'ai/getAIExtractions',
  async ({ token }: { token: string }) => {
    const response = await FeatheryAPI.getAIExtractions(token);

    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Could not get AI extraction');
    }
  }
);

const createAIExtraction = createAsyncThunk(
  'ai/createAIExtraction',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.createAIExtraction(token, data);

    if (response.status === 201) {
      return await response.json();
    }

    throw new Error('Could not create AI extraction');
  }
);

const editAIExtraction = createAsyncThunk(
  'ai/editAIExtraction',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.editAIExtraction(token, data);

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not edit AI extraction');
  }
);

const deleteAIExtraction = createAsyncThunk(
  'ai/deleteAIExtraction',
  async ({ extractionId, token }: { extractionId: string; token: string }) => {
    const response = await FeatheryAPI.deleteAIExtraction(token, {
      extractionId
    });

    if (response.status !== 204) {
      throw new Error('Could not delete AI extraction');
    }
  }
);

const getAIExtractionRuns = createAsyncThunk(
  'ai/getAIExtractionRuns',
  async ({ token, ...data }: { token: string }) => {
    const response = await FeatheryAPI.getAIExtractionRuns(token, data);

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not get AI extraction runs');
  }
);

const editAIExtractionRun = createAsyncThunk(
  'ai/editAIExtractionRun',
  async ({ token, runId, ...data }: { token: string; runId: string }) => {
    const response = await FeatheryAPI.editAIExtractionRun(token, runId, data);

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not edit AI extraction run');
  }
);

const deleteAIExtractionRun = createAsyncThunk(
  'ai/deleteAIExtractionRun',
  async ({ runId, token }: { runId: string; token: string }) => {
    const response = await FeatheryAPI.deleteAIExtractionRun(token, runId);
    if (response.status !== 204) {
      throw new Error('Could not delete AI extraction run');
    }
  }
);

const updateAIExtractionEntry = createAsyncThunk(
  'ai/updateAIExtractionEntry',
  async ({
    token,
    runId,
    data
  }: {
    token: string;
    runId: string;
    data: any;
  }) => {
    const response = await FeatheryAPI.updateAIExtractionEntry(
      token,
      runId,
      data
    );

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not update AI extraction entry');
  }
);

const commitAIExtractionEntries = createAsyncThunk(
  'ai/commitAIExtractionEntries',
  async ({
    token,
    runId,
    data
  }: {
    token: string;
    runId: string;
    data: any;
  }) => {
    const response = await FeatheryAPI.commitAIExtractionEntries(
      token,
      runId,
      data
    );

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not commit AI extraction entries');
  }
);

const aiAnalyzeQuestion = createAsyncThunk(
  'ai/aiAnalyzeQuestion',
  async ({ token, question }: { token: string; question: string }) => {
    const response = await FeatheryAPI.analyzeQuestion(token, question);

    if (response.status === 200) {
      return await response.json();
    }

    throw new Error('Could not analyze question');
  }
);

const aiExtractionsSlice = createSlice({
  name: 'aiExtractions',
  initialState: {
    extractions: [] as any[],
    runs: [] as any[],
    count: 0,
    prevUrl: '',
    nextUrl: '',
    extractionId: ''
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAIExtractions.fulfilled, (state, action) => {
      (state as any).extractions = action.payload;
    });

    builder.addCase(createAIExtraction.fulfilled, (state: any, action: any) => {
      state.extractions = [...state.extractions, action.payload];
    });

    builder.addCase(editAIExtraction.fulfilled, (state: any, action: any) => {
      const {
        meta: { arg },
        payload
      } = action;

      const id = state.extractions.findIndex(
        (extraction: any) => extraction.id === arg.extractionId
      );
      const newAIExtractions = state.extractions.filter(
        (extraction: any) => extraction.id !== arg.extractionId
      );
      newAIExtractions.splice(id, 0, payload);
      return { ...state, extractions: newAIExtractions };
    });

    builder.addCase(deleteAIExtraction.fulfilled, (state: any, action: any) => {
      const {
        meta: { arg }
      } = action;
      const index = state.extractions.findIndex(
        (extraction: any) => extraction.id === arg.extractionId
      );
      if (index >= 0) {
        state.extractions.splice(index, 1);
      }
    });

    builder.addCase(
      getAIExtractionRuns.fulfilled,
      (state: any, action: any) => {
        const {
          meta: { arg },
          payload
        } = action;
        state.extractionId = arg.extractionId;
        state.runs = payload.results;
        state.count = payload.count;
        state.prevUrl = payload.previous;
        state.nextUrl = payload.next;
      }
    );

    builder.addCase(
      deleteAIExtractionRun.fulfilled,
      (state: any, action: any) => {
        const {
          meta: { arg }
        } = action;
        const index = state.runs.findIndex((run: any) => run.id === arg.runId);
        if (index >= 0) state.runs.splice(index, 1);
      }
    );

    builder.addCase(
      editAIExtractionRun.fulfilled,
      (state: any, action: any) => {
        const {
          meta: { arg }
        } = action;
        const index = state.runs.findIndex((run: any) => run.id === arg.runId);
        state.runs[index] = action.payload;
      }
    );

    builder.addCase(
      updateAIExtractionEntry.fulfilled,
      (state: any, action: any) => {
        const { payload } = action;

        const updatedEntries = payload.entries;
        state.runs = state.runs.map((run: any) => {
          return {
            ...run,
            question_groups: run.question_groups.map((questionGroup: any) => {
              return {
                ...questionGroup,
                entries: questionGroup.entries.map((entry: any) => {
                  const updatedEntry = updatedEntries.find(
                    ({ id }: any) => id === entry.id
                  );

                  if (updatedEntry) {
                    return updatedEntry;
                  }

                  return entry;
                })
              };
            })
          };
        });
      }
    );

    builder.addCase(
      commitAIExtractionEntries.fulfilled,
      (state: any, action: any) => {
        const { payload } = action;

        const updatedEntries = payload.entries;
        state.runs = state.runs.map((run: any) => {
          return {
            ...run,
            question_groups: run.question_groups.map((questionGroup: any) => {
              return {
                ...questionGroup,
                entries: questionGroup.entries.map((entry: any) => {
                  const updatedEntry = updatedEntries.find(
                    ({ id }: any) => id === entry.id
                  );

                  if (updatedEntry) {
                    return updatedEntry;
                  }

                  return entry;
                })
              };
            })
          };
        });
      }
    );
  }
});

export default aiExtractionsSlice.reducer;
export {
  getAIExtractions,
  createAIExtraction,
  editAIExtraction,
  deleteAIExtraction,
  editAIExtractionRun,
  getAIExtractionRuns,
  deleteAIExtractionRun,
  updateAIExtractionEntry,
  commitAIExtractionEntries,
  aiAnalyzeQuestion
};
