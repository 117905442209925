import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  ALL_INTEGRATIONS_MAP,
  APIKeySidebar,
  AlloySettingsSidebar,
  ArgyleSettingsSidebar,
  FlinksSettingsSidebar,
  EgnyteSettingsSidebar,
  EmailSettingsSidebar,
  FirebaseSettingsSidebar,
  HubspotSettingsSidebar,
  IntegrationCard,
  INTEGRATIONS,
  PlaidSettingsSidebar,
  PostgresSettingsSidebar,
  PREMIUM_INTEGRATIONS,
  QuikSettingsSidebar,
  SFTPSettingsSidebar,
  SlackSettingsSidebar,
  STANDARD_INTEGRATIONS,
  StripeSettingsSidebar,
  StytchSettingsSidebar,
  TelesignSettingsSidebar,
  TrustedFormSettingsSidebar,
  WebhookSettingsSidebar,
  ZapierSettingsSidebar
} from '../components/Integrations';
import useFilterTag from '../components/Integrations/components/useFilterTag';

import FormNavigation from '../components/NavBar/FormNavigation';

import { chosenPlan, INTEGRATION_LEVELS } from '../components/PricingPlans';

import styles from './styles.module.scss';
import useIntegrations from '../components/Integrations/useIntegrations';
import {
  PREMIUM_INTEGRATIONS_ORDER,
  STANDARD_INTEGRATION_ORDER
} from '../components/Integrations/types';
import SegmentSettingsSidebar from '../components/Integrations/SegmentSettingsSidebar';
import RolloutSidebar from '../components/Integrations/RolloutSidebar';
import { SearchIcon } from '../components/Icons';
import classNames from 'classnames';
import GoogleTagManagerSettingsSidebar from '../components/Integrations/GoogleTagManagerSettingsSidebar';
import DisabledIntegrationSidebar from '../components/Integrations/DisabledIntegrationSidebar';
import RudderStackSettingsSidebar from '../components/Integrations/RudderStackSettingsSidebar';
import AmplitudeSettingsSidebar from '../components/Integrations/AmplitudeSettingsSidebar';
import SMSOTPSettingsSidebar from '../components/Integrations/SMSOTPSettingsSidebar';
import CalendlySettingsSidebar from '../components/Integrations/CalendlySettingsSidebar';
import PersonaSettingsSidebar from '../components/Integrations/PersonaSettingsSidebar';
import SMSMessageSettingsSidebar from '../components/Integrations/SMSMessageSettingsSidebar';
import DocumentsIntegrationSidebar from '../components/Integrations/DocumentsIntegrationSidebar';
import { useAppSelector } from '../hooks';
import PowerAutomateSidebar from '../components/Integrations/PowerAutomateSidebar';
import { ROLLOUT_APPS } from '../components/Integrations/RolloutSidebar/components/constants';
import EmailOTPSettingsSidebar from '../components/Integrations/EmailOTPSettingsSidebar';

function FormIntegrationsPage() {
  const { formId } = useParams<{ formId: string }>();

  const [searchStr, setSearchStr] = useState('');
  const [visibleSidebar, setVisibleSidebar] = useState('');
  const { filterTag, filterPills } = useFilterTag();

  const integrations = useIntegrations({ all: true, panelId: formId });

  // Supporting passing a url param to preselect an integration/sidebar.  Needed for Stytch over quota CTA.
  const { search } = useLocation();
  useEffect(() => {
    const sidebar = new URLSearchParams(search).get('sidebar');
    sidebar && setVisibleSidebar(sidebar);
  }, [search]);

  const org = useAppSelector((state) => state.accounts.organization);
  const { integration: planIntLevel, title: planName } = chosenPlan(org);

  let sidebarContent;
  if (visibleSidebar in ROLLOUT_APPS) {
    sidebarContent = (
      <RolloutSidebar
        key={visibleSidebar}
        integrationType={visibleSidebar}
        rolloutApp={ROLLOUT_APPS[visibleSidebar]}
      />
    );
  } else {
    switch (visibleSidebar) {
      // TODO: gate Shopify for now until Rollout ports to v2
      case INTEGRATIONS.SHOPIFY:
      case INTEGRATIONS.EXPERIAN:
      case INTEGRATIONS.EQUIFAX:
      case INTEGRATIONS.ID_VERIFICATION:
      case INTEGRATIONS.SAP_CONCUR:
      case INTEGRATIONS.MORNINGSTAR:
      case INTEGRATIONS.EMONEY:
      case INTEGRATIONS.REDTAIL:
      case INTEGRATIONS.WEALTHBOX:
      case INTEGRATIONS.MULESOFT:
      case INTEGRATIONS.LEXISNEXIS:
      case INTEGRATIONS.FIDELITY:
      case INTEGRATIONS.SCHWAB:
      case INTEGRATIONS.PERSHING:
      case INTEGRATIONS.VANGUARD:
      case INTEGRATIONS.INVERITE:
      case INTEGRATIONS.APPLIED_EPIC:
      case INTEGRATIONS.GUIDEWIRE:
      case INTEGRATIONS.SAGITTA:
      case INTEGRATIONS.AMS360:
      case INTEGRATIONS.EZLYNX:
      case INTEGRATIONS.HAWKSOFT:
      case INTEGRATIONS.QQ_CATALYST:
      case INTEGRATIONS.NOVIDEA:
      case INTEGRATIONS.PL_RATING:
      case INTEGRATIONS.QUOTE_RUSH:
      case INTEGRATIONS.TURBORATER:
      case INTEGRATIONS.IBQ_RATER:
      case INTEGRATIONS.TARMIKA:
      case INTEGRATIONS.AGENCY_ZOOM:
      case INTEGRATIONS.ZYWAVE:
      case INTEGRATIONS.FISERV:
      case INTEGRATIONS.LOANPRO:
      case INTEGRATIONS.TEMENOS:
      case INTEGRATIONS.OSCILAR:
        sidebarContent = (
          <DisabledIntegrationSidebar integrationType={visibleSidebar} />
        );
        break;
      case INTEGRATIONS.POWER_AUTOMATE:
        sidebarContent = <PowerAutomateSidebar />;
        break;
      case INTEGRATIONS.DOCUMENTS:
        sidebarContent = <DocumentsIntegrationSidebar />;
        break;
      case INTEGRATIONS.EGNYTE:
        sidebarContent = <EgnyteSettingsSidebar />;
        break;
      case INTEGRATIONS.SFTP:
        sidebarContent = <SFTPSettingsSidebar />;
        break;
      case INTEGRATIONS.FIREBASE:
        sidebarContent = <FirebaseSettingsSidebar />;
        break;
      case INTEGRATIONS.HUBSPOT_LEGACY:
        sidebarContent = <HubspotSettingsSidebar />;
        break;
      case INTEGRATIONS.SMS_MESSAGE:
        sidebarContent = <SMSMessageSettingsSidebar />;
        break;
      case INTEGRATIONS.SMS_OTP:
        sidebarContent = <SMSOTPSettingsSidebar />;
        break;
      case INTEGRATIONS.EMAIL_OTP:
        sidebarContent = <EmailOTPSettingsSidebar />;
        break;
      case INTEGRATIONS.STYTCH:
        sidebarContent = <StytchSettingsSidebar />;
        break;
      case INTEGRATIONS.STRIPE:
        sidebarContent = <StripeSettingsSidebar />;
        break;
      case INTEGRATIONS.TELESIGN:
        sidebarContent = <TelesignSettingsSidebar />;
        break;
      case INTEGRATIONS.ZAPIER:
        sidebarContent = <ZapierSettingsSidebar />;
        break;
      case INTEGRATIONS.GOOGLE_ANALYTICS:
        sidebarContent = (
          <APIKeySidebar
            key={INTEGRATIONS.GOOGLE_ANALYTICS}
            integrationInfo={
              ALL_INTEGRATIONS_MAP[INTEGRATIONS.GOOGLE_ANALYTICS]
            }
            label='Measurement ID*'
            placeholder='G-XXXXXXXXXX'
          />
        );
        break;
      case INTEGRATIONS.INTERCOM_EMBEDDED:
        sidebarContent = (
          <APIKeySidebar
            key={INTEGRATIONS.INTERCOM_EMBEDDED}
            integrationInfo={
              ALL_INTEGRATIONS_MAP[INTEGRATIONS.INTERCOM_EMBEDDED]
            }
            label='Workspace ID*'
            placeholder='XXXXXXXX'
          />
        );
        break;
      case INTEGRATIONS.HEAP:
        sidebarContent = (
          <APIKeySidebar
            key={INTEGRATIONS.HEAP}
            integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.HEAP]}
            label='App ID*'
          />
        );
        break;
      case INTEGRATIONS.AMPLITUDE:
        sidebarContent = <AmplitudeSettingsSidebar />;
        break;
      case INTEGRATIONS.MIXPANEL:
        sidebarContent = (
          <APIKeySidebar
            key={INTEGRATIONS.MIXPANEL}
            integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.MIXPANEL]}
            label='Project Token'
          />
        );
        break;
      case INTEGRATIONS.CALENDLY:
        sidebarContent = <CalendlySettingsSidebar />;
        break;
      case INTEGRATIONS.RUDDERSTACK:
        sidebarContent = <RudderStackSettingsSidebar />;
        break;
      case INTEGRATIONS.GOOGLE_TAG_MANAGER:
        sidebarContent = <GoogleTagManagerSettingsSidebar />;
        break;
      case INTEGRATIONS.SEGMENT:
        sidebarContent = <SegmentSettingsSidebar />;
        break;
      case INTEGRATIONS.TRUSTEDFORM:
        sidebarContent = <TrustedFormSettingsSidebar />;
        break;
      case INTEGRATIONS.PLAID:
        sidebarContent = <PlaidSettingsSidebar />;
        break;
      case INTEGRATIONS.PERSONA:
        sidebarContent = <PersonaSettingsSidebar />;
        break;
      case INTEGRATIONS.ARGYLE:
        sidebarContent = <ArgyleSettingsSidebar />;
        break;
      case INTEGRATIONS.ALLOY:
        sidebarContent = <AlloySettingsSidebar />;
        break;
      case INTEGRATIONS.FLINKS:
        sidebarContent = <FlinksSettingsSidebar />;
        break;
      case INTEGRATIONS.SLACK_LEGACY:
        sidebarContent = <SlackSettingsSidebar />;
        break;
      case INTEGRATIONS.EMAIL:
        sidebarContent = <EmailSettingsSidebar />;
        break;
      case INTEGRATIONS.WEBHOOK:
        sidebarContent = <WebhookSettingsSidebar />;
        break;
      case INTEGRATIONS.POSTGRES:
        sidebarContent = <PostgresSettingsSidebar />;
        break;
      case INTEGRATIONS.QUIK:
        sidebarContent = <QuikSettingsSidebar />;
        break;
      default:
        sidebarContent = null;
    }
  }

  const premiumToRender = renderIntegrations(
    PREMIUM_INTEGRATIONS.sort((a: any, b: any) => {
      const aInteg = integrations[a.key];
      const bInteg = integrations[b.key];
      if ((aInteg && bInteg) || (!aInteg && !bInteg)) {
        return PREMIUM_INTEGRATIONS_ORDER[a.key] >
          PREMIUM_INTEGRATIONS_ORDER[b.key]
          ? 1
          : -1;
      } else return aInteg ? -1 : 1;
    }),
    formId,
    visibleSidebar,
    setVisibleSidebar,
    filterTag,
    searchStr,
    org
  );
  return (
    <>
      <FormNavigation activeItem='integrations' />
      <div className={styles.integrationsContainer}>
        <div className={styles.integrationsScrollContainer}>
          <div className={styles.integrationsHeader}>
            <h1 className='page-head'>Integrations</h1>
            <div className={classNames('search-wrap', styles.searchAdjust)}>
              <SearchIcon className='search-icon' />
              <input
                type='text'
                className='inp list-view-search'
                value={searchStr}
                placeholder='Search'
                onChange={(e) => setSearchStr(e.target.value)}
              />
            </div>
            {filterPills}
          </div>
          <div className={styles.integrationCardsContainer}>
            {renderIntegrations(
              STANDARD_INTEGRATIONS.filter(
                (integ: any) =>
                  // Only show legacy integrations for people who already have it active
                  ![
                    INTEGRATIONS.HUBSPOT_LEGACY,
                    INTEGRATIONS.SLACK_LEGACY,
                    INTEGRATIONS.PDF_LEGACY
                  ].includes(integ.key) || integrations[integ.key]
              ).sort((a: any, b: any) => {
                const aInteg = integrations[a.key];
                const bInteg = integrations[b.key];
                if ((aInteg && bInteg) || (!aInteg && !bInteg)) {
                  return STANDARD_INTEGRATION_ORDER[a.key] >
                    STANDARD_INTEGRATION_ORDER[b.key]
                    ? 1
                    : -1;
                } else return aInteg ? -1 : 1;
              }),
              formId,
              visibleSidebar,
              setVisibleSidebar,
              filterTag,
              searchStr,
              org
            )}
            {premiumToRender.length > 0 && (
              <div className={styles.integrationsSubHeader}>
                <div className={styles.integrationsSubHeaderLabel}>
                  Premium Integrations
                </div>
                <span className={styles.integrationsHeaderSubtitle}>
                  {planIntLevel === INTEGRATION_LEVELS.PREMIUM ? (
                    `Included in your ${planName} plan`
                  ) : (
                    <a
                      href='https://www.feathery.io/demo'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Reach out to sales for live access
                    </a>
                  )}
                </span>
              </div>
            )}
            {premiumToRender}
          </div>
        </div>
        {sidebarContent && (
          <div className={styles.sidebar}>
            <div className={styles.sidebarOverflow}>{sidebarContent}</div>
          </div>
        )}
      </div>
    </>
  );
}

const renderIntegrations = (
  integrations: typeof STANDARD_INTEGRATIONS,
  formId: string,
  visibleSidebar: any,
  setVisibleSidebar: any,
  filterTag: string,
  searchStr: string,
  org: any
) => {
  return integrations
    .filter(
      (integ) =>
        (org.hipaaIntegrationWhitelist || !integ.hideForHIPAA) &&
        (filterTag === 'All' || integ.tags.includes(filterTag)) &&
        (!searchStr ||
          integ.title.toLowerCase().includes(searchStr.toLowerCase()))
    )
    .map((integ) => (
      // eslint-disable-next-line react/jsx-key
      <IntegrationCard
        integrationType={integ.key}
        panelId={formId}
        isSelected={visibleSidebar === integ.key}
        onClick={() => setVisibleSidebar(integ.key)}
        {...integ}
      />
    ));
};

export default FormIntegrationsPage;
