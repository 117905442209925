import { NavBar } from '../../components/NavBar';
import { PageProps } from '../types';
import {
  API_CONNECTOR_TEMPLATE_PAGE,
  CONFIGURE_REQUEST_PAGE,
  MAP_RESPONSE_STRUCTURE_PAGE,
  SELECT_API_SERVICE_PAGE
} from '../../constants';
import { useEffect, useMemo, useState } from 'react';
import { DropdownField, TextField } from '../../../../Core';
import {
  BasicValueComponent,
  KeyValueInput,
  ValueComponentProps
} from '../../components/KeyValueInput';
import { JSONEditor } from '../../../../JSONEditor';
import { useFields } from '../../../../../hooks/useFields';
import { searchForFields } from '../../utils';
import styles from '../styles.module.scss';
import { ErrorMarkerIcon } from '../../../../Icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RolloutCredentialPicker } from '../../components/RolloutCredentialPicker';
import {
  getUsedAppTokens,
  replaceValue,
  ROLLOUT_TOKENS,
  validateState
} from '../utils';
import { GUIDE_TEXT } from '../../api_templates';

export const CustomPage = (props: PageProps) => {
  const [errors, setErrors] = useState<any>({});
  const {
    state,
    setState,
    onSubmit = () => {},
    onChange = () => {},
    goto = () => {},
    apiConnectors = [],
    options
  } = props;

  const fields = useFields(true);
  const fieldKeys = useMemo(() => {
    const fieldMap = fields.reduce((acc, f) => ({ ...acc, [f.key]: true }), {});
    ROLLOUT_TOKENS.forEach((token) => (fieldMap[token] = true));
    fieldMap.feathery_user_id = true;
    fieldMap.feathery_auth_email = true;
    return fieldMap;
  }, [fields]);

  const handleSubmit = () => {
    if (!validateState(state, { setErrors, apiConnectors, fieldKeys })) return;
    else onSubmit(MAP_RESPONSE_STRUCTURE_PAGE);
  };

  const FieldTextInput = useMemo(() => {
    const FieldTextInputComponent = (props: ValueComponentProps) => {
      const { onComplete = () => {}, ...rest } = props;
      const [fieldTextError, setFieldTextError] = useState(false);

      const validate = (text: string) => {
        const fieldNames = searchForFields(text);

        if (fieldNames.length) {
          const invalidFields = fieldNames.find((f) => !fieldKeys[f]);

          if (invalidFields) {
            setFieldTextError(true);
          }
        }
      };

      useEffect(() => {
        validate(rest.value);
      }, []);

      const handleComplete = (text: string) => {
        validate(text);
        onComplete(text);
      };

      return (
        <BasicValueComponent
          {...rest}
          type='expanding-textarea'
          onComplete={handleComplete}
          error={fieldTextError}
        />
      );
    };

    return FieldTextInputComponent;
  }, [fieldKeys]);

  const app_tokens = getUsedAppTokens(state).map((token) => token.slice(0, -6));

  const guideText: JSX.Element[] = app_tokens
    .map((token: any) => GUIDE_TEXT[token as keyof typeof GUIDE_TEXT] ?? null)
    .filter((element): element is JSX.Element => element !== null);

  return (
    <>
      <div className={styles.page}>
        <div className={styles.content}>
          {guideText.length > 0 && (
            <div className={styles.field}>{guideText}</div>
          )}
          <div className={styles.field}>
            <div className={styles.label}>Name</div>
            <TextField
              value={state.name}
              onChange={(val: string) => {
                onChange('name', val);
                validateState(
                  { ...state, name: val },
                  {
                    targets: ['name'],
                    setErrors,
                    apiConnectors
                  }
                );
              }}
              error={errors.name}
              errorMessage={errors.name}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>URL</div>
            <TextField
              value={state.url}
              maxLength={256}
              onChange={(val: string) => {
                onChange('url', val);
                validateState(
                  { ...state, url: val },
                  { targets: ['url'], setErrors, fieldKeys }
                );
              }}
              error={errors.url}
              errorMessage={errors.url}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>
              Headers{' '}
              {errors['headers'] && (
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip style={{ zIndex: 99999999 }}>
                      {errors['headers']}
                    </Tooltip>
                  }
                >
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <ErrorMarkerIcon />
                  </div>
                </OverlayTrigger>
              )}
            </div>
            <KeyValueInput
              value={state.headers as any}
              addButtonText='Add header'
              onChange={(headers: any) => {
                onChange('headers', headers);
                validateState(
                  { ...state, headers },
                  { targets: ['headers'], setErrors, fieldKeys }
                );
              }}
              allowEmptyKeys
              ValueComponent={FieldTextInput}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>
              Query Parameters{' '}
              {errors['params'] && (
                <OverlayTrigger
                  placement='right'
                  overlay={
                    <Tooltip style={{ zIndex: 99999999 }}>
                      {errors['params']}
                    </Tooltip>
                  }
                >
                  <div
                    style={{
                      position: 'relative'
                    }}
                  >
                    <ErrorMarkerIcon />
                  </div>
                </OverlayTrigger>
              )}
            </div>
            <KeyValueInput
              value={state.params as any}
              addButtonText='Add parameter'
              onChange={(params: any) => {
                onChange('params', params);
                validateState(
                  { ...state, params },
                  { targets: ['params'], setErrors, fieldKeys }
                );
              }}
              allowEmptyKeys
              ValueComponent={FieldTextInput}
            />
          </div>
          <div className={styles.field}>
            <div className={styles.label}>Method</div>
            <DropdownField
              placeholder='Select'
              onChange={(event: any) => {
                onChange('method', event.target.value);
                validateState(
                  { ...state, method: event.target.value },
                  { targets: ['method'], setErrors }
                );
              }}
              selected={state.method}
              options={[
                { display: 'GET', value: 'GET' },
                { display: 'POST', value: 'POST' },
                { display: 'PUT', value: 'PUT' },
                { display: 'PATCH', value: 'PATCH' },
                { display: 'DELETE', value: 'DELETE' }
              ]}
              error={errors.method}
              errorMessage={errors.method}
            />
          </div>
          {state.method && !['GET', 'DELETE'].includes(state.method) && (
            <div className={styles.field}>
              <div className={styles.label}>
                Request Body{' '}
                {errors['body'] && (
                  <OverlayTrigger
                    placement='right'
                    overlay={
                      <Tooltip style={{ zIndex: 99999999 }}>
                        {errors['body']}
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        position: 'relative'
                      }}
                    >
                      <ErrorMarkerIcon />
                    </div>
                  </OverlayTrigger>
                )}
              </div>
              <JSONEditor
                height='300px'
                code={state.body}
                onChange={(code?: string) => {
                  onChange('body', code || '');
                  validateState(
                    { ...state, body: code || '' },
                    { targets: ['body'], setErrors, fieldKeys }
                  );
                }}
              />
            </div>
          )}
          {!!app_tokens.length && (
            <div className={styles.field}>
              <div className={styles.label}>App Credentials</div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                {app_tokens.map((app) => (
                  <RolloutCredentialPicker
                    key={app}
                    app={app}
                    credentialKey={state.tokens?.[app] ?? null}
                    onChange={(credential, data) => {
                      const { previousCredential } = data ?? {};

                      if (
                        previousCredential &&
                        previousCredential.data.userCustomDomainUrl &&
                        credential &&
                        credential.data.userCustomDomainUrl
                      ) {
                        const newState = replaceValue(
                          state,
                          previousCredential.data.userCustomDomainUrl,
                          credential.data.userCustomDomainUrl
                        );
                        newState.tokens[app] = credential.credentialKey;
                        setState(newState);
                      } else {
                        onChange('tokens', {
                          ...(state.tokens ?? {}),
                          [app]: credential.credentialKey
                        });
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <NavBar
        next
        back={
          options?.startPage != null &&
          options.startPage < CONFIGURE_REQUEST_PAGE
        }
        onClick={(btn: string) => {
          if (btn === 'next') handleSubmit();
          else if (btn === 'back') {
            goto(
              state.id ? SELECT_API_SERVICE_PAGE : API_CONNECTOR_TEMPLATE_PAGE
            );
          }
        }}
      />
    </>
  );
};
