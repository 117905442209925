import { useRef, useState } from 'react';
import { TableAction } from './types';
import { useGlobalMouseDownToggle } from '../Core/util';
import { MenuIcon } from '../Icons';
import { ContextMenu } from '../Core';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';

type TableActionsProps = {
  entry: any;
  actions: TableAction[];
  hasOverflowMenu?: boolean;
};
export function TableActions({
  entry,
  actions,
  hasOverflowMenu
}: TableActionsProps) {
  const actionMenuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useGlobalMouseDownToggle([actionMenuRef]);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setShowMenu(true);

    setPosition({ x: event.clientX, y: event.clientY });
  };

  if (hasOverflowMenu) {
    return (
      <div>
        <button className='tr-icon-button' onClick={(event) => openMenu(event)}>
          <MenuIcon height={16} width={16} />
        </button>
        <ContextMenu
          ref={actionMenuRef}
          position={position}
          show={showMenu}
          close={() => {
            setShowMenu(false);
          }}
          actions={actions.map((action) => ({
            onMouseDown: () => action.onClick(entry, {}),
            title: action.tooltip,
            Icon: ({ color }: any) => (
              <action.icon width={16} height={16} color={color} />
            )
          }))}
        />
      </div>
    );
  }
  return (
    <div className='tr-icon-container-container'>
      {actions.map(
        ({ id, tooltip, icon: ActionIcon, iconClassname, onClick }) => (
          <OverlayTrigger
            key={id}
            placement='top'
            overlay={<Tooltip>{tooltip}</Tooltip>}
          >
            <div className='tr-icon-container'>
              <ActionIcon
                height={16}
                width={16}
                color='#414859'
                className={classNames('tr-icon', iconClassname)}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClick(entry, e);
                }}
              />
            </div>
          </OverlayTrigger>
        )
      )}
    </div>
  );
}
