import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FilterItem, FilterItemState } from './index';
import { CloseIcon } from '../Icons';
import styles from './styles.module.scss';

// Filter indicator "crumb" component that shows the current state of active filters.
// One for each active filter.
const FilterIndicator = ({
  filterState,
  filterItem,
  revealFilterDialog,
  onDelete
}: {
  filterState: FilterItemState;
  filterItem: FilterItem;
  revealFilterDialog: (
    filterItem: FilterItem,
    filterState: FilterItemState
  ) => void;
  onDelete: () => void;
}) => {
  return (
    <div
      className={styles.filterIndicator}
      onClick={(e) => {
        revealFilterDialog(filterItem, filterState);
      }}
    >
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>{filterState.displayState}</Tooltip>}
      >
        <span className={styles.filterIndicatorLabel}>
          {filterState.displayState}
        </span>
      </OverlayTrigger>

      <div
        className={styles.close}
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
      >
        <CloseIcon width={14} height={14} />
      </div>
    </div>
  );
};
export default FilterIndicator;
