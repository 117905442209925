function UndoIcon({
  width = 32,
  height = 32,
  disabled,
  color = '#FFFFFF'
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      className={disabled ? '' : 'undo-redo-enabled'}
      stroke={color}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.6253 9.27527H18.5977C23.8051 9.27527 28 13.432 28 18.5922C28 23.7523 23.8051 27.9091 18.5977 27.9091H4'
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.3636 13.3551L10.1477 9.17754L14.3636 4.99994'
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default UndoIcon;
