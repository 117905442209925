import { FC, useMemo, useRef, useState } from 'react';

import Table from '../Table';
import { ContextMenu } from '../Core';
import { useGlobalMouseDownToggle } from '../Core/util';
import FilterIndicator from './FilterIndicator';

import {
  ChevronDownIcon,
  FileIcon,
  FolderIcon,
  PlusIcon,
  RefreshIcon,
  SearchIcon
} from '../Icons';
import FilterOverlay from './FilterOverlay';

import styles from './styles.module.scss';
import classNames from 'classnames';
import testIds from '../../utils/testIds';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../Core/Tabs';
import { FolderList } from './FolderList';
import { groupByFolder } from './utils';
import useFolderPreference from '../../hooks/useFolderPreference';
import CreateFolderModal from '../Modals/CreateFolderModal';

export type SortDirection = 'ascending' | 'descending' | '';
export type SearchType = '' | 'client' | 'server';

export type FilterWidgetProps = {
  paramName: string; // The filter param name is dynamic and can change based on user selection
  paramValue: string;
  onChange: (
    filterStateId: string,
    paramName: string,
    paramValue: string,
    displayState: string,
    error?: string
  ) => void;
};
export type FilterWidget = FC<FilterWidgetProps>;
export type FilterItem = {
  id: string; // Unique identifier for the filter
  name: string; // Display name for the filter
  filterWidget: FilterWidget;
};
export type FilterItemState = {
  id: string; // filter state Id
  filterId: string; // filter Id
  paramName: string;
  paramValue: string;
  displayState: string; // Displayable state if the filter
};
export interface ActiveFilterState extends FilterItemState {
  error: string;
}
type ActionCallback = (entry: any, event: any) => void;

type Props = {
  name: string;
  columns: any[];
  data: any[];
  dataKey?: string;
  sortCallback?: (
    sortDirection: SortDirection,
    key: any,
    sortByKey: any
  ) => void;
  sortable?: boolean;
  defaultSort?: any;
  // useSearch = false => No search
  // useSearch = true and onSearch set => Search enabled and onSearch callback called (server filtering)
  // useSearch = true and onSearch not set => Search enabled and client side filtering
  useSearch?: boolean;
  onSearch?: (searchTerm: string) => void;
  filters?: Record<string, FilterItem>;
  initialFilterState?: Record<string, FilterItemState>;
  onFiltersChange?: (filters: Record<string, FilterItemState>) => void;
  hasFolders?: boolean;
  hasOverflowMenu?: boolean;
  onRefresh?: () => void;
  prevPage?: string;
  nextPage?: string;
  onPageChange?: (page: string) => void;
  onSelect?: (item: any) => void;
  onCreate?: (folder?: string | null) => void;
  createLabel?: string;
  onCopy?: ActionCallback;
  onShare?: ActionCallback;
  onDelete?: ActionCallback;
  onDownload?: ActionCallback;
  onMoveToFolder?: ActionCallback;
  downloadName?: string;
};

const HeaderFilterResultsTable = ({
  name,
  columns,
  data,
  dataKey = 'id',
  sortCallback,
  sortable = true,
  defaultSort = { order: 0 },
  useSearch,
  onSearch,
  filters = {},
  initialFilterState = {},
  onFiltersChange = () => {},
  hasFolders = false,
  hasOverflowMenu = false,
  onRefresh,
  prevPage = '',
  nextPage = '',
  onPageChange = () => {},
  onSelect = () => {},
  onCreate,
  createLabel = '',
  onCopy,
  onShare,
  onDelete,
  onDownload,
  onMoveToFolder,
  downloadName = ''
}: Props) => {
  const [searchInput, setSearchInput] = useState('');
  const [searchStr, setSearchStr] = useState('');
  const [timeoutFunc, setTimeoutFunc] = useState<any>();

  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);

  const {
    tab: selectedTab,
    folder: selectedFolder,
    setFolderPreference
  } = useFolderPreference(name);

  const tableSortCallback = (order: any, key: any, sortByKey: any) => {
    let sortDirection: SortDirection;
    if (order === -1) {
      sortDirection = 'descending';
    } else if (order === 1) {
      sortDirection = 'ascending';
    } else {
      sortDirection = '';
    }
    sortCallback && sortCallback(sortDirection, key, sortByKey);
  };

  // Text search
  const searchRef = useRef(null);
  const handleSearch = (e: any) => {
    const newStr = e.target.value;
    setSearchInput(newStr);
    const s = newStr.trim();
    if (timeoutFunc) clearTimeout(timeoutFunc);
    setTimeoutFunc(
      setTimeout(() => {
        if (onSearch) onSearch(s);
        else setSearchStr(s);
      }, 300)
    );
  };
  const handleFocus = () => {
    if (searchRef.current) {
      (searchRef.current as any).select();
    }
  };
  const filterFn = (str: any, dataItem: any) => {
    str = str.toLowerCase();
    return columns.some((col) => {
      const key = col.filterKey || col.key;
      const val = dataItem[key];
      if (Array.isArray(val)) {
        return val.some(
          (v) => typeof v === 'string' && v.toLowerCase().includes(str)
        );
      }

      return typeof val === 'string' && val.toLowerCase().includes(str);
    });
  };

  //////////////////////////////////////////
  // Filters
  const filterButtonRef = useRef<HTMLDivElement>(null);

  // Filter dropdown menu
  const selectFilterMenuRef = useRef<HTMLDivElement>(null);
  const [showFilterSelect, setShowFilterSelect] = useGlobalMouseDownToggle([
    selectFilterMenuRef
  ]);
  const [position, setPosition] = useState({});
  function setDialogPosition() {
    // The dialogs and menu will always be positioned relative to the filter + button
    const buttonRect = filterButtonRef.current?.getBoundingClientRect();
    if (buttonRect) {
      setPosition({
        x: buttonRect.x + buttonRect.width,
        y: buttonRect.y + buttonRect.height
      });
    }
  }

  function revealFilterMenu(event: any) {
    event.preventDefault();
    setDialogPosition();
    setShowFilterSelect(true);
  }

  // Filter dialog where user can set a filter value
  const filterDialogRef = useRef<HTMLDivElement>(null);
  const [showFilterDialog, setShowFilterDialog] = useGlobalMouseDownToggle([
    filterDialogRef
  ]);
  // State for the filter being configured in the dialog
  const [activeFilterState, setActiveFilterState] =
    useState<ActiveFilterState>();

  // Overall Filters state
  const [filtersState, setFiltersState] =
    useState<Record<string, FilterItemState>>(initialFilterState);

  const [selectedFilterItem, setSelectedFilterItem] = useState<FilterItem>();
  const revealFilterDialog = (
    filter: FilterItem,
    existingFilterState?: FilterItemState
  ) => {
    if (filters && Object.keys(filters).length > 0) {
      setSelectedFilterItem(filter);
      // If not called for previously configured filter try to find single instance filters
      // in the filter state map so that we modify the existing one.
      let _activeFilterState = existingFilterState;
      if (!_activeFilterState)
        _activeFilterState = filtersState[filter.id] ?? {
          id: filter.id,
          filterId: filter.id,
          paramName: '',
          paramValue: '',
          error: 'Invalid filter state'
        };
      setActiveFilterState({ error: '', ..._activeFilterState });
      setDialogPosition();
      setShowFilterDialog(true);
    }
  };
  // Selected filter widget to be rendered in the filter dialog
  const SelectedFilter = selectedFilterItem?.filterWidget;
  const hasActiveFilters = Object.keys(filtersState).length > 0;

  //////////////////////////////////////////

  const formsGroupedByFolder = useMemo(() => {
    return groupByFolder(data);
  }, [data]);

  const table = (
    <Table
      name={name}
      columns={columns}
      data={
        (selectedFolder != null
          ? formsGroupedByFolder[selectedFolder]
          : data) ?? []
      }
      filterCallback={useSearch ? filterFn : null}
      searchStr={searchStr}
      sortable={sortable}
      sortCallback={sortCallback ? tableSortCallback : null}
      defaultSort={defaultSort}
      idKey={dataKey}
      className={styles.filterableTable}
      hasOverflowMenu={hasOverflowMenu}
      onSelect={onSelect}
      onCopy={onCopy}
      onShare={onShare}
      onDelete={onDelete}
      onDownload={onDownload}
      onMoveToFolder={hasFolders ? onMoveToFolder : undefined}
      downloadName={downloadName}
    />
  );

  const showCreateFolder = selectedTab === 'folders' && selectedFolder == null;
  return (
    <>
      <Tabs
        value={selectedTab}
        onValueChange={(tab) => setFolderPreference(tab, null)}
      >
        <div className={styles.resultsTable}>
          <div className={styles.tableActionsBar}>
            <div className={styles.refreshSearch}>
              {onRefresh && (
                <button
                  className={classNames('btn', styles.refreshButton)}
                  onClick={onRefresh}
                >
                  <RefreshIcon color={'#212529'} />
                </button>
              )}
              <div className='search'>
                {useSearch && (
                  <div className='search-wrap'>
                    <SearchIcon className='search-icon' />
                    <input
                      type='text'
                      className='inp list-view-search'
                      value={searchInput}
                      placeholder='Search'
                      onChange={handleSearch}
                      ref={searchRef}
                      onFocus={handleFocus}
                    />
                  </div>
                )}
              </div>
              {hasFolders &&
                (selectedFolder != null ? (
                  <div
                    style={{ display: 'flex', gap: 6, alignItems: 'center' }}
                  >
                    <button
                      className='tw-rounded-lg tw-border-none tw-text-grey-700 tw-inline-flex tw-items-center tw-h-[34px] tw-justify-center tw-w-[34px] hover:tw-text-grey-900 hover:tw-bg-grey-100 tw-transition-colors'
                      onClick={() => setFolderPreference('folders', null)}
                    >
                      <ChevronDownIcon className='tw-stroke-current tw-h-[14px] [&_path]:tw-stroke-[4] tw-rotate-90' />
                    </button>
                    <p style={{ margin: 0 }}>{selectedFolder || 'No Folder'}</p>
                  </div>
                ) : (
                  <TabsList className='tw-h-[42px] tw-rounded-lg'>
                    <TabsTrigger
                      value='files'
                      className='tw-gap-2 tw-h-[36px] tw-rounded'
                    >
                      <FileIcon /> {`${name}s`}
                    </TabsTrigger>
                    <TabsTrigger
                      disabled={data.length === 0}
                      value='folders'
                      className='tw-gap-2 tw-h-[36px] tw-rounded'
                    >
                      <FolderIcon /> Folders
                    </TabsTrigger>
                  </TabsList>
                ))}
            </div>
            <div className={styles.filterAndCreateContainer}>
              <div className={styles.filterContainer}>
                {Object.keys(filters).length > 0 && (
                  <>
                    {hasActiveFilters && <span>Filtered By:</span>}
                    {Object.values(filtersState)
                      .sort((a, b) =>
                        a.displayState.localeCompare(b.displayState)
                      )
                      .map((filterState, i) => (
                        <FilterIndicator
                          key={i}
                          filterState={filterState}
                          filterItem={filters[filterState.filterId]}
                          revealFilterDialog={revealFilterDialog}
                          onDelete={() => {
                            const { id } = filterState;
                            const newfiltersState = {
                              ...filtersState
                            };
                            delete newfiltersState[id];
                            setFiltersState(newfiltersState);
                            onFiltersChange(newfiltersState);
                          }}
                        />
                      ))}
                    <div
                      ref={filterButtonRef}
                      className={classNames(styles.filterButton, {
                        [styles.activeFilters]: hasActiveFilters
                      })}
                      onClick={revealFilterMenu}
                    >
                      <PlusIcon width={20} height={20} />
                      {!hasActiveFilters && 'Filter'}
                    </div>
                    <ContextMenu
                      ref={selectFilterMenuRef}
                      position={position as { x: number; y: number }}
                      show={showFilterSelect}
                      close={() => setShowFilterSelect(false)}
                      actions={Object.values(filters).map((filter, i) => ({
                        onMouseDown: () => revealFilterDialog(filter),
                        title: filter.name
                      }))}
                    />
                    {SelectedFilter && activeFilterState && (
                      <FilterOverlay
                        ref={filterDialogRef}
                        show={showFilterDialog}
                        close={() => setShowFilterDialog(false)}
                        position={position as { x: number; y: number }}
                        SelectedFilter={SelectedFilter}
                        activeFilterState={activeFilterState}
                        setActiveFilterState={setActiveFilterState}
                        onSave={() => {
                          const {
                            id,
                            filterId,
                            paramName,
                            paramValue,
                            displayState
                          } = activeFilterState;
                          const newfiltersState = {
                            ...filtersState,
                            [id]: {
                              id,
                              filterId,
                              paramName,
                              paramValue,
                              displayState
                            }
                          };
                          setFiltersState(newfiltersState);
                          onFiltersChange(newfiltersState);
                        }}
                        onDelete={() => {
                          const { id } = activeFilterState;
                          const newfiltersState = {
                            ...filtersState
                          };
                          delete newfiltersState[id];
                          setFiltersState(newfiltersState);
                          onFiltersChange(newfiltersState);
                        }}
                        newFilter={Boolean(
                          activeFilterState?.id &&
                            !filtersState[activeFilterState.id]
                        )}
                        filterName={filters[activeFilterState.filterId].name}
                        anchor='top_right'
                      />
                    )}
                  </>
                )}
              </div>
              {onCreate && !showCreateFolder && (
                <button
                  data-testid={testIds.createButton(name)}
                  className={classNames(
                    'btn',
                    'btn-custom',
                    'list-view-action',
                    styles.createButton
                  )}
                  onClick={() => onCreate(selectedFolder)}
                >
                  {createLabel || `Create ${name}`}
                </button>
              )}
              {showCreateFolder && (
                <button
                  className={classNames(
                    'btn',
                    'btn-custom',
                    'list-view-action',
                    styles.createButton
                  )}
                  onClick={() => setShowCreateFolderModal(true)}
                >
                  Create Folder
                </button>
              )}
            </div>
          </div>
          {selectedFolder != null ? (
            table
          ) : (
            <>
              <TabsContent value='folders' className={styles.folderList}>
                <FolderList
                  folderData={formsGroupedByFolder}
                  onClick={(folder) => setFolderPreference('folders', folder)}
                  name={name}
                />
              </TabsContent>
              <TabsContent value='files'>
                {table}
                {prevPage && (
                  <button
                    className='btn btn-custom list-view-action'
                    onClick={() => onPageChange(prevPage)}
                  >
                    Previous
                  </button>
                )}
                {nextPage && (
                  <button
                    className='btn btn-custom list-view-action'
                    onClick={() => onPageChange(nextPage)}
                  >
                    Next
                  </button>
                )}
              </TabsContent>
            </>
          )}
        </div>
      </Tabs>
      <CreateFolderModal
        show={showCreateFolderModal}
        setShow={setShowCreateFolderModal}
        name={name}
      />
    </>
  );
};

export default HeaderFilterResultsTable;
