import { useMemo } from 'react';

// importing from .../types below to avoid INTEGRATIONS undefined issue (probably circular dependency)
import { INTEGRATIONS } from '../../../components/Integrations/types';
import useIntegrations from '../../../components/Integrations/useIntegrations';
import { useAppSelector } from '../../../hooks';
import {
  Product,
  Price
} from '../../Panels/Sections/ClickActionSection/useStripeActions';

interface ProductCatalogItem {
  isLiveTestPair: boolean;
  products: Product[];
}

/**
 * Stripe product catalog data.
 *
 */
export const useProductCatalog = () => {
  const formId = useAppSelector((state: any) => state.formBuilder.formId);

  const integrations = useIntegrations({
    types: [INTEGRATIONS.STRIPE],
    panelId: formId
  });
  const allTestProducts: Record<string, Product> =
    integrations[INTEGRATIONS.STRIPE]?.data.metadata.test
      ?.product_price_cache ?? {};
  const allLiveProducts: Record<string, Product> =
    integrations[INTEGRATIONS.STRIPE]?.data.metadata.live
      ?.product_price_cache ?? {};

  const getDuplicateProductNames = (
    products: Record<string, Product>
  ): Set<string> => {
    const duplicateProductNames = new Set<string>();
    const productNames = Object.values(products).map((product) => product.name);
    productNames.forEach((name) => {
      if (productNames.filter((n) => n === name).length > 1)
        duplicateProductNames.add(name);
    });
    return duplicateProductNames;
  };
  const isProductNameAPair = (
    name: string,
    products: Record<string, Product>
  ): boolean =>
    Object.values(products).filter((p) => p.name === name).length === 2;

  // Hash of product names to array of product objects (test and live and possibly dups)
  const productCatalog = useMemo(() => {
    const allProducts = { ...allTestProducts, ...allLiveProducts };

    // The possibility of duplicate live or duplicate test product names forces them to
    // be kept separate and not treated as "clean" live/test pairs.
    // Figure out any duplicates.
    const duplicateTestProductNames = getDuplicateProductNames(allTestProducts);
    const duplicateLiveProductNames = getDuplicateProductNames(allLiveProducts);

    const addToCatalog = (
      product: Product,
      mode: 'TEST' | 'LIVE',
      duplicateProductNames: Set<string>,
      productCatalog: Record<string, ProductCatalogItem>
    ): Record<string, ProductCatalogItem> => {
      let nameKey = `${product.name} (${mode})`;
      let isLiveTestPair = false;
      if (
        !duplicateProductNames.has(product.name) &&
        isProductNameAPair(product.name, allProducts)
      ) {
        nameKey = `${product.name} (LIVE / TEST)`;
        isLiveTestPair = true;
      }

      if (!productCatalog[nameKey]) {
        productCatalog[nameKey] = { isLiveTestPair, products: [] };
      }
      productCatalog[nameKey].products.push(product);
      return productCatalog;
    };

    const productCatalog: Record<string, ProductCatalogItem> = Object.values(
      allTestProducts
    ).reduce((pc, product) => {
      return addToCatalog(product, 'TEST', duplicateTestProductNames, pc);
    }, {});
    return Object.values(allLiveProducts).reduce((pc, product) => {
      return addToCatalog(product, 'LIVE', duplicateLiveProductNames, pc);
    }, productCatalog);
  }, [allTestProducts, allLiveProducts]);

  // Id to "friendly" product name hash
  const productCatalogNamesById = useMemo(() => {
    const productCatalogById: Record<string, string> = {};
    Object.entries(productCatalog).forEach(
      ([productName, productCatalogItem]) => {
        productCatalogItem.products.forEach((product) => {
          productCatalogById[product.id] = productName;
        });
      }
    );
    return productCatalogById;
  }, [productCatalog]);

  return {
    productCatalog,
    productCatalogNamesById
  };
};

// get the default price if there is one, otherwise just get the first price
export const getDefaultPrice = (
  productPriceItem: Product
): Price | undefined => {
  let priceObj: Price | undefined;
  if (productPriceItem.default_price) {
    // find the price with the default price id
    priceObj = productPriceItem.prices.find(
      (price) => price.id === productPriceItem.default_price
    );
  }
  if (!priceObj && productPriceItem.prices.length > 0)
    priceObj = productPriceItem.prices[0];
  return priceObj;
};
