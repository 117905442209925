import { memo } from 'react';

import useFeatheryRedux from '../../redux';
import Dialog from '../Dialog';
import { Negative, Neutral } from '../Core/Button';

function DeleteDocumentModal({ show, close, documentId, name }: any) {
  const { deleteDocument } = useFeatheryRedux();

  return (
    <Dialog
      isOpen={show}
      onClose={() => close()}
      shouldShowCloseIcon={false}
      title={`Are you sure you want to delete ${name}?`}
      alignCenter
      style={{ padding: '24px 40px' }}
    >
      <p style={{ padding: '0 70px', margin: '20px 0 0' }}>
        You will lose access to the document immediately
      </p>
      <div className='dialog-form-action'>
        <Neutral title='Cancel' onClick={() => close()} />
        <Negative
          title='Yes, Delete Form'
          onClick={async (event: any) => {
            event.preventDefault();
            await deleteDocument({ documentId: documentId });
            close();
          }}
        />
      </div>
    </Dialog>
  );
}

export default memo(DeleteDocumentModal);
