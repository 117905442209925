import * as Icons from '../../Icons';

import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import {
  BorderStyleInput,
  CheckboxField,
  CollapsibleSection,
  FColorPicker,
  NumberInput,
  PropertyLabel,
  RadioButtonGroup
} from '../../Core';
import {
  assetUpdateOperations,
  BORDER_PROPS,
  elementOperation,
  getAsset,
  HOVER_BORDER_PROPS,
  level2UpdateOperations
} from '../../../utils/themes';
import { memo } from 'react';

import { RichTextEditor } from './components';
import { calculateOverrideObjects } from '../utils';
import { objectPick } from '../../../utils/core';
import { TYPE_TEXT } from '../../../utils/elements';
import { Viewport } from '../../RenderingEngine/GridInGrid/engine';
import { AXIS, SIZE_UNITS } from '../../../utils/constants';
import styles from './styles.module.scss';
import BoxSpacingInput from '../../Core/BoxSpacingInput';

function TextStylePanel({
  mode,
  viewport,
  theme,
  baseProps,
  baseStyle,
  overrideProps,
  overrideStyle,
  handleUpdates,
  isAsset,
  cellNode
}: any) {
  const { result: element } = calculateOverrideObjects(
    baseProps,
    overrideProps,
    true
  );
  const { result: elementStyle, isOverride: isOverrideStyle } =
    calculateOverrideObjects(baseStyle, overrideStyle);
  const asset = getAsset(theme, TYPE_TEXT, element.source_asset);

  function handleStyleChange(styleUpdate: any) {
    handleUpdates([elementOperation({ viewport, styleUpdate })]);
  }

  function labelData(...props: string[]) {
    const newStyle = objectPick(elementStyle, props);
    const key = viewport === Viewport.Mobile ? 'Desktop' : 'Theme';
    return {
      mode,
      highlighted: isOverrideStyle(props),
      themeOperations: {
        instance: {
          label: `Reset to ${key}`,
          operation: () => {
            handleUpdates([elementOperation({ viewport, styleReset: props })]);
          }
        },
        ...(mode === 'builder' && {
          level2: {
            label: 'Text Elements',
            operation: () => {
              handleUpdates(
                level2UpdateOperations({
                  viewport,
                  newStyle,
                  asset,
                  elementType: TYPE_TEXT,
                  level2: 'text'
                })
              );
            }
          }
        }),
        ...(asset && {
          asset: {
            label: asset.key,
            operation: () => {
              handleUpdates(
                assetUpdateOperations({
                  viewport,
                  elementType: TYPE_TEXT,
                  asset,
                  newStyle
                })
              );
            }
          }
        })
      }
    };
  }

  return (
    <>
      <CollapsibleSection title='Layout' collapsible>
        <Row>
          <Col sm='3'>
            <PropertyLabel
              label='Width'
              {...labelData('width', 'width_unit')}
            />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={elementStyle.width}
              unit={elementStyle.width_unit}
              units={['px', '%', 'fit']}
              onComplete={({ value, unit }: any) =>
                handleStyleChange({
                  width: unit === SIZE_UNITS.FIT ? '' : value,
                  width_unit: unit
                })
              }
              triggerCleanUp
              dimension='width'
            />
          </Col>
          <Col sm='3'>
            <PropertyLabel
              label='Height'
              indented
              {...labelData('height', 'height_unit')}
            />
          </Col>
          <Col>
            <NumberInput
              required
              min={0}
              value={elementStyle.height}
              unit={elementStyle.height_unit}
              units={['px', '%', 'fit']}
              onComplete={({ value, unit }: any) =>
                handleStyleChange({
                  height: unit === SIZE_UNITS.FIT ? '' : value,
                  height_unit: unit
                })
              }
              triggerCleanUp
              dimension='height'
            />
          </Col>
        </Row>
        <BoxSpacingInput
          elementStyle={elementStyle}
          handleStyleChange={handleStyleChange}
          labelData={labelData}
          elementType={TYPE_TEXT}
        />
        {cellNode && (
          <Row>
            <Col sm='3'>
              <PropertyLabel
                label='Align Self'
                {...labelData('layout', 'vertical_layout')}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.layout}
                name='text-editor-horizontal-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.COL}
                onChange={(value: any) => handleStyleChange({ layout: value })}
                options={[
                  { value: 'left', display: <Icons.HorizontalLeftIcon /> },
                  { value: 'center', display: <Icons.HorizontalCenterIcon /> },
                  { value: 'right', display: <Icons.HorizontalRightIcon /> }
                ]}
              />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.vertical_layout}
                name='text-editor-vertical-alignment'
                allowUnselect
                hideValueIfDisabled
                disabled={!cellNode.parent || cellNode.parent.axis === AXIS.ROW}
                onChange={(value: any) =>
                  handleStyleChange({ vertical_layout: value })
                }
                options={[
                  { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                  { value: 'center', display: <Icons.VerticalCenterIcon /> },
                  { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
                ]}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col sm='3'>
            <PropertyLabel
              label='Align Content'
              {...labelData('horizontal_align', 'vertical_align')}
            />
          </Col>
          <Col>
            <RadioButtonGroup
              selected={elementStyle?.horizontal_align}
              name='text-editor-horizontal-alignment'
              onChange={(value: any) =>
                handleStyleChange({ horizontal_align: value })
              }
              options={[
                {
                  value: 'flex-start',
                  display: <Icons.HorizontalLeftIcon />
                },
                {
                  value: 'center',
                  display: <Icons.HorizontalCenterIcon />
                },
                {
                  value: 'flex-end',
                  display: <Icons.HorizontalRightIcon />
                }
              ]}
            />
          </Col>
          <Col>
            <RadioButtonGroup
              selected={elementStyle?.vertical_align}
              name='text-editor-vertical-alignment'
              onChange={(value: any) =>
                handleStyleChange({ vertical_align: value })
              }
              options={[
                { value: 'flex-start', display: <Icons.VerticalTopIcon /> },
                { value: 'center', display: <Icons.VerticalCenterIcon /> },
                { value: 'flex-end', display: <Icons.VerticalBottomIcon /> }
              ]}
            />
          </Col>
        </Row>
        {mode !== 'theme' && (
          <Row>
            <Col sm='3'>
              <PropertyLabel label='Visibility' />
            </Col>
            <Col>
              <RadioButtonGroup
                selected={elementStyle.visibility}
                name='text-editor-visibility'
                onChange={(value: any) =>
                  handleStyleChange({ visibility: value })
                }
                options={[
                  {
                    value: 'visible',
                    display: <Icons.ShowIcon width={18} height={18} />
                  },
                  {
                    value: 'hidden',
                    display: <Icons.HideIcon width={18} height={18} />
                  }
                ]}
              />
            </Col>
          </Row>
        )}
      </CollapsibleSection>

      <CollapsibleSection title='Typography' collapsible>
        <RichTextEditor
          mode={mode}
          level2={{ label: 'Text Elements', type: 'text' }}
          viewport={viewport}
          baseProps={baseProps}
          overrideProps={overrideProps}
          elementType={TYPE_TEXT}
          baseStyle={baseStyle}
          overrideStyle={overrideStyle}
          handleUpdates={handleUpdates}
          theme={theme}
          isAsset={isAsset}
          labelCustomData={labelData}
          handleCustomStyleChange={handleStyleChange}
          customValues={{
            line_height: elementStyle.line_height
          }}
        />
      </CollapsibleSection>

      <Tabs defaultActiveKey='DefaultColors'>
        <Tab
          eventKey='DefaultColors'
          title='Default'
          className={styles.stylesTab}
          style={{ padding: '0 1em' }}
        >
          <Row>
            <Col sm='4'>
              <PropertyLabel
                label='Background'
                {...labelData('background_color')}
              />
            </Col>
            <Col>
              <FColorPicker
                value={elementStyle.background_color}
                onChange={(color: any) =>
                  handleStyleChange({ background_color: color })
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <PropertyLabel label='Border' {...labelData(...BORDER_PROPS)} />
            </Col>
          </Row>
          <Row>
            <Col>
              <BorderStyleInput
                elementStyles={elementStyle}
                onComplete={handleStyleChange}
              />
            </Col>
          </Row>
        </Tab>
        {[
          {
            eventKey: 'HoverColors',
            title: 'Hover',
            pre: (prop: string) => `hover_${prop}`,
            prefix: 'hover_',
            borderProps: HOVER_BORDER_PROPS
          }
        ].map(({ eventKey, title, pre, prefix, borderProps }) => (
          <Tab
            key={eventKey}
            eventKey={eventKey}
            title={title}
            className={styles.stylesTab}
            style={{ padding: '0 1em' }}
          >
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle[pre('font_color')]}
                  onChange={(value) =>
                    handleStyleChange({
                      [pre('font_color')]: value ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Font'
                      {...labelData(pre('font_color'))}
                    />
                  }
                />
              </Col>
              {elementStyle[pre('font_color')] && (
                <Col>
                  <FColorPicker
                    value={elementStyle[pre('font_color')]}
                    onChange={(color: any) =>
                      handleStyleChange({ [pre('font_color')]: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle[pre('background_color')]}
                  onChange={(value) =>
                    handleStyleChange({
                      [pre('background_color')]: value ? '000000FF' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Background'
                      {...labelData(pre('background_color'))}
                    />
                  }
                />
              </Col>
              {elementStyle[pre('background_color')] && (
                <Col>
                  <FColorPicker
                    value={elementStyle[pre('background_color')]}
                    onChange={(color: any) =>
                      handleStyleChange({ [pre('background_color')]: color })
                    }
                    colorPickerStyle={{ left: '-30px' }}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col sm='5'>
                <CheckboxField
                  checked={!!elementStyle[pre('border_top_color')]}
                  onChange={(value) =>
                    handleStyleChange({
                      [pre('border_top_color')]: value ? '000000FF' : '',
                      [pre('border_right_color')]: value ? '000000FF' : '',
                      [pre('border_bottom_color')]: value ? '000000FF' : '',
                      [pre('border_left_color')]: value ? '000000FF' : '',
                      [pre('border_top_width')]: 0,
                      [pre('border_right_width')]: 0,
                      [pre('border_bottom_width')]: 0,
                      [pre('border_left_width')]: 0,
                      [pre('border_top_pattern')]: value ? 'solid' : '',
                      [pre('border_right_pattern')]: value ? 'solid' : '',
                      [pre('border_bottom_pattern')]: value ? 'solid' : '',
                      [pre('border_left_pattern')]: value ? 'solid' : ''
                    })
                  }
                  text={
                    <PropertyLabel
                      label='Border'
                      {...labelData(...borderProps)}
                    />
                  }
                />
              </Col>
            </Row>
            {!!elementStyle[pre('border_top_color')] && (
              <Row>
                <Col>
                  <BorderStyleInput
                    elementStyles={elementStyle}
                    onComplete={handleStyleChange}
                    prefix={prefix}
                  />
                </Col>
              </Row>
            )}
          </Tab>
        ))}
      </Tabs>
    </>
  );
}

export default memo(TextStylePanel);
