import { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Neutral, Positive } from '../../../components/Core/Button';
import { useAppSelector } from '../../../hooks';
import { deepEquals } from '../../../utils/core';
import { ErrorMarkerIcon } from '../../../components/Icons';
import {
  DropdownField,
  DropdownMultiField,
  InlineTooltip,
  TextField
} from '../../../components/Core';
import {
  defaultQuestionGroup,
  getDefaultEntity,
  getDefaultQuestionGroups
} from '../utils';
import { ALLOWED_FIELD_TYPES, FIELDS_WITH_OPTIONS } from './constants';
import { getFieldOptions } from './utils';
import type { FieldProperties } from './types';
import produce from 'immer';
import useModalSubmissionLockout from '../../../utils/useModalSubmissionLockout';
import useDraftForm from '../../../utils/useDraftForm';
import useFeatheryRedux from '../../../redux';
import Label from '../../../components/Dialog/Label';
import Dialog from '../../../components/Dialog';
import QuestionGroup from '../components/QuestionGroup';
import styles from '../styles.module.scss';
import '../../../style/dialog-form.css';

export default function ExtractionCreateEditModal({
  extraction,
  isOpen,
  close
}: any) {
  const { formId } = useParams<{ formId: string }>();
  const history = useHistory();
  const {
    createAIExtraction,
    editAIExtraction,
    deleteAIExtraction,
    getFields
  } = useFeatheryRedux();
  const accounts = useAppSelector(
    (state) => state.accounts.organization?.team_accounts ?? []
  );

  const steps = Object.values(useDraftForm().steps ?? {});

  const {
    fileOptions,
    singleDataOptions,
    multipleDataOptions,
    fieldProperties
  } = useMemo(() => {
    const servarFileMap: Record<string, string> = {};
    const singleDataMap: Record<string, string> = {};
    const multipleDataMap: Record<string, string> = {};
    const fieldProperties: Record<string, any> = {};

    steps.forEach((step: any) =>
      step.servar_fields.forEach((field: any) => {
        const servar = field.servar;
        if (servar.type === 'file_upload') {
          servarFileMap[servar.id] = servar.key;
        } else if (ALLOWED_FIELD_TYPES[servar.type]) {
          const _fieldProperties: FieldProperties = {
            type: servar.type
          };

          if (FIELDS_WITH_OPTIONS.includes(servar.type)) {
            if (servar.type !== 'checkbox') {
              _fieldProperties.options = getFieldOptions(servar);
            } else {
              _fieldProperties.options = [
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
              ];
            }
          }

          if (servar.semantic_description) {
            _fieldProperties.semanticDescription = servar.semantic_description;
          }

          if (servar.repeated) {
            multipleDataMap[servar.id] = servar.key;
          } else {
            singleDataMap[servar.id] = servar.key;
          }

          fieldProperties[servar.id] = _fieldProperties;
        }
      })
    );

    const options = [servarFileMap, singleDataMap, multipleDataMap].map(
      (fieldMap) =>
        Object.entries(fieldMap).map(([servarId, servarKey]) => ({
          label: servarKey,
          value: servarId
        }))
    );

    return {
      fileOptions: options[0] as { label: string; value: string }[],
      singleDataOptions: options[1] as { label: string; value: string }[],
      multipleDataOptions: options[2] as { label: string; value: string }[],
      fieldProperties: fieldProperties as Record<string, FieldProperties>
    };
  }, [steps]);

  const hiddenFields = useAppSelector(
    (state) =>
      (state.fields.hiddenFields ?? []).filter(
        (field) => !field.key.startsWith('feathery.')
      ),
    deepEquals
  );

  const { hiddenFieldOptions, hiddenFieldProperties } = useMemo(() => {
    const _hiddenFieldOptions: { label: string; value: string }[] = [];
    const _hiddenFieldProperties: Record<string, Partial<FieldProperties>> = {};

    hiddenFields.forEach((field) => {
      if (field.options && field.options.length) {
        _hiddenFieldProperties[field.id] = {
          options: field.options.map((option: string) => ({
            label: option,
            value: option
          }))
        };
      }

      _hiddenFieldOptions.push({
        label: field.key,
        value: field.id
      });
    });

    return {
      hiddenFieldOptions: _hiddenFieldOptions,
      hiddenFieldProperties: _hiddenFieldProperties
    };
  }, [hiddenFields]);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<{
    key: string;
    file_sources: string[];
    question_groups: any[];
    file_type: string;
    reviewers: string[];
  }>({
    key: extraction?.key ?? '',
    file_sources: extraction?.file_sources ?? [],
    question_groups: getDefaultQuestionGroups(extraction),
    file_type: extraction?.file_type ?? 'image',
    reviewers: extraction?.reviewers ?? []
  });

  const usedFields = useMemo(() => {
    return [
      ...formData.file_sources,
      ...formData.question_groups.reduce((acc, group) => {
        return [...acc, ...group.field_ids];
      }, [])
    ];
  }, [formData]);

  const handleChange = (key: any, val: any) => {
    setFormData((formData) => ({
      ...formData,
      [key]: val
    }));
  };

  const removeQuestionGroup = (index: number) => {
    const newGroups = produce(formData.question_groups, (draft) => {
      if (draft.length === 1) {
        draft[0] = defaultQuestionGroup();
        return;
      } else {
        draft.splice(index, 1);
      }
    });
    handleChange('question_groups', newGroups);
  };

  const addQuestionGroup = () => {
    handleChange('question_groups', [
      ...formData.question_groups,
      defaultQuestionGroup()
    ]);
  };

  const updateQuestionGroup = (index: number, key: string, val: any) => {
    const newGroups = produce(formData.question_groups, (draft) => {
      draft[index][key] = val;

      if (key === 'question_type') {
        draft[index].field_ids = [];
        draft[index].entities = [getDefaultEntity()];
        draft[index].criteria = '';
        draft[index].toggle_page_question = false;
      }

      if (key === 'entities') {
        draft[index].field_ids = draft[index][key].map(
          (e: any) => e.field_id ?? null
        );
      }
    });

    handleChange('question_groups', newGroups);
  };

  const handleSubmit = useCallback(() => {
    setLoading(true);
    setError('');

    (async () => {
      try {
        const parsedData = JSON.parse(JSON.stringify({ ...formData }));

        parsedData.question_groups = parsedData.question_groups.map(
          (group: any) => {
            group.field_ids = group.entities.map(
              (entity: any) => entity.field_id
            );

            group.entities = group.entities.map((entity: any) => {
              const { field_id, options, ...rest } = entity;

              if (
                !FIELDS_WITH_OPTIONS.includes(
                  fieldProperties[field_id]?.type
                ) &&
                !hiddenFieldProperties[field_id]?.options
              ) {
                rest.options = options;
              }

              return rest;
            });

            return group;
          }
        );

        const isValid = parsedData.question_groups.every((group: any) => {
          if (!group.entities.every((entity: any) => entity.name)) {
            setError('Please provide a name for each entity.');
            return false;
          }

          if (group.entities.length !== group.field_ids.length) {
            setError('Please select a field to save each entity to.');
            return false;
          }

          return true;
        });

        if (!isValid) {
          setLoading(false);
          return;
        }

        const submitData: Record<string, any> = {
          ...parsedData,
          panel: formId
        };

        if (extraction) submitData.extractionId = extraction.id;
        return (
          extraction
            ? editAIExtraction(submitData)
            : createAIExtraction(submitData)
        )
          .then(() => getFields({ hidden: true }))
          .then(() => close())
          .catch((err: any) => setError(err.message))
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setError((error as any).message);
        setLoading(false);
      }
    })();
  }, [formData, fieldProperties]);

  const { lockOutFlag, lockoutFunction } =
    useModalSubmissionLockout(handleSubmit);

  const handleDelete = async (e: any) => {
    e.preventDefault();
    try {
      await deleteAIExtraction({ extractionId: extraction.id });
      history.replace(`/forms/${formId}/ai`);
      close();
    } catch (error) {
      setError((error as any).message);
    }
  };

  const moveQuestionGroupUp = (index: number) => {
    const newGroups = produce(formData.question_groups, (draft) => {
      if (index > 0) {
        [draft[index - 1], draft[index]] = [draft[index], draft[index - 1]];
      }
    });

    handleChange('question_groups', newGroups);
  };

  const moveQuestionGroupDown = (index: number) => {
    const newGroups = produce(formData.question_groups, (draft) => {
      if (index < draft.length - 1) {
        [draft[index], draft[index + 1]] = [draft[index + 1], draft[index]];
      }
    });

    handleChange('question_groups', newGroups);
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      title={extraction ? 'Edit Extraction' : 'Create Extraction'}
      size='lg'
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          return lockoutFunction();
        }}
      >
        <Label>Name</Label>
        <TextField
          value={formData.key}
          placeholder='My Extraction'
          onComplete={(newKey: string) => handleChange('key', newKey)}
          className={styles.modalInput}
          required
        />
        <div className={styles.labelContainer}>
          <Label>File Type</Label>
        </div>
        <DropdownField
          selected={formData.file_type}
          options={[
            { value: 'image', display: 'Documents or images' },
            { value: 'csv', display: 'Spreadsheets or CSVs' }
          ]}
          onChange={(event: any) =>
            handleChange('file_type', event.target.value)
          }
          className={styles.modalInput}
          required
        />
        <div className={styles.labelContainer}>
          <Label>File Sources</Label>
          <InlineTooltip text='The extraction will run on files stored in these fields' />
        </div>
        <DropdownMultiField
          selected={formData.file_sources}
          options={[...fileOptions, ...hiddenFieldOptions].filter(
            (option) =>
              formData.file_sources.includes(option.value) ||
              !usedFields.includes(option.value)
          )}
          onChange={(items: { value: string }[]) =>
            handleChange(
              'file_sources',
              items.map((item) => item.value)
            )
          }
          className={styles.modalInput}
          required
        />
        <div className={styles.labelContainer}>
          <Label>Reviewers</Label>
          <InlineTooltip text='Reviewers will be notified via email to review & approve new extraction runs.' />
        </div>
        <DropdownMultiField
          selected={formData.reviewers}
          options={accounts.map((account: any) => ({
            label: account.email,
            value: account.id
          }))}
          onChange={(items: { value: string }[]) =>
            handleChange(
              'reviewers',
              items.map((item) => item.value)
            )
          }
          className={styles.modalInput}
        />
        {formData.question_groups.map((group, index) => {
          return (
            <QuestionGroup
              key={group.id}
              index={index}
              group={group}
              formData={formData}
              usedFields={usedFields}
              singleDataOptions={singleDataOptions}
              multipleDataOptions={multipleDataOptions}
              hiddenFieldOptions={hiddenFieldOptions}
              fieldProperties={fieldProperties}
              hiddenFieldProperties={hiddenFieldProperties}
              onRemove={() => removeQuestionGroup(index)}
              onMoveUp={() => moveQuestionGroupUp(index)}
              onMoveDown={() => moveQuestionGroupDown(index)}
              onUpdate={(key: string, val: any) =>
                updateQuestionGroup(index, key, val)
              }
            />
          );
        })}
        <div
          onClick={() => addQuestionGroup()}
          className={styles.addGroupButton}
        >
          Add Query
        </div>
        {error && (
          <div className={styles.error}>
            <div className={styles.icon}>
              <ErrorMarkerIcon height={30} width={30} />
            </div>
            <div className={styles.content}>
              <div className={styles.title}>{error}</div>
              <div className={styles.message}>
                Please try again or contact support if the problem persists
              </div>
            </div>
          </div>
        )}
        <div className='dialog-form-action text-center'>
          {extraction && <Neutral title='Delete' onClick={handleDelete} />}
          <Positive
            title={extraction ? 'Save' : 'Create'}
            type='submit'
            lockoutOverride={lockOutFlag}
            loading={loading}
          />
        </div>
      </form>
    </Dialog>
  );
}
