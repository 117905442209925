import { useEffect, useMemo, useRef, useState } from 'react';
import { AIExtractionEntryValue } from '../../types';
import DataLayerEntry from './DataLayerEntry';
import styles from '../styles.module.scss';

type Props = {
  entries: AIExtractionEntryValue[];
  page: number;
};

const DataLayerPage = ({ entries, page }: Props) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const dataPageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const pageEl = document.querySelector(`div[data-page-number="${page}"]`);
    if (!pageEl) {
      return;
    }

    const setDataLayerDimensions = () => {
      const { width, height } = pageEl.getBoundingClientRect();
      setDimensions({ width, height });
    };

    setDataLayerDimensions();

    const observer = new ResizeObserver(() => setDataLayerDimensions());
    observer.observe(pageEl);

    return () => {
      observer.disconnect();
    };
  }, [page]);

  const EntryComponents = useMemo(() => {
    return entries.map((entry) => (
      <DataLayerEntry key={`${entry.id}-${entry.index}`} entry={entry} />
    ));
  }, [entries]);

  return (
    <div
      id='data-layer-page'
      ref={dataPageRef}
      className={styles.dataLayerPage}
      style={dimensions}
    >
      {EntryComponents}
    </div>
  );
};

export default DataLayerPage;
