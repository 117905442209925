import '../../style/dialog-form.css';
import { useAppSelector } from '../../hooks';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import IntegrationsSidebar from './IntegrationsSidebar';

function DocumentsIntegrationSidebar() {
  const org = useAppSelector((state) => state.accounts.organization) ?? {};
  const { documents_limit: documentsLimit } = org;
  const documentsEnabled = org.enterprise_features.documents ?? false;

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.DOCUMENTS]}
      showConnectedStatus={false}
    >
      <>
        <h5>Documentation</h5>
        <a
          href='https://docs.feathery.io/platform/documents#autogenerate-a-new-document-from-forms'
          target='_blank'
          rel='noreferrer'
        >
          Generate PDFs from form submissions
        </a>
        <a
          href='https://docs.feathery.io/platform/documents#autofill-a-document-template-from-forms'
          target='_blank'
          rel='noreferrer'
        >
          Autofill document templates with form data
        </a>
        <a
          href='https://docs.feathery.io/platform/documents#manually-request-signatures'
          target='_blank'
          rel='noreferrer'
        >
          Sign forms directly
        </a>
        {!documentsEnabled && (
          <>
            <br />
            <h5>Please Note</h5>
            <p>
              Your plan limits you to {documentsLimit} documents per month. If
              you would like to remove this limit, please reach out to&nbsp;
              <a
                href='https://www.feathery.io/demo'
                target='_blank'
                rel='noreferrer'
              >
                sales
              </a>
              .
            </p>
          </>
        )}
        <br />
        <h5>Ready to use this integration?</h5>
        <p>
          Click{' '}
          <a href='/documents' rel='noreferrer'>
            here
          </a>
          .
        </p>
      </>
    </IntegrationsSidebar>
  );
}

export default DocumentsIntegrationSidebar;
