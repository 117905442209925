import '../../style/dialog-form.css';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import { PropertyLabel, TextField } from '../Core';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import Combobox from '../Core/Combobox';
import Label from '../Dialog/Label';
import styles from './styles.module.scss';

const DEFAULT_SETTINGS = {
  username: '',
  password: '',
  form_ids: []
};

function QuikSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.QUIK,
    panelId: formId,
    includeInactive: true
  });

  const [err, setErr] = useState('');
  const [isPartial, setIsPartial] = useState(false);

  const [quikConfig, setQuikConfig] = useState(
    integration?.data.secret_metadata ?? DEFAULT_SETTINGS
  );

  const setField = (key: string, value: any) =>
    setQuikConfig((quikConfig: any) => ({
      ...quikConfig,
      [key]: value
    }));

  function onSubmitCustom(newIsActive: boolean) {
    if (quikConfig.form_ids.length === 0) {
      setErr('At least one Quik! Form ID is required');
      return;
    }
    setErr('');

    if (newIsActive) {
      const { username, password } = quikConfig;
      const partial = !username || !password;
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      secretMetadata: {
        form_ids: quikConfig.form_ids,
        username: quikConfig.username,
        password: quikConfig.password
      }
    };
  }

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.QUIK]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <Form className='integration-modal-form'>
        <Form.Group controlId='quik-credentials'>
          <Label>Authentication</Label>
          <PropertyLabel label='Username' />
          <TextField
            value={quikConfig.username}
            onChange={(value: string) => setField('username', value)}
            error={isPartial && !quikConfig.username}
            className={styles.marginBottom}
          />
          <PropertyLabel label='Password' />
          <TextField
            value={quikConfig.password}
            onChange={(value: string) => setField('password', value)}
            type='password'
            error={isPartial && !quikConfig.password}
            className={styles.marginBottom}
          />
        </Form.Group>
        <Form.Group controlId='quik-integration-config'>
          <Label>Properties</Label>
          <PropertyLabel label='Quik! Form IDs' />
          <Combobox
            value={quikConfig.form_ids || []}
            onChange={(items: string[]) => {
              setField('form_ids', items);
            }}
            placeholder='Add your Quik! Form IDs...'
          />
        </Form.Group>
      </Form>
    </IntegrationsSidebar>
  );
}

export default QuikSettingsSidebar;
