import '../../style/dialog-form.css';

import { Form } from 'react-bootstrap';
import { Fragment, useState } from 'react';

import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import {
  CollapsibleSection,
  DropdownField,
  PropertyLabel,
  TextField
} from '../Core';
import IntegrationsSidebar from './IntegrationsSidebar';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { produce } from 'immer';
import { FieldSelectorWithModal } from '../Modals';
import classNames from 'classnames';
import { PlusIcon, TrashIcon } from '../Icons';

export default function FlinksSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();

  const integration = useIntegrations({
    type: INTEGRATIONS.FLINKS,
    panelId: formId,
    includeInactive: true
  });

  const [isPartial, setIsPartial] = useState(false);
  const [err, setErr] = useState('');

  const secretMeta = integration?.data.secret_metadata ?? {};
  const [customerId, setCustomerId] = useState(secretMeta.customer_id ?? '');
  const [flinksFieldMap, setFlinksFieldMap] = useState<FlinksFieldMapType>(
    secretMeta.field_map || [defaultEntry()]
  );

  const meta = integration?.data.metadata ?? {};
  const [environment, setEnvironment] = useState(
    meta.environment ?? 'production'
  );
  const [instance, setInstance] = useState(meta.instance ?? '');

  function onSubmitCustom(newIsActive: boolean) {
    if (newIsActive) {
      const partial =
        (!customerId && environment === 'production') ||
        flinksFieldMap.some(
          (data) => data.flinks_attribute && !data.feathery_field
        );
      if (partial) setErr('Required fields must be filled');

      setIsPartial(partial);
      if (partial) return;
    }

    setErr('');
    return {
      isUpdate: integration?.data,
      metadata: { environment, instance },
      secretMetadata: {
        customer_id: customerId,
        field_map: flinksFieldMap
      }
    };
  }

  const updateFlinksMap = (newMap: FlinksFieldMapType) => {
    setFlinksFieldMap(
      produce(newMap, (draft) => {
        if (draft.length === 0) draft.push(defaultEntry());
      })
    );
  };

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.FLINKS]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
      customError={err}
    >
      <Form className='integration-modal-form'>
        <Form.Group controlId='flinks-credentials'>
          <PropertyLabel label='Environment Type' />
          <DropdownField
            selected={environment}
            onChange={(event: any) => setEnvironment(event.target.value)}
            options={[
              { value: 'production', display: 'Production' },
              { value: 'sandbox', display: 'Sandbox' }
            ]}
          />
        </Form.Group>
        {environment === 'production' && (
          <>
            <Form.Group controlId='flinks-credentials'>
              <PropertyLabel label='Environment Name' />
              <TextField
                value={instance}
                onChange={setInstance}
                error={isPartial && !instance}
              />
            </Form.Group>
            <Form.Group controlId='flinks-credentials'>
              <PropertyLabel label='Customer ID' />
              <TextField
                value={customerId} // https://docs.flinks.com/reference/getallattributes (default value of customer ID)
                onChange={setCustomerId}
                error={isPartial && !customerId}
              />
            </Form.Group>
          </>
        )}

        <CollapsibleSection
          title='Send Flinks Data to Fields'
          collapsible={false}
        >
          <div className={styles.twoColumnContainer}>
            <div className={styles.fieldHeaderText}>Flinks Attribute</div>
            <div className={styles.fieldHeaderText}>Feathery Field</div>
            {flinksFieldMap.map((data: any, index: number) => (
              <Fragment key={`${index}`}>
                <TextField
                  value={data.flinks_attribute}
                  onChange={(attr: string) =>
                    updateFlinksMap(
                      produce(flinksFieldMap, (draft) => {
                        draft[index] = {
                          ...draft[index],
                          flinks_attribute: attr
                        };
                      })
                    )
                  }
                />
                <div className={styles.fieldSelectorContainer}>
                  <FieldSelectorWithModal
                    selectId={data.feathery_field}
                    selectType={data.feathery_type}
                    placeholder='Select'
                    onSelect={(field) => {
                      updateFlinksMap(
                        produce(flinksFieldMap, (draft) => {
                          draft[index] = {
                            ...draft[index],
                            feathery_field: field.selectId,
                            feathery_type: field.selectType
                          };
                        })
                      );
                    }}
                    error={isPartial && !data.feathery_field}
                    className={classNames(
                      styles.marginBottom,
                      styles.fieldSelector
                    )}
                  />
                  <TrashIcon
                    height={16}
                    width={16}
                    className={classNames(
                      'tr-icon',
                      styles.customPropertyDelete
                    )}
                    onClick={() => {
                      updateFlinksMap(
                        produce(flinksFieldMap, (draft) => {
                          draft.splice(index, 1);
                        })
                      );
                    }}
                  />
                </div>
              </Fragment>
            ))}
            <PlusIcon
              className={styles.customPropertyAdd}
              onClick={() => {
                updateFlinksMap(
                  produce(flinksFieldMap, (draft) => {
                    draft.push(defaultEntry());
                  })
                );
              }}
            />
          </div>
        </CollapsibleSection>
      </Form>
    </IntegrationsSidebar>
  );
}

const defaultEntry = () => ({
  flinks_attribute: '',
  feathery_field: '',
  feathery_type: ''
});

type FlinksFieldMapType = {
  flinks_attribute?: string;
  feathery_field?: string;
  feathery_type?: string;
}[];
