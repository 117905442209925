import { memo, useMemo } from 'react';

import Dialog from '../../Dialog';

import classNames from 'classnames';
import { calculateDimensions } from '../../RenderingEngine/utils';
import styles from './styles.module.scss';
import {
  fieldTypeNameMap,
  TYPE_BUTTON,
  TYPE_TEXT
} from '../../../utils/elements';
import GridInGridContainer from '../../RenderingEngine/GridInGrid';
import { ACTION_OPTIONS } from '../../Panels/utils';
import useFeatheryRedux from '../../../redux';
import { Viewport } from '../../RenderingEngine/GridInGrid/engine';
import { useAppSelector } from '../../../hooks';
import { getElementStyleType } from '../../RenderingEngine/GridInGrid/components/CellElement/utils';
import { getAsset, themeSelectorToElementType } from '../../../utils/themes';

const getActiveConditionType = (element: any, asset: any) => {
  const type = element._type;
  const actions = element.properties.actions ?? asset?.properties.actions ?? [];
  const hasNextAction = actions.some(
    (action: any) => action.type === ACTION_OPTIONS.NEXT
  );

  if (type === TYPE_BUTTON) return hasNextAction ? 'button' : null;
  else if (type === TYPE_TEXT) return 'text';
  else if (fieldTypeNameMap[type]) return 'field';
};

const useWrapper = (
  onSelect: (type: string, nodeId: string) => void,
  selectedId: string
) => {
  const {
    toasts: { addInfoToast }
  } = useFeatheryRedux();

  function Wrapper({
    node,
    children,
    className,
    css,
    ...props
  }: {
    node: any;
    children: any;
    css: any;
    className: any;
  }) {
    const theme = useAppSelector((s) => s.formBuilder.theme);

    if (!node?.isElement)
      return (
        <div className={className} {...props}>
          {children}
        </div>
      );

    const element = node.element;
    const { style } = getElementStyleType(element);
    const asset = getAsset(
      theme,
      themeSelectorToElementType(style[0], style[1] ?? ''),
      element.source_asset
    );

    const onClick = () => {
      const conditionType = getActiveConditionType(element, asset);
      if (conditionType === null)
        addInfoToast(
          "You can't select this element until you set its click action to 'Go To Step'"
        );
      else if (conditionType) onSelect(conditionType, node.id);
    };

    return (
      <div
        className={classNames(className, {
          [styles.elementCover]: true,
          [styles.selectable]: !!getActiveConditionType(element, asset),
          [styles.selected]: node.id === selectedId
        })}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }

  return Wrapper;
};

const ElementSelectorModal = ({
  show,
  hide,
  step,
  elementId = '',
  onSelect = () => {}
}: any) => {
  const root = step.subgrids.find((grid: any) => grid.position.length === 0);
  const rootDimensions = useMemo(
    () => calculateDimensions(Viewport.Desktop, root),
    [step]
  );

  const Wrapper = useWrapper(onSelect, elementId);

  return (
    <Dialog
      isOpen={show}
      onClose={hide}
      title='Select element to trigger navigation'
      size='xlg'
    >
      <div className={styles.elementSelectorContainer}>
        <div
          style={{
            maxHeight: '50vh',
            height: rootDimensions.height,
            width: rootDimensions.width,
            backgroundImage: `url("${root.styles.background_image_url}")`,
            backgroundColor: `#${root.styles.background_color}`
          }}
        >
          <GridInGridContainer
            customControls={{ Wrapper }}
            overrideStep={step}
          />
        </div>
      </div>
      <button
        className={classNames('btn btn-custom', styles.stepLoadButton)}
        onClick={() => onSelect('step', step.id)}
      >
        Skip Step
      </button>
    </Dialog>
  );
};

export default memo(ElementSelectorModal);
