import { useMemo } from 'react';
import { useAppSelector } from '../../hooks';
import { INTEGRATIONS } from './types';

export default function useIntegrations({
  types = [],
  type = '',
  all = false,
  panelId,
  includeInactive = false
}: any) {
  const formIntegrations = useAppSelector(
    (state) => state.integrations.integrations[panelId]
  );
  return useMemo(() => {
    const filteredIntegrations = Object.entries(formIntegrations ?? {})
      .filter(
        ([integType, integ]) =>
          (includeInactive || (integ as any).data.active) &&
          (all || integType === type || types.includes(integType))
      )
      .reduce((allIntegs, [integType, integ]) => {
        allIntegs[integType] = integ;
        return allIntegs;
      }, {} as Record<string, any>);
    if (type) return filteredIntegrations[type];
    else if (types.length > 0 || all) return filteredIntegrations;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formIntegrations]);
}

export function useActiveAuthIntegration(formId: string) {
  const integs = useIntegrations({
    types: [INTEGRATIONS.FIREBASE, INTEGRATIONS.STYTCH],
    panelId: formId
  });
  return integs[INTEGRATIONS.STYTCH] || integs[INTEGRATIONS.FIREBASE];
}
