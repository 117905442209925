import { useState } from 'react';
import { useRuleBuilderUpdater } from '../../../context';
import { FieldSelector } from '../../../../../../../components/Modals/DecisionLogicModal/DecisionLogicSection';
import {
  LogicFieldSelectorModal,
  SelectFieldPayload
} from '../../FieldSelectorModal';
import { RuleOperand } from '../../../context/RuleDSL';
import { RuleOperand as Operand } from '../../RuleOperand';
import classNames from 'classnames';
import useFieldKey from '../../../../../../../utils/useFieldKey';
import comparisonRuleStyles from '../../../../../../../components/Modals/DecisionLogicModal/styles.module.scss';
import styles from '../styles.module.scss';

type SmsCodeActionProps = {
  action: IRuleAction;
  mode?: RuleBuilderMode;
};

const EditMode = ({ action }: SmsCodeActionProps) => {
  const { updateAction, updateOperand } = useRuleBuilderUpdater((s) => ({
    updateAction: s.updateAction,
    updateOperand: s.updateOperand
  }));

  const [selectedField, setSelectedField] = useState<Field | null>(null);
  const [showFieldSelector, setShowFieldSelector] = useState(false);
  const getFieldKey = useFieldKey(true);

  const { parameters } = action;
  const [field] = parameters;

  const handleSelectField = (payload: SelectFieldPayload) => {
    if (field) {
      updateOperand(field.id, {
        type: 'field',
        value: payload.selectId,
        meta: {
          ...field.meta,
          field_type: payload.selectType,
          servar_type: payload.selectServarType
        }
      });
    } else {
      const operand = new RuleOperand();

      operand.type = 'field';
      operand.value = payload.selectId;
      operand.meta = {
        field_type: payload.selectType,
        servar_type: payload.selectServarType
      };

      updateAction(action.id, {
        parameters: [operand.toJSON()]
      });
    }
  };

  return (
    <>
      <span> to</span>
      <LogicFieldSelectorModal
        show={showFieldSelector}
        setShow={(value: boolean) => setShowFieldSelector(value)}
        selectedField={selectedField}
        onSelect={handleSelectField}
        includeServarTypes={['phone_number']}
      />
      <FieldSelector
        fieldId={field?.value ?? ''}
        fieldType={field?.meta?.field_type ?? 'servar'}
        fieldKey={getFieldKey(field?.value ?? '')}
        className={classNames(
          comparisonRuleStyles.fieldSelector,
          styles.fieldSelector
        )}
        error={false}
        showFieldSelector={(
          id: any,
          type: any,
          ruleItemIndex: any,
          ruleValueIndex = null
        ) => {
          setSelectedField({
            id,
            type,
            ruleItemIndex,
            ruleValueIndex
          });
          setShowFieldSelector(true);
        }}
      />
    </>
  );
};

const ViewMode = ({ action }: SmsCodeActionProps) => {
  const [field] = action.parameters;

  return (
    <>
      <span> to</span>
      <Operand operand={field} />
    </>
  );
};

export const SmsCodeAction = ({
  action,
  mode = 'view'
}: SmsCodeActionProps) => {
  return (
    <div className={styles.actionContent}>
      {mode === 'view' ? (
        <ViewMode action={action} />
      ) : (
        <EditMode action={action} />
      )}
    </div>
  );
};
