import { memo } from 'react';

import useFeatheryRedux from '../../redux';
import Dialog from '../Dialog';
import { Negative, Neutral } from '../Core/Button';

function DeleteEnvelopeModal({ show, close, envelopeId, signer }: any) {
  const { deleteEnvelope } = useFeatheryRedux();

  return (
    <Dialog
      isOpen={show}
      onClose={() => close()}
      shouldShowCloseIcon={false}
      title={`Are you sure you want to delete the envelope sent to ${signer}?`}
      alignCenter
      style={{ padding: '24px 40px' }}
    >
      <div className='dialog-form-action'>
        <Neutral title='Cancel' onClick={() => close()} />
        <Negative
          title='Yes, Delete Envelope'
          onClick={async (event: any) => {
            event.preventDefault();
            await deleteEnvelope({ envelopeId });
            close();
          }}
        />
      </div>
    </Dialog>
  );
}

export default memo(DeleteEnvelopeModal);
