import { fieldTypeNameMap } from '../../../../../../utils/elements';
import useFieldKey from '../../../../../../utils/useFieldKey';
import elementEntries from '../../../../../../components/SelectionPanel/elementEntries';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAppSelector } from '../../../../../../hooks';

type RuleOperandProps = {
  operand: IRuleOperand;
  displayField?: string;
  bold?: boolean;
  getDisplay?: (operand: IRuleOperand, displayField?: string) => string | null;
};

const REVERSE_FIELD_NAME_MAP = Object.keys(fieldTypeNameMap).reduce(
  (acc, entry) => {
    acc[fieldTypeNameMap[entry]] = entry;
    return acc;
  },
  {} as any
);

const getIcon = (servarName: string) => {
  const servarType = REVERSE_FIELD_NAME_MAP[servarName];

  if (elementEntries[servarType]) {
    return elementEntries[servarType].icon;
  }

  return null;
};

const _getDisplay = (operand: IRuleOperand, displayField?: string) => {
  return displayField
    ? operand?.meta?.[displayField] ?? operand.value
    : operand.value;
};

const formatDisplay = (display: any) => {
  if (Array.isArray(display)) {
    return display.join(', ');
  }

  if (display === '') {
    return 'no value';
  }

  return display;
};

const FieldOperand = ({
  operand,
  displayField = 'field_key',
  bold = true,
  getDisplay = _getDisplay
}: RuleOperandProps) => {
  const servars = useAppSelector((s) => s.formBuilder.servars);
  const hiddenFields = useAppSelector((s) => s.fields.hiddenFields);
  const getFieldKey = useFieldKey(true);
  const display = displayField
    ? getDisplay(operand, displayField)
    : getFieldKey(operand.value);

  // Detect if the field key is a valid field key
  let isValid = true;
  if (operand.meta?.field_key) {
    const servarFieldKeys: any = Object.values({
      ...servars,
      ...hiddenFields
    }).reduce((acc: any, { key }: any) => {
      acc[key] = true;
      return acc;
    }, {});

    isValid = servarFieldKeys[operand.meta.field_key] ?? false;
  }

  return (
    <div
      className={classNames(styles.operand, styles.fieldOperand, {
        [styles.bold]: bold,
        [styles.invalid]: !isValid
      })}
    >
      {operand.meta?.servar_type && getIcon(operand.meta.servar_type)}
      <div>{display}</div>
    </div>
  );
};

const ValueOperand = ({
  operand,
  displayField,
  bold = false,
  getDisplay = _getDisplay
}: RuleOperandProps) => {
  const display = getDisplay(operand, displayField);
  const formattedDisplay = formatDisplay(display);

  if (!display) {
    return null;
  }

  return (
    <div
      className={classNames(styles.operand, styles.valueOperand, {
        [styles.bold]: bold
      })}
    >
      {formattedDisplay}
    </div>
  );
};

const ErrorOperand = () => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={<Tooltip>This field/value is invalid.</Tooltip>}
    >
      <div
        className={classNames(
          styles.operand,
          styles.fieldOperand,
          styles.bold,
          styles.invalid
        )}
      >
        Unknown Field
      </div>
    </OverlayTrigger>
  );
};

export const RuleOperand = ({
  operand,
  displayField,
  bold,
  getDisplay
}: RuleOperandProps) => {
  if (!operand) {
    return <ErrorOperand />;
  }

  const { type } = operand;

  switch (type) {
    case 'field':
      return (
        <FieldOperand
          operand={operand}
          displayField={displayField}
          bold={bold}
          getDisplay={getDisplay}
        />
      );
    case 'value':
      return (
        <ValueOperand
          operand={operand}
          displayField={displayField}
          bold={bold}
          getDisplay={getDisplay}
        />
      );
    default:
      return null;
  }
};
