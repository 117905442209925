import { memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function NavTab({
  label,
  icon,
  link = '',
  onClick = () => {},
  active = false,
  showBackground = false,
  disabledMessage = ''
}: any) {
  if (disabledMessage)
    return (
      <OverlayTrigger
        placement='bottom'
        overlay={<Tooltip>{disabledMessage}</Tooltip>}
      >
        <div className='disabled-global-tab'>
          {icon}
          {label}
        </div>
      </OverlayTrigger>
    );
  if (link)
    return (
      <Link
        to={link}
        className={classNames(
          'nav-item',
          active === label && 'active',
          showBackground && 'nav-background'
        )}
      >
        {icon}
        {label}
      </Link>
    );
  return (
    <button
      style={{ marginBottom: '15px' }}
      className='nav-item logout'
      onClick={onClick}
    >
      {icon}
      {label}
    </button>
  );
}

export default memo(NavTab);
