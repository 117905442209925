import {
  CheckboxIcon,
  DropdownIcon,
  ImageIcon,
  RadioButtonIcon,
  SignatureIcon,
  TextFieldIcon
} from '../../components/Icons';

type Field = {
  type: string;
  label: string;
  Icon: any;
  width?: number;
  height?: number;
};

export const FIELDS: { [key: string]: Field } = {
  text: {
    type: 'text',
    label: 'Text Field',
    Icon: TextFieldIcon
  },
  signature: {
    type: 'signature',
    label: 'Signature',
    Icon: SignatureIcon
  },
  checkbox: {
    type: 'checkbox',
    label: 'Checkbox',
    Icon: CheckboxIcon,
    width: 18,
    height: 18
  },
  radio: {
    type: 'radio',
    label: 'Radio Button',
    Icon: RadioButtonIcon,
    width: 18,
    height: 18
  },
  combobox: {
    type: 'combobox',
    label: 'Dropdown',
    Icon: DropdownIcon
  },
  image: {
    type: 'image',
    label: 'Image',
    Icon: ImageIcon,
    width: 75,
    height: 75
  }
};
