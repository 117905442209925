import { memo } from 'react';
import { ComputerIllustration } from '../../components/Assets/ComputerIllustration';
import styles from './styles.module.scss';

const MobileGatePage = () => {
  const handleClick = () => {
    window.location.href = 'https://feathery.io';
  };

  return (
    <div className={styles.mobileGatePage}>
      <div className={styles.mobileGatePageContainer}>
        <div>
          <ComputerIllustration />
        </div>
        <div className={styles.mobileGatePageContent}>
          <h1>Please access from your desktop</h1>
          <p>
            Enjoy all the features Feathery has to offer when you use a larger
            screen.
          </p>
          <button onClick={handleClick}>Return to Feathery.io</button>
        </div>
      </div>
    </div>
  );
};

export default memo(MobileGatePage);
