import classNames from 'classnames';
import { Switch } from '../';
import styles from './styles.module.scss';

const OnOffSwitch = ({
  checked,
  className,
  onChange = () => {},
  ...props
}: any) => {
  return (
    <div
      className={classNames(styles.switchContainer, className)}
      onClick={() => onChange(!checked)}
    >
      <div
        className={classNames(styles.onOverlay, styles.onOffLabel, {
          [styles.onOverlayActive]: checked
        })}
      >
        ON
      </div>
      <Switch
        checked={checked}
        onChange={() => onChange(!checked)}
        className={styles.switch}
        {...props}
      />
      <div
        onClick={() => onChange(!checked)}
        className={classNames(styles.offOverlay, styles.onOffLabel, {
          [styles.offOverlayActive]: !checked
        })}
      >
        OFF
      </div>
    </div>
  );
};

export default OnOffSwitch;
