import { memo } from 'react';
import { BackArrowIcon } from '../../Icons';
import classNames from 'classnames';

function Back({ onClick = () => {}, className = '' }) {
  return (
    <button onClick={onClick} className={classNames('back-button', className)}>
      <BackArrowIcon />
    </button>
  );
}

export default memo(Back);
