import { memo } from 'react';
import { RedoIcon } from '../../Icons';

function Redo({ onClick, disabled = () => {} }: any) {
  return (
    <button onClick={onClick} disabled={disabled} className='redo-button'>
      <RedoIcon disabled={disabled} />
    </button>
  );
}

export default memo(Redo);
