import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  ContainerControlsSection,
  ContainerPropertiesSection
} from './components/ContainerPanel';
import { INTEGRATIONS } from '../../Integrations';
import useIntegrations from '../../Integrations/useIntegrations';
import { ACTION_OPTIONS, getActionOptions } from '../utils';
import ClickActionSection from '../Sections/ClickActionSection';
import { cellOperation } from '../../../utils/themes';
import { useRepeatedContainerChecks } from '../../../hooks/useRepeatedContainerChecks';
import { ViewportType } from '../../RenderingEngine/GridInGrid/engine/viewports';
import { HideIfsSection } from './components';

export type ContainerPropertiesPanelProps = {
  viewport: ViewportType;
  handleUpdates: any;
  cellNode: any;
};

const ContainerPropertiesPanel = ({
  viewport,
  handleUpdates,
  cellNode
}: ContainerPropertiesPanelProps) => {
  const { formId }: any = useParams();
  const integrations = useIntegrations({
    types: [
      INTEGRATIONS.ARGYLE,
      INTEGRATIONS.PLAID,
      INTEGRATIONS.ALLOY,
      INTEGRATIONS.FLINKS,
      INTEGRATIONS.PERSONA,
      INTEGRATIONS.FIREBASE,
      INTEGRATIONS.SMS_MESSAGE,
      INTEGRATIONS.STYTCH,
      INTEGRATIONS.STRIPE,
      INTEGRATIONS.TELESIGN
    ],
    panelId: formId
  });

  const { repeatExists, inRepeatContainer } =
    useRepeatedContainerChecks(cellNode);

  const actionOptions = useMemo(
    () =>
      getActionOptions({ repeatExists, integrations }, [
        ACTION_OPTIONS.NEXT,
        ACTION_OPTIONS.BACK,
        ACTION_OPTIONS.OAUTH,
        ACTION_OPTIONS.MAGIC_LINK,
        ACTION_OPTIONS.LOGOUT,
        ACTION_OPTIONS.SMS_CODE,
        ACTION_OPTIONS.SMS_MESSAGE,
        ACTION_OPTIONS.EMAIL_CODE,
        ACTION_OPTIONS.PLAID,
        ACTION_OPTIONS.ALLOY_VERIFY_ID,
        ACTION_OPTIONS.FLINKS,
        ACTION_OPTIONS.PERSONA,
        ACTION_OPTIONS.ARGYLE,
        ACTION_OPTIONS.PURCHASE_PRODUCTS,
        ACTION_OPTIONS.SELECT_PRODUCT_TO_PURCHASE,
        ACTION_OPTIONS.REMOVE_PRODUCT_FROM_PURCHASE,
        ACTION_OPTIONS.STORE_FIELD_VALUE,
        ACTION_OPTIONS.URL,
        ACTION_OPTIONS.ADD_REPEATED_ROW,
        ACTION_OPTIONS.REMOVE_REPEATED_ROW,
        ACTION_OPTIONS.NEW_SUBMISSION,
        ACTION_OPTIONS.TELESIGN_SILENT_VERIFICATION,
        ACTION_OPTIONS.TELESIGN_PHONE_TYPE,
        ACTION_OPTIONS.TELESIGN_VOICE_OTP,
        ACTION_OPTIONS.TELESIGN_SMS_OTP,
        ACTION_OPTIONS.TELESIGN_VERIFY_OTP,
        ACTION_OPTIONS.CUSTOM
      ]),
    [repeatExists, integrations]
  );

  function handlePropChange(propUpdate: any) {
    handleUpdates([cellOperation({ viewport, propUpdate })]);
  }

  return (
    <>
      {!cellNode.isRoot() && (
        <ClickActionSection
          title='When Clicked'
          properties={cellNode.properties}
          actionOptions={actionOptions}
          handlePropChange={handlePropChange}
          elementType='container'
          elementId={cellNode.id}
          isTheme={false}
          inRepeatContainer={inRepeatContainer}
          repeatingContainer={cellNode.repeated}
        />
      )}
      <ContainerPropertiesSection
        cellNode={cellNode}
        handlePropChange={handlePropChange}
      />
      {!cellNode.isRoot() && (
        <HideIfsSection
          element={cellNode}
          hideIfs={cellNode.hide_ifs}
          showLogic={cellNode.show_logic}
          handleUpdates={handleUpdates}
          isCell
        />
      )}
      <ContainerControlsSection cellNode={cellNode} />
    </>
  );
};

export default ContainerPropertiesPanel;
