import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { Button } from '../../components/Core';
import FormNavigation from '../../components/NavBar/FormNavigation';
import ExtractionCreateEditModal from '../AIExtractionListPage/ExtractionCreateEditModal';
import UserResultsTable from '../../components/UserResultsTable';
import classNames from 'classnames';
import styles from './styles.module.scss';
import useFeatheryRedux from '../../redux';
import { LinkText } from '../../utils/domOperations';
import { AIExtractionRun } from '../AIExtractionRunPage/types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { capitalizeFirstLetter } from '../../utils/format';

type URLParams = {
  formId: string;
  extractionId: string;
};

export default function AIExtractionDetailPage() {
  const { formId, extractionId } = useParams<URLParams>();
  const history = useHistory();

  const [edit, setEdit] = useState(false);
  const [loadError, setLoadError] = useState('');

  const extraction = useAppSelector((state) =>
    state.ai.extractions.find((extraction) => extraction.id === extractionId)
  );

  const { getAIExtractionRuns, deleteAIExtractionRun } = useFeatheryRedux();

  const handleFetchData = (payload: { [key: string]: any }) => {
    return getAIExtractionRuns({
      ...payload,
      extractionId
    });
  };

  const handleSelectResult = (run: any) => {
    if (run.success === null) return;
    history.push(`/forms/${formId}/ai/${extractionId}/${run.id}`);
  };

  const handleLoadError = (error: string) => {
    setLoadError(error);
  };

  return (
    <>
      <FormNavigation activeItem='aiExtraction' error={loadError} />
      <ExtractionCreateEditModal
        extraction={extraction}
        isOpen={edit}
        close={() => setEdit(false)}
      />
      <div className={styles.aiExtractionsListPage}>
        <div className={styles.header}>
          <div className={styles.title}>
            <Button.Back onClick={() => history.push(`/forms/${formId}/ai`)} />
            <h3>{extraction.key}: Runs</h3>
          </div>
          <div className={styles.actions}>
            <button
              className='btn btn-custom list-view-action'
              onClick={() => setEdit(true)}
            >
              Edit Extraction
            </button>
          </div>
        </div>
        <div className={styles.section}>
          <UserResultsTable
            extractionId={extractionId}
            onFetchData={handleFetchData}
            onSelectResult={handleSelectResult}
            onDelete={(run: AIExtractionRun) =>
              deleteAIExtractionRun({ runId: run.id })
            }
            onLoadError={handleLoadError}
            hideTitle
            hideDownloadCSV
            hideEditTableView
            padding={false}
            resultColumns={[
              { key: 'id', name: 'Run ID' },
              {
                key: 'file_source',
                name: 'File',
                renderCell: (data) => {
                  return (
                    <LinkText
                      key={data.id}
                      link={data.file_source.url}
                      text={data.file_source.path.split('/').at(-1)}
                    />
                  );
                }
              },
              {
                key: 'error_message',
                name: 'Status',
                renderCell: (data) => {
                  let status, message;
                  if (data.success === null) status = 'processing';
                  else if (!data.success) status = 'error';
                  else if (!data.approved) status = 'pending';
                  else {
                    status = 'approved';
                    if (data.approver) message = `Approved - ${data.approver}`;
                  }
                  let component = (
                    <div className={classNames(styles.status, styles[status])}>
                      {message || capitalizeFirstLetter(status)}
                    </div>
                  );
                  if (status === 'error') {
                    const errMessage =
                      data.error_message || 'An error occurred';
                    component = (
                      <OverlayTrigger
                        overlay={<Tooltip id='ai-err'>{errMessage}</Tooltip>}
                      >
                        {component}
                      </OverlayTrigger>
                    );
                  }
                  return component;
                }
              },
              { key: 'updated_at', name: 'Last Updated' }
            ]}
          />
        </div>
      </div>
    </>
  );
}
