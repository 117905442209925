import { memo, useEffect, useMemo, useRef, useState } from 'react';

import Dialog from '../../Dialog';
import { Neutral, Positive } from '../../Core/Button';
import { useAppSelector } from '../../../hooks';
import { DropdownField, WarningCard } from '../../Core';
import styles from './styles.module.scss';
import { downloadTranslationFile } from './utils';
import { useParams } from 'react-router-dom';
import { FileUploadIcon, TrashIcon } from '../../Icons';
import { isDefined } from '../../../utils/core';

const PHRASE_DELIMITER_REGEX = /=/g;
const ESCAPED_EQUAL_DELIMITER_REGEX = /\\=/g;

const UpdateTranslationModal = ({
  editLanguage,
  translations,
  hide,
  onTranslationUpdate
}: {
  editLanguage: undefined | string;
  translations: Record<string, any>;
  hide: () => void;
  onTranslationUpdate: (
    lang: string,
    translations: Record<string, string>
  ) => void;
}) => {
  const { formId } = useParams<{ formId: string }>();
  const fileInput = useRef<any>(null);
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const languageCodes = useAppSelector(
    (state) => state.integrations.languageCodes
  );
  const [language, setLanguage] = useState(editLanguage ?? '');
  const [languageTranslations, setLanguageTranslations] = useState<
    undefined | Record<string, string>
  >();
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState('');

  const dropdownOptions = useMemo(
    () =>
      languageCodes
        .map(({ code, name }) => ({ display: name, value: code }))
        .sort(({ display: d1 }, { display: d2 }) => (d1 < d2 ? -1 : 1)),
    [languageCodes]
  );
  const codeDisplayMap = useMemo(
    () =>
      languageCodes.reduce((curCodes, { code, name }) => {
        curCodes[code] = name;
        return curCodes;
      }, {} as Record<string, string>),
    [languageCodes]
  );

  useEffect(() => setLanguage(editLanguage ?? ''), [editLanguage]);

  function onFileChange(event: any) {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const payload = event.target?.result ?? '';
      if (!payload) return;

      const pieces = (payload as string).split('\n');
      let i;
      for (i = 0; i < pieces.length; i++) {
        if (pieces[i].trim()[0] !== '#') break;
      }

      const err = { current: '' };
      const curTranslations = pieces
        .slice(i)
        .filter((piece) => !!piece.trim())
        .map((piece) => validateTranslationDelimiters(piece, err))
        .filter(
          ([original, translation]) =>
            original && translation && original in translations
        )
        .reduce((cur, [phrase, translation]) => {
          phrase = phrase.replaceAll('\\=', '=');
          cur[phrase] = translation.replaceAll('\\=', '=');
          return cur;
        }, {} as Record<string, string>);

      if (!err.current && Object.keys(curTranslations).length === 0)
        err.current = 'Your file has no translations relevant to this form';

      setError(err.current);
      if (!err.current) {
        setLanguageTranslations(curTranslations);
        setFileName(file.name);
      }
    });
    reader.readAsText(file);

    // Clear file input
    event.target.value = '';
  }

  const addTranslation = () => {
    if (!fileName || !languageTranslations) return;

    onTranslationUpdate(language, languageTranslations);
    setLanguageTranslations(undefined);
    setFileName('');
    hide();
  };

  return (
    <Dialog
      isOpen={editLanguage !== undefined}
      onClose={hide}
      title={`${editLanguage ? 'Update' : 'New'} Translation`}
      size='xsm'
    >
      {error ? (
        <WarningCard text={error} />
      ) : (
        <div className={styles.downloadCTA}>
          Download the{' '}
          <span
            className={styles.downloadFileLink}
            onClick={() =>
              downloadTranslationFile(panel.key, translations, editLanguage)
            }
          >
            {editLanguage ? codeDisplayMap[editLanguage] : 'Default'}
          </span>{' '}
          document and fill out your translations.
        </div>
      )}
      <div className={styles.configLabel}>Choose language</div>
      <DropdownField
        selected={language}
        onChange={(e: any) => setLanguage(e.target.value)}
        options={dropdownOptions}
        disabled={!!editLanguage}
      />
      <div className={styles.configLabel}>Upload file</div>
      {fileName ? (
        <div className={styles.existingFile}>
          <div className={styles.existingFileName}>{fileName}</div>
          <TrashIcon
            onClick={() => {
              setFileName('');
              setLanguageTranslations(undefined);
            }}
          />
        </div>
      ) : (
        <div
          className={styles.richFileInput}
          onClick={() => fileInput.current.click()}
        >
          <FileUploadIcon />
          <input
            ref={fileInput}
            type='file'
            onChange={onFileChange}
            accept='.txt'
            className={styles.hiddenFileInput}
          />
        </div>
      )}
      <div className='dialog-form-action text-right'>
        <Neutral title='Cancel' onClick={hide} />
        <Positive
          title={editLanguage ? 'Update' : 'Add'}
          onClick={addTranslation}
          disabled={!language || !fileName}
        />
      </div>
    </Dialog>
  );
};

export const validateTranslationDelimiters = (
  piece: string,
  err: { current: string }
): string[] => {
  const delimiters = [...piece.matchAll(PHRASE_DELIMITER_REGEX)]
    .map((match) => match.index)
    .filter(isDefined);
  const escaped = [...piece.matchAll(ESCAPED_EQUAL_DELIMITER_REGEX)]
    .map((match) => match.index)
    .filter(isDefined);

  const unescapedEquals = delimiters.length - escaped.length;
  if (unescapedEquals === 0) {
    err.current = `Your file is not valid. The following line is missing the unescaped equal sign.\n${piece}`;
  } else if (unescapedEquals > 1) {
    err.current = `Your file is not valid. The following line should only have one unescaped equal sign.\n${piece}`;
  }

  const escapedSet = new Set(escaped);
  const splitIdx = delimiters.find(
    (delimiterIdx) => !escapedSet.has(delimiterIdx - 1)
  );

  return err.current || !splitIdx
    ? []
    : [
        piece.slice(0, splitIdx).replaceAll('\\=', '='),
        piece.slice(splitIdx + 1).replaceAll('\\=', '=')
      ];
};

export default memo(UpdateTranslationModal);
