import { PageProps } from './types';
import styles from './styles.module.scss';

import {
  API_CONNECTOR_TEMPLATE_PAGE,
  CONFIGURE_REQUEST_PAGE,
  SELECT_API_SERVICE_PAGE
} from '../constants';
import { TEMPLATES } from '../api_templates';
import { useState } from 'react';
import { RolloutCredentialPicker } from '../components/RolloutCredentialPicker';
import { NavBar } from '../components/NavBar';
import { useAppSelector } from '../../../../hooks';
import { useParams } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const APIConnectorTemplatePage = (props: PageProps) => {
  const { setState, options, setOptions, goto = () => {} } = props;
  const { formId } = useParams<{ formId: string }>();
  const [selectedTemplate, setSelectedTemplate] = useState<
    keyof typeof TEMPLATES | ''
  >('');
  const enabledIntegrations = useAppSelector((state) => {
    return Object.entries(state.integrations.integrations[formId] ?? {})
      .filter(([key, value]: any) => value?.data?.active)
      .map(([key, value]) => key);
  });

  const [selectedCredential, setSelectedCredential] = useState<any>(null);

  const app_token = selectedTemplate ? TEMPLATES[selectedTemplate].token : null;

  const handleSubmit = () => {
    if (selectedTemplate) {
      setState(TEMPLATES[selectedTemplate].createConnector(selectedCredential));
      goto(CONFIGURE_REQUEST_PAGE);
    }
  };

  const buttons = Object.keys(TEMPLATES).sort((idA, idB) => {
    const aDisabled = idA !== 'custom' && !enabledIntegrations.includes(idA);
    const bDisabled = idB !== 'custom' && !enabledIntegrations.includes(idB);
    if (aDisabled === bDisabled) {
      return 0;
    }
    if (aDisabled && !bDisabled) {
      return 1;
    }
    return -1;
  });

  const allDisabled = !enabledIntegrations.includes(buttons[1]);

  if (allDisabled) {
    setState(TEMPLATES.custom.createConnector());
    if (options?.startPage === 1) {
      setOptions({ startPage: 2 });
    }
    goto(CONFIGURE_REQUEST_PAGE);
  }

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div className='tw-grid lg:tw-grid-cols-2 tw-gap-4'>
          {buttons.map((id) => (
            <TemplateButton
              key={id}
              app={id}
              onClick={() => {
                if (selectedTemplate !== id) {
                  setSelectedTemplate(id as any);
                  setSelectedCredential(null);
                }
              }}
              selected={selectedTemplate === id}
              disabled={id !== 'custom' && !enabledIntegrations.includes(id)}
            />
          ))}
        </div>
        {app_token && (
          <div className='tw-mt-6'>
            <RolloutCredentialPicker
              app={app_token}
              credentialKey={
                selectedCredential ? selectedCredential.credentialKey : ''
              }
              onChange={setSelectedCredential}
            />
          </div>
        )}
      </div>
      <NavBar
        next
        back={
          options?.startPage != null &&
          options.startPage < API_CONNECTOR_TEMPLATE_PAGE
        }
        disabled={{
          next: !(
            selectedTemplate &&
            (!TEMPLATES[selectedTemplate].token || selectedCredential)
          )
        }}
        onClick={(btn: string) => {
          if (btn === 'next') handleSubmit();
          if (btn === 'back') goto(SELECT_API_SERVICE_PAGE);
        }}
      />
    </div>
  );
};

function TemplateButton({ disabled, app, onClick, selected }: any) {
  const template = TEMPLATES[app as keyof typeof TEMPLATES];
  if (!template) return <></>;
  const appDisplay = app.charAt(0).toUpperCase() + app.slice(1);

  if (disabled) {
    return (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip
            style={{ zIndex: 99999999 }}
          >{`Set up a ${appDisplay} integration to use the ${appDisplay} API.`}</Tooltip>
        }
      >
        <button
          disabled={disabled}
          onClick={onClick}
          className='tw-block tw-p-6 tw-bg-white !tw-border !tw-border-solid !tw-border-gray-200 tw-rounded-lg enabled:hover:tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-gap-6 tw-cursor-pointer data-[selected]:!tw-border-primary data-[selected]:!tw-border-2 data-[selected]:!tw-bg-primary-light/20 disabled:tw-bg-gray-100/50 disabled:tw-cursor-auto disabled:tw-text-gray-600 disabled:tw-opacity-70'
          data-selected={selected || undefined}
        >
          {template.logo}
          {template.name}
        </button>
      </OverlayTrigger>
    );
  }

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className='tw-block tw-p-6 tw-bg-white !tw-border !tw-border-solid !tw-border-gray-200 tw-rounded-lg enabled:hover:tw-bg-gray-100 tw-flex tw-items-center tw-justify-center tw-gap-6 tw-cursor-pointer data-[selected]:!tw-border-primary data-[selected]:!tw-border-2 data-[selected]:!tw-bg-primary-light/20 disabled:tw-bg-gray-100/50 disabled:tw-cursor-auto disabled:tw-text-gray-600 disabled:tw-opacity-70'
      data-selected={selected || undefined}
    >
      {template.logo}
      {template.name}
    </button>
  );
}
