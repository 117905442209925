import { useAppSelector } from '../../../hooks';
import {
  DropdownField,
  FColorPicker,
  InlineTooltip,
  NumberInput,
  TextField,
  YesNoSwitch
} from '../../../components/Core';
import SettingsContainer from '../../../components/SettingsContainer';
import { Form } from 'react-bootstrap';
import { FONT_WEIGHT_OPTIONS } from '../../../utils/themes';
import FontPicker from '../../../components/Core/FontPicker';

const PDFSubmissionSection = ({ title, formData, updateFormData }: any) => {
  const uploadedFonts = useAppSelector(
    (state) => state.integrations.uploadedFonts
  );

  const pdfSettings = formData.submission_pdf_settings ?? {};
  const onPDFChange = (key: string, val: any) => {
    if (pdfSettings[key] !== val) {
      updateFormData({
        submission_pdf_settings: { ...pdfSettings, [key]: val }
      });
    }
  };

  const pdfFontSettings = (label: string, key: string, defaultSize = 11) => (
    <>
      <div className='field-group'>
        <Form.Label>{label} Font Family</Form.Label>
        <DropdownField
          selected={pdfSettings[`${key}_font`] ?? ''}
          onChange={(e: any) => onPDFChange(`${key}_font`, e.target.value)}
          options={[
            { value: '', display: 'Helvetica' },
            ...Object.keys(uploadedFonts).map((font) => ({
              value: font,
              display: font
            }))
          ]}
        />
      </div>
      <div className='field-group'>
        <Form.Label>{label} Font Size</Form.Label>
        <NumberInput
          classNames={{ root: 'form-control' }}
          min={6}
          max={64}
          value={pdfSettings[`${key}_size`] ?? defaultSize}
          onComplete={({ value }: any) => onPDFChange(`${key}_size`, value)}
        />
      </div>
      <div className='field-group'>
        <Form.Label>{label} Font Weight</Form.Label>
        <DropdownField
          disabled={!pdfSettings[`${key}_font`]}
          selected={parseInt(pdfSettings[`${key}_weight`]) ?? ''}
          onChange={(e: any) =>
            onPDFChange(`${key}_weight`, e.target.value.toString())
          }
          options={FONT_WEIGHT_OPTIONS}
        />
      </div>
      <div className='field-group'>
        <Form.Label>{label} Font Color</Form.Label>
        <FColorPicker
          value={pdfSettings[`${key}_color`]}
          onChange={(color: string) => onPDFChange(`${key}_color`, color)}
        />
      </div>
    </>
  );

  return (
    <SettingsContainer title={title}>
      <div className='field-group'>
        <Form.Label>
          Field Label Column Width
          <InlineTooltip
            text='The width (in points) of the column displaying field labels'
            inline
          />
        </Form.Label>
        <NumberInput
          classNames={{ root: 'form-control' }}
          min={10}
          max={256}
          value={pdfSettings.label_column_width ?? 150}
          onComplete={({ value }: any) =>
            onPDFChange('label_column_width', value)
          }
        />
      </div>
      <div className='field-group'>
        <Form.Label>
          Separate Step Pages
          <InlineTooltip
            text='Each step of the form will be printed on separate PDF pages'
            inline
          />
        </Form.Label>
        <YesNoSwitch
          id='submission-pdf-separate-step-pages'
          checked={!pdfSettings.unify_steps}
          onChange={(e: any) => onPDFChange('unify_steps', !e.target.checked)}
        />
      </div>
      <div className='field-group'>
        <Form.Label>
          Hide Empty Fields
          <InlineTooltip
            text='Hide empty fields on submission PDF exports'
            inline
          />
        </Form.Label>
        <YesNoSwitch
          id='submission-pdf-hide-empty-fields'
          checked={pdfSettings.hide_empty_fields ?? false}
          onChange={(e: any) =>
            onPDFChange('hide_empty_fields', e.target.checked)
          }
        />
      </div>
      <div className='field-group'>
        <Form.Label>
          Hide Default Footer
          <InlineTooltip
            text='Hide the default footer included on submission PDF exports'
            inline
          />
        </Form.Label>
        <YesNoSwitch
          id='submission-pdf-hide-footer'
          checked={pdfSettings.hide_footer ?? false}
          onChange={(e: any) => onPDFChange('hide_footer', e.target.checked)}
        />
      </div>
      <div className='field-group'>
        <Form.Label>
          Hide Default Header
          <InlineTooltip
            text='Hide the default header included on submission PDF exports'
            inline
          />
        </Form.Label>
        <YesNoSwitch
          id='submission-pdf-hide-header'
          checked={pdfSettings.hide_header ?? false}
          onChange={(e: any) => onPDFChange('hide_header', e.target.checked)}
        />
      </div>
      {pdfSettings.hide_header && (
        <>
          <div className='field-group'>
            <Form.Label>
              Custom Header
              <InlineTooltip
                text='Custom text to display at the top of pages'
                inline
              />
            </Form.Label>
            <TextField
              value={pdfSettings.custom_header ?? ''}
              onComplete={(val: string) => onPDFChange('custom_header', val)}
            />
          </div>
          {pdfSettings.custom_header &&
            pdfFontSettings('Header', 'custom_header', 12)}
        </>
      )}
      {pdfFontSettings('Field Label', 'field_label')}
      {pdfFontSettings('Field Value', 'field_value')}
      <FontPicker CustomComponent={FontUploader} />
    </SettingsContainer>
  );
};

const FontUploader = ({ onClick }: any) => {
  return (
    <button
      className='btn-custom'
      style={{ margin: '10px 0 0 0' }}
      onClick={onClick}
    >
      Add Custom Font
    </button>
  );
};

export default PDFSubmissionSection;
