import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoIcon, WarningIcon } from '../../Icons';
import styles from './styles.module.scss';
import classNames from 'classnames';

export default function InlineTooltip({
  text,
  warning = false,
  size = 17,
  inline = true,
  style = {},
  placement = 'top',
  ...props
}: any) {
  const IconType = warning ? WarningIcon : InfoIcon;
  if (inline) style = { paddingLeft: '5px', ...style };
  const base = (
    <div
      className={classNames(styles.tooltip, warning && styles.warning)}
      style={style}
      {...props}
    >
      <IconType width={size} height={size} />
    </div>
  );
  return text ? (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id='inline-tooltip' style={{ zIndex: 99999999 }}>
          {text}
        </Tooltip>
      }
    >
      {base}
    </OverlayTrigger>
  ) : (
    base
  );
}
