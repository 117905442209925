import '../../style/navigation.scss';
import { Link, useHistory, useParams } from 'react-router-dom';
import { BackArrowIcon } from '../Icons';
import { useAppSelector } from '../../hooks';
import { DynamicTextTooltip } from '../Core';

const MAX_DOC_LABEL_LENGTH = 14;

export default function DocumentNavigation({
  activeItem,
  style,
  submitButton
}: any) {
  const history = useHistory();
  const { documentId } = useParams<{ documentId: string }>();
  const account = useAppSelector((state) => state.accounts.account);
  const doc = useAppSelector((state) => state.documents.documents[documentId]);

  const isPDF = doc?.type === 'pdf';

  let docLabel = doc?.key;
  if (doc?.key.length > MAX_DOC_LABEL_LENGTH) {
    docLabel = doc.key.slice(0, MAX_DOC_LABEL_LENGTH) + '...';
  }

  return (
    <nav className='main-nav' style={style}>
      <div className='left-nav'>
        <div className='left-nav-header'>
          <div
            className='back-container'
            onClick={() => history.push('/documents/')}
          >
            <BackArrowIcon />
          </div>
          <DynamicTextTooltip
            text={doc?.key}
            maxLength={MAX_DOC_LABEL_LENGTH}
            placement='bottom'
          >
            <span>{docLabel}</span>
          </DynamicTextTooltip>
        </div>
        {isPDF && account.role !== 'viewer' && (
          <Link
            className={activeItem === 'editor' ? 'active' : undefined}
            to={`/documents/${documentId}`}
          >
            Editor
          </Link>
        )}
        <Link
          className={activeItem === 'envelopes' ? 'active' : undefined}
          to={`/documents/${documentId}/envelopes/`}
        >
          Envelopes
        </Link>
        {account.role !== 'viewer' && (
          <Link
            className={activeItem === 'settings' ? 'active' : undefined}
            to={`/documents/${documentId}/settings/`}
          >
            Settings
          </Link>
        )}
      </div>
      {submitButton && (
        <div className='right-sub-nav ml-auto justify-right'>
          {submitButton}
        </div>
      )}
    </nav>
  );
}
