import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks';
import { useMemo } from 'react';

export default function useRuleServarKeys(filterTypes: string[] = []) {
  const { formId } = useParams<{ formId: string }>();
  const logicAllFields = useAppSelector(
    (state) => state.panels.panels[formId].logic_all_fields
  );
  const servars = useAppSelector((state) => state.formBuilder.servars);
  const formBuilderSteps = useAppSelector(
    (state) => state.formBuilder.workingSteps
  );

  return useMemo(() => {
    let servarList: any[] = Object.values(servars).filter(
      (servar: any) => !filterTypes.length || !filterTypes.includes(servar.type)
    );
    if (!logicAllFields) {
      const currentFormServars = new Set();
      Object.values(formBuilderSteps).forEach((step) =>
        (step as any).servar_fields.forEach((field: any) => {
          currentFormServars.add(field.servar.id);
        })
      );
      servarList = servarList.filter((servar) =>
        currentFormServars.has(servar.id)
      );
    }
    return servarList.map((servar) => servar.key as string);
  }, [servars, formBuilderSteps, logicAllFields]);
}
