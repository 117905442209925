/* eslint-disable react-hooks/exhaustive-deps */

import { memo, useEffect, useMemo, useState } from 'react';

import HeaderFilterResultsTable, {
  FilterItemState,
  SortDirection
} from '../../components/HeaderFilterResultsTable';
import ResultsHeader from '../../components/HeaderFilterResultsTable/ResultsHeader';
import { useResultColumns } from '../../components/HeaderFilterResultsTable/useResultColumns';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useAppSelector } from '../../hooks';
import useFeatheryRedux from '../../redux';
import { formatDate } from '../../utils/format';
import { useHostedURL } from '../../components/Core/Button/Share/ShareFormOverlay';
import { openTab } from '../../utils/domOperations';
import { TasksIcon } from '../../components/Icons';
import CollaboratorFilter from '../../components/HeaderFilterResultsTable/CollaboratorFilter';
import FieldFilter from '../../components/HeaderFilterResultsTable/FieldFilter';
import {
  createDateTimeFilterWidget,
  createSimpleFilterWidget
} from '../../components/HeaderFilterResultsTable/filters';
import {
  COLLABORATOR_FILTER_ID,
  COLLABORATOR_FILTER_LABEL,
  COMPLETED_AT_FILTER_ID,
  COMPLETED_AT_FILTER_LABEL,
  FIELD_FILTER_ID,
  FIELD_FILTER_LABEL,
  FORM_FILTER_ID,
  FORM_FILTER_LABEL,
  FORM_STATUS_FILTER_ID,
  FORM_STATUS_FILTER_LABEL,
  FORM_TAG_FILTER_ID,
  FORM_TAG_FILTER_LABEL,
  INVITED_AT_FILTER_ID,
  INVITED_AT_FILTER_LABEL,
  STATUS_FILTER_ID,
  STATUS_FILTER_LABEL,
  SUBMITTED_FILTER_ID,
  SUBMITTED_FILTER_LABEL
} from '../../components/HeaderFilterResultsTable/constants';

const w = window as any;

function TaskListPage() {
  const { editAccount, getTasks } = useFeatheryRedux();

  const org = useAppSelector((state) => state.accounts.organization);
  const panelsData = useAppSelector((state) => state.panels.panels);
  const account = useAppSelector((state) => state.accounts.account);

  const [resultsPage, setResultsPage] = useState('');
  const [envName, setEnvName] = useState(w.envName || 'Production');
  const tasksData = useAppSelector((state) => state.tasks);

  const [queryState, setQueryState] = useState({
    searchKey: '',
    sortKey: '',
    sortDirection: '',
    ...(Object.values(account.task_list_filters).reduce(
      (acc: any, f: any) => ({ ...acc, [f.paramName]: f.paramValue }),
      {}
    ) as Record<string, any>)
  });

  const onSearch = (searchTerm: string) => {
    setResultsPage(''); // reset the page because search/filter change changes pages
    setQueryState({ ...queryState, searchKey: searchTerm });
  };

  const onFiltersChange = (filters: Record<string, FilterItemState>) => {
    const newQueryState: any = {
      searchKey: queryState.searchKey,
      sortKey: queryState.sortKey,
      sortDirection: queryState.sortDirection
    };
    Object.values(filters).forEach((f: any) => {
      newQueryState[f.paramName] = f.paramValue;
    });
    setResultsPage(''); // reset the page because filter change changes pages
    setQueryState(newQueryState);
    // save filters in the account
    editAccount({
      task_list_filters: filters
    });
  };
  const onRefresh = () => setQueryState({ ...queryState });

  const tableSortCallback = (
    sortDirection: SortDirection,
    key: any,
    sortByKey: any
  ) => {
    setQueryState({ ...queryState, sortKey: sortByKey ?? key, sortDirection });
  };

  useEffect(() => {
    (async () => {
      try {
        // Add all queryState key/value pairs to payload
        const payload = {
          page: resultsPage,
          envName,
          ...queryState
        };

        await getTasks(payload);
      } catch (e) {
        console.log('An unknown error occurred');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envName, queryState, resultsPage]);

  const dataToRender = useMemo(() => {
    const data = tasksData.tasks;
    return data
      ? data.map((d: any) => {
          return {
            ...d,
            completed_at_pretty: formatDate(d.completed_at),
            // unique_key is Collaborator ID + '--' + collab user id or account id key
            __unique_key: d.id + '--' + d.collaborator_id
          };
        })
      : [];
  }, [tasksData.tasks]);

  // Component that present the perform action button and handle the action
  const PerformAction = ({ task }: any) => {
    const panel = panelsData[task.panel];
    const getHostedUrl = useHostedURL(org, panel);
    const performTask = (panelId: string, userKey: string, cid: string) => {
      // Note: cid is either the CollaboratorUser id or the account key (user in group)
      let link = getHostedUrl();
      if (!link) return;
      const separator = link.includes('?') ? '&' : '?';
      link = `${link}${separator}_id=${userKey}&_cid=${cid}`;
      openTab(link);
    };
    return (
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip>Perform as {task.collaborator_email}</Tooltip>}
      >
        <button
          className={classNames('border-button', styles.performAction)}
          onClick={() => {
            performTask(task.panel, task.fuser_key, task.collaborator_id);
          }}
        >
          Perform Task
        </button>
      </OverlayTrigger>
    );
  };

  const resultColumns = useResultColumns(
    null,
    [],
    false,
    true,
    'allowed_task_list_result_columns'
  );
  // if resultsColumns has completed_at, then key it to completed_at_pretty
  const completedAtColumn = resultColumns.find(
    (c: any) => c.key === 'completed_at'
  );
  if (completedAtColumn) {
    completedAtColumn.key = 'completed_at_pretty';
    completedAtColumn.sortBy = 'completed_at';
  }
  // add the perform action column
  resultColumns.push({
    key: 'id',
    name: '',
    noSort: true,
    renderCell: (task: any) => {
      // Do the logic here for more efficiency
      if (
        task.status === 'Pending' &&
        (account.role === 'admin' ||
          // search is case insensitive!
          task.invitees.find(
            (email: string) =>
              email.toLowerCase() === account.email.toLowerCase()
          ))
      )
        return <PerformAction task={task} />;
      return null;
    }
  });

  // paging
  const getUrlPageParam = (url: string) => {
    if (!url) return '';
    return new URLSearchParams(url).get('page') ?? '0';
  };
  const onPageChange = (page: string) => {
    if (page === '0') page = '';
    setResultsPage(page);
  };

  //////////////////////////////////////////////////////
  // Filter widgets

  const StatusFilter = createSimpleFilterWidget(
    STATUS_FILTER_ID,
    STATUS_FILTER_LABEL,
    'status',
    [
      { value: 'Pending', label: 'Pending' },
      { value: 'Completed', label: 'Completed' }
    ]
  );
  const FormFilter = createSimpleFilterWidget(
    FORM_FILTER_ID,
    FORM_FILTER_LABEL,
    'panel',
    Object.values(panelsData)
      .map((panel: any) => ({
        value: panel.id,
        label: panel.key
      }))
      .sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      )
  );
  const FormTagFilter = createSimpleFilterWidget(
    FORM_TAG_FILTER_ID,
    FORM_TAG_FILTER_LABEL,
    'tag',
    Object.values(panelsData)
      .map((panel: any) => panel.tag)
      .filter((tag: string) => tag)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map((tag: string) => ({
        value: tag,
        label: tag
      }))
  );
  const FormStatusFilter = createSimpleFilterWidget(
    FORM_STATUS_FILTER_ID,
    FORM_STATUS_FILTER_LABEL,
    'active',
    [
      { value: 'true', label: 'On' },
      { value: 'false', label: 'Off' }
    ]
  );
  const InvitedAtFilter = createDateTimeFilterWidget(
    INVITED_AT_FILTER_ID,
    INVITED_AT_FILTER_LABEL,
    'invited_at'
  );
  const CompletedAtFilter = createDateTimeFilterWidget(
    COMPLETED_AT_FILTER_ID,
    COMPLETED_AT_FILTER_LABEL,
    'completed_at'
  );
  const SubmittedFilter = createDateTimeFilterWidget(
    SUBMITTED_FILTER_ID,
    SUBMITTED_FILTER_LABEL,
    'submitted'
  );

  //////////////////////////////////////////////////////

  return (
    <>
      <div className={styles.pageHeadRow}>
        <h1 className='page-head'>Tasks</h1>
      </div>
      <ResultsHeader
        label='Tasks'
        count={tasksData.count}
        refresh={onRefresh}
        onPageChange={onPageChange}
        onEnvChange={setEnvName}
        Icon={TasksIcon}
        columnsProp='task_list_result_columns'
      />

      <HeaderFilterResultsTable
        name='Task'
        columns={resultColumns}
        data={dataToRender}
        sortCallback={tableSortCallback}
        dataKey='__unique_key'
        useSearch
        onSearch={onSearch}
        filters={{
          [COLLABORATOR_FILTER_ID]: {
            id: COLLABORATOR_FILTER_ID,
            name: COLLABORATOR_FILTER_LABEL,
            filterWidget: CollaboratorFilter
          },
          [STATUS_FILTER_ID]: {
            id: STATUS_FILTER_ID,
            name: STATUS_FILTER_LABEL,
            filterWidget: StatusFilter
          },
          [FORM_FILTER_ID]: {
            id: FORM_FILTER_ID,
            name: FORM_FILTER_LABEL,
            filterWidget: FormFilter
          },
          [FORM_TAG_FILTER_ID]: {
            id: FORM_TAG_FILTER_ID,
            name: FORM_TAG_FILTER_LABEL,
            filterWidget: FormTagFilter
          },
          [FORM_STATUS_FILTER_ID]: {
            id: FORM_STATUS_FILTER_ID,
            name: FORM_STATUS_FILTER_LABEL,
            filterWidget: FormStatusFilter
          },
          [INVITED_AT_FILTER_ID]: {
            id: INVITED_AT_FILTER_ID,
            name: INVITED_AT_FILTER_LABEL,
            filterWidget: InvitedAtFilter
          },
          [COMPLETED_AT_FILTER_ID]: {
            id: COMPLETED_AT_FILTER_ID,
            name: COMPLETED_AT_FILTER_LABEL,
            filterWidget: CompletedAtFilter
          },
          [SUBMITTED_FILTER_ID]: {
            id: SUBMITTED_FILTER_ID,
            name: SUBMITTED_FILTER_LABEL,
            filterWidget: SubmittedFilter
          },
          [FIELD_FILTER_ID]: {
            id: FIELD_FILTER_ID,
            name: FIELD_FILTER_LABEL,
            filterWidget: FieldFilter()
          }
        }}
        initialFilterState={account.task_list_filters}
        onFiltersChange={onFiltersChange}
        onRefresh={onRefresh}
        prevPage={getUrlPageParam(tasksData.prevUrl)}
        nextPage={getUrlPageParam(tasksData.nextUrl)}
        onPageChange={onPageChange}
      />
    </>
  );
}

export default memo(TaskListPage);
