import { useMemo, useState } from 'react';
import { UnresolvedComparisonRule } from '../../../../../../components/Modals';
import { FieldSelector } from '../../../../../../components/Modals/DecisionLogicModal/DecisionLogicSection';
import {
  getDefaultOperator,
  transformToComparisonRule,
  transformToExpression
} from './utils';
import { useRuleBuilderUpdater } from '../../context';
import { RuleOperand } from '../../context/RuleDSL';
import {
  LogicFieldSelectorModal,
  SelectFieldPayload
} from '../FieldSelectorModal';
import useFieldKey from '../../../../../../utils/useFieldKey';
import ComparisonRule from '../../../../../../components/Modals/DecisionLogicModal/ComparisonRule';
import styles from './styles.module.scss';

type EditModeProps = {
  expression: IRuleExpression;
  allowRemove?: boolean;
  onRemove?: () => void;
};

export const EditMode = ({
  expression,
  allowRemove,
  onRemove = () => {}
}: EditModeProps) => {
  const { updateExpression, updateOperand } = useRuleBuilderUpdater((s) => ({
    updateExpression: s.updateExpression,
    updateOperand: s.updateOperand
  }));

  const [showFieldSelector, setShowFieldSelector] = useState(false);
  const [selectedField, setSelectedField] = useState<Field | null>(null);
  const getFieldKey = useFieldKey(true);

  const comparisonRule = useMemo(() => {
    return transformToComparisonRule(expression);
  }, [expression]);

  const handleSelectField = (field: SelectFieldPayload) => {
    const operands = expression.operands as IRuleOperand[];

    if (!selectedField) {
      return;
    }

    if (selectedField.ruleItemIndex === null) {
      const operand = new RuleOperand();

      operand.type = 'field';
      operand.value = field.selectId;
      operand.meta = {
        field_type: field.selectType,
        field_key: getFieldKey(field.selectId),
        servar_type: field.selectServarType
      };

      updateExpression(expression.id, {
        operator: getDefaultOperator(field.selectServarType as string),
        operands: [operand.toJSON()]
      });
    } else {
      const existingOperand = (operands as IRuleOperand[]).find(
        ({ meta }) => meta?.ruleIndex === selectedField.ruleItemIndex
      );

      if (existingOperand) {
        const updates = {
          type: 'field',
          value: field.selectId,
          meta: {
            ...existingOperand.meta,
            field_type: field.selectType,
            field_key: getFieldKey(field.selectId),
            ruleIndex: selectedField.ruleItemIndex,
            servar_type: field.selectServarType
          }
        };

        updateOperand(existingOperand.id, updates as Partial<IRuleOperand>);
      } else {
        const operand = new RuleOperand();

        operand.type = 'field';
        operand.value = field.selectId;
        operand.meta = {
          field_type: field.selectType,
          field_key: getFieldKey(field.selectId),
          ruleIndex: selectedField.ruleItemIndex,
          servar_type: field.selectServarType
        };

        const newOperands = [...expression.operands];
        newOperands.splice(selectedField.ruleItemIndex, 0, operand.toJSON());

        updateExpression(expression.id, {
          operands: newOperands
        });
      }
    }
  };

  const handleUpdates = (rule: UnresolvedComparisonRule) => {
    updateExpression(expression.id, {
      ...transformToExpression(rule),
      id: expression.id
    });
  };

  return (
    <>
      <LogicFieldSelectorModal
        show={showFieldSelector}
        setShow={(value: boolean) => setShowFieldSelector(value)}
        selectedField={selectedField}
        onSelect={handleSelectField}
        includeReserved
      />
      <ComparisonRule
        rule={comparisonRule}
        handleUpdates={handleUpdates}
        handleDelete={() => onRemove()}
        canDelete={allowRemove}
        showFieldSelector={(id, type, ruleItemIndex, ruleValueIndex = null) => {
          setSelectedField({
            id,
            type,
            ruleItemIndex,
            ruleValueIndex
          });
          setShowFieldSelector(true);
        }}
        FieldSelector={FieldSelector}
        classNames={{
          fieldSelector: styles.fieldSelector,
          overflowIcon: styles.overflowIcon
        }}
      />
    </>
  );
};
