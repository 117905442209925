import { useMemo } from 'react';
import { useRuleBuilderState } from '../../../context';
import styles from '../styles.module.scss';
import { CheckIcon } from '../../../../../../../components/Icons';

const replacerFn = (key: string, value: any) => {
  if (key === 'id') {
    return undefined;
  }

  return value;
};

type SaveButtonProps = {
  onSave: () => void;
};

export const SaveButton = ({ onSave = () => {} }: SaveButtonProps) => {
  const { dsl, originalDSL } = useRuleBuilderState((s) => ({
    dsl: s.dsl,
    originalDSL: s.originalDSL
  }));

  const dslChanged = useMemo(() => {
    const _dsl = JSON.stringify(dsl, replacerFn);
    const _originalDSL = JSON.stringify(originalDSL, replacerFn);

    return _dsl !== _originalDSL;
  }, [dsl, originalDSL]);

  if (dslChanged) {
    return (
      <button className={styles.saveChangesButton} onClick={() => onSave()}>
        Save Changes
      </button>
    );
  }

  return (
    <button className={styles.savedChangesButton} onClick={() => {}}>
      <CheckIcon width={16} height={16} /> Saved
    </button>
  );
};
